import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const eventTypes = [
  {
    text: 'Party',
    src: getStorageUrl('landing/event_examples/party.webp'),
    services: ['Villas', 'DJ Equipment', 'Entertainment']
  },
  {
    text: 'Wedding',
    src: getStorageUrl('landing/event_examples/wedding.webp'),
    services: ['Venues', 'Decorations', 'Musicians']
  },
  {
    text: 'Birthday',
    src: getStorageUrl('landing/event_examples/birthday.webp'),
    services: ['Villas', 'Food and Drinks', 'Yachts']
  },
  {
    text: 'Festival',
    src: getStorageUrl('landing/event_examples/festival.webp'),
    services: ['Sound & Light', 'Performers', 'Entertainment']
  },
  {
    text: 'Kids Event',
    src: getStorageUrl('landing/event_examples/kids.webp'),
    services: ['Games', 'Animators', 'Venues']
  },
  {
    text: 'Photoshoot',
    src: getStorageUrl('landing/event_examples/photo.webp'),
    services: ['Photographers', 'Equipment', 'Photo studios']
  },
  {
    text: 'Business Event',
    src: getStorageUrl('landing/event_examples/business.webp'),
    services: ['Furniture', 'Venues', 'Screens']
  },
  {
    text: 'Concert',
    src: getStorageUrl('landing/event_examples/concert.webp'),
    services: ['Stage setup', 'Equipment', '3D Mapping']
  },
  {
    text: 'Art Exhibition',
    src: getStorageUrl('landing/event_examples/exhibition.webp'),
    services: ['Installations', 'Lighting Setup', 'Creative Spaces']
  },
  {
    text: 'Yoga or Dance',
    src: getStorageUrl('landing/event_examples/yoga.webp'),
    services: ['Retreat Venues', 'Yoga Spaces', 'Dance Studios']
  },
  {
    text: 'Music Session',
    src: getStorageUrl('landing/event_examples/music.webp'),
    services: ['Instruments', 'Record Studios', 'DJ Stations']
  },
  {
    text: 'Other',
    src: getStorageUrl('landing/event_examples/other.webp'),
    services: ['Fireworks', 'Artists & Performers', 'Transport']
  },
];
