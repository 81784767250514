import { createContext, useContext, useReducer } from "react";
import {
  UploadingProcedureDispatcherType,
  uploadingProcedureReducer,
  UploadingProcedureStateType,
} from "./dispatcher";

const UploadingProcedureStateContext = createContext<
  [UploadingProcedureStateType, UploadingProcedureDispatcherType]
>([{}, () => {}]);

type Props = {
  children?: React.ReactNode;
};

export const UploadingProcedureStateProvider: React.FC<Props> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(uploadingProcedureReducer, {});

  return (
    <UploadingProcedureStateContext.Provider value={[state, dispatch]}>
      {children}
    </UploadingProcedureStateContext.Provider>
  );
};

export const useUploadingProcedureState = () =>
  useContext(UploadingProcedureStateContext);
