import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './InputSelect.module.scss';
import { IonSelect } from '@ionic/react';

interface SelectProps {
  className?: string;
  defaultValue?: any;
  onChange: (v: any) => void;
}

const Select: React.FC<PropsWithChildren<SelectProps>> = props => {
  return (
    <IonSelect
      label=""
      mode="ios"
      value={props.defaultValue}
      compareWith={(o1, o2) => {
        /* workaround */
        if (o1 === '' && o2 === null) {
          return true;
        }
        return !!o1 && !!o2
          ? (o1.id && o2.id && o1.id === o2.id) ||
              JSON.stringify(o1) === JSON.stringify(o2)
          : o1 === o2;
      }}
      className={clsx(s.IonSelect, props.className)}
      interface="popover"
      justify="start"
      interfaceOptions={{
        subHeader: 'Location:',
        showBackdrop: true,
        backdropDismiss: true,
        mode: 'ios',
        alignment: 'start',
        cssClass: clsx(s.Popover_Interface),
      }}
      onIonChange={e => {
        props.onChange(e.detail.value);
      }}
    >
      {props.children}
    </IonSelect>
  );
};

export default Select;
