import { RouteProps, Route } from 'react-router';
import { useTypedSelector } from '../../redux/hooks';
import AuthRequired from '../Auth/AuthRequired/AuthRequired';
import PermissionLayer from './PermissionLayer';

interface PrivateRouteProps extends RouteProps {
  enablePermissionLayer?: boolean;
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  enablePermissionLayer,
  ...rest
}) => {
  const authPhase = useTypedSelector(s => s.auth.authPhase);
  const isAuthenticated = authPhase === 'authenticated';

  const isLogoutCompleted = authPhase === 'logoutCompleted';

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        enablePermissionLayer ? (
          <PermissionLayer> {children} </PermissionLayer>
        ) : (
          children
        )
      ) : !isLogoutCompleted ? (
        <AuthRequired />
      ) : null}
    </Route>
  );
};

export default PrivateRoute;
