import { useEffect, useState } from "react";

export const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeout = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeout);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};
