import { useEffect, useState } from 'react';
import { IS_SAFARI_NEW } from '../utils/browser';
import { isPlatform } from '@ionic/core';
import { AppVersion } from '@ionic-native/app-version';

export const useAppVersion = () => {
  const [versionNumber, setVersionNumber] = useState<string | number>('Web');
  const [versionCode, setVersionCode] = useState<string | number>(
    IS_SAFARI_NEW ? 'new safari' : 'other'
  );

  const isCapacitor = isPlatform('capacitor');

  useEffect(() => {
    if (isCapacitor) {
      AppVersion.getVersionCode().then(v => setVersionCode(v));
      AppVersion.getVersionNumber().then(v => setVersionNumber(v));
    }
  }, [isCapacitor]);

  return { versionNumber, versionCode, isCapacitor };
};
