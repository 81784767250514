import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { ReduxDispatch } from './store';
import { ReduxRootState } from './store';
import { Product } from '../models/product';
import { Business } from '../models/business';

export const useTypedDispatch = () => useDispatch<ReduxDispatch>();

export const useTypedSelector: TypedUseSelectorHook<ReduxRootState> =
  useSelector;

/* Get object from LIST cache if exsists*/
type TagNameType = 'Product' | 'Business';

type ObjectType<T> = T extends 'Product'
  ? Product
  : T extends 'Business'
  ? Business
  : never;

export function useCachedFromList<T extends TagNameType>({
  tagName,
  objectId,
}: {
  tagName: TagNameType;
  objectId: string;
}): ObjectType<T> | null {
  const queryCacheKeys = useTypedSelector(s =>
    s.api.provided[tagName] ? s.api.provided[tagName][objectId] : undefined
  );

  const queryCacheKey = queryCacheKeys ? queryCacheKeys[0] : '';

  const allObjectsInCachedQuery = useTypedSelector(
    s => s.api.queries[queryCacheKey]?.data
  );

  if (Array.isArray(allObjectsInCachedQuery)) {
    const objectFromCache = allObjectsInCachedQuery.find(
      oneObject => oneObject.id === objectId
    );

    return objectFromCache;
  }

  return null;
}
