import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import { useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import PasswordResetForm, {
  PasswordResetFormValues,
} from './PasswordResetForm';

const PasswordReset: React.FC = () => {
  const dispatch = useTypedDispatch();

  const submitCallback = async function (values: PasswordResetFormValues) {
    await dispatch(
      authActionCreators.passwordReset({
        email: values.email!,
      })
    );
  };

  return (
    <div className={clsx(sg.Stack_Vertical, sg.Gap_20)}>
      <p style={{ marginTop: '10px' }}>
        Enter the email that was used during registration.
      </p>

      <PasswordResetForm submitCallback={submitCallback} />
    </div>
  );
};

export default PasswordReset;
