import { Product } from '../../models/product';

export const getCategoryAndSubcategoriesLine = (
  product: Product,
  showCategory?: boolean
) => {
  const humanReadableSubcategories = product.subcategories?.map(
    s => product.category.subcategories?.find(e => e[0] === s)?.[1]
  );

  const humanReadableSubcategoriesInLine =
    humanReadableSubcategories?.join(', ');

  if (product.category.name && humanReadableSubcategoriesInLine) {
    if (showCategory) {
      return `${product.category.name}: ${humanReadableSubcategoriesInLine}`;
    } else {
      return humanReadableSubcategoriesInLine;
    }
  } else {
    return product.category.name;
  }
};
