import clsx from 'clsx';
import { useCallback } from 'react';
import { AlertButton, AlertOptions } from '@ionic/react';
import s from '../components/Alert/Alert.module.scss';
import { useTypedDispatch } from '../redux/hooks';
import { globalActionCreators } from '../redux/slices/global/actionCreator';

interface BuildAlertParams {
  header?: string | null;
  subHeader?: string;
  message: string;
  previewSrc?: string;
  cancelButtonText?: string;
  cancelButtonHandler?: () => void;
  confirmButtonVisible?: boolean;
  confirmButtonText?: string;
  confirmButtonHandler?: () => void;
  milisecondsBeforeAlert?: number;
}

export const useAlert = function () {
  const dispatch = useTypedDispatch();

  const alertBuilder = useCallback(
    (params: BuildAlertParams) => {
      const buttons: AlertButton[] = [
        {
          text: params.cancelButtonText || 'Ok',
          role: 'cancel',
          handler: () => {
            if (params.cancelButtonHandler) {
              params.cancelButtonHandler();
            }
            dispatch(globalActionCreators.setAlertObject(null));
            dispatch(globalActionCreators.setErrorText(null));
          },
        },
        {
          text: params.confirmButtonText || 'Continue',
          handler: params.confirmButtonHandler,
        },
      ];
      if (!params.confirmButtonVisible) {
        delete buttons[1];
      }

      const alertObject: AlertOptions = {
        header:
          params.header === null
            ? undefined
            : params.header === undefined
            ? 'Error'
            : params.header,
        buttons,
        mode: 'ios',
        keyboardClose: true,
        backdropDismiss: false,
        cssClass: clsx(s.Alert),
      };

      if (!params.previewSrc) {
        alertObject.subHeader = params.subHeader;
        alertObject.message = params.message;
      } else {
        alertObject.subHeader = params.message;
        alertObject.message = '';
      }

      setTimeout(() => {
        dispatch(globalActionCreators.setAlertObject(alertObject));

        if (params.previewSrc) {
          setTimeout(() => {
            const alertMessage =
              document.getElementsByClassName('alert-message')[0];
            if (alertMessage)
              alertMessage.innerHTML = `<img src="${params.previewSrc}"/>`;
          }, 300);
        }
      }, params.milisecondsBeforeAlert || 0);
    },
    [dispatch]
  );

  return alertBuilder;
};
