import React from 'react';
import { StatusType } from './useGroup.hook';

type FetchMoreStatusContextType = {
  status: StatusType;
  executeFetchMore: () => void;
};

export const FetchMoreStatusContext =
  React.createContext<FetchMoreStatusContextType>({
    status: undefined,
    executeFetchMore: () => console.log('Not provided yet.'),
  });
