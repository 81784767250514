import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './InputSelect.module.scss';
import { IonSelect } from '@ionic/react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { UseFormRegister } from 'react-hook-form';
import InputYAnchor from '../InputYAnchor/InputYAnchor';

interface InputSelectProps {
  label?: string;
  placeholder?: string;
  onChange?: (newValue: any) => any;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  labelClassName?: string;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
}

const InputSelect: React.FC<PropsWithChildren<InputSelectProps>> = props => {
  const _registered = props._register(props._name);

  return (
    <InputWrapper
      label={props.label}
      errorText={props._errorText}
      labelClassName={props.labelClassName}
    >
      <IonSelect
        multiple={props.multiple}
        label=""
        mode="ios"
        compareWith={(o1, o2) => {
          /* workaround */
          if (o1 === '' && o2 === null) {
            return true;
          }
          return !!o1 && !!o2
            ? (o1.id && o2.id && o1.id === o2.id) ||
                JSON.stringify(o1) === JSON.stringify(o2)
            : o1 === o2;
        }}
        disabled={props.disabled}
        className={clsx(s.IonSelect, s.IonSelect__border, props.className)}
        placeholder={props.placeholder}
        interface="popover"
        justify="start"
        interfaceOptions={{
          subHeader: props.multiple ? 'Select 1-3 subcategories' : undefined,
          showBackdrop: true,
          backdropDismiss: true,
          mode: 'ios',
          alignment: 'start',
          cssClass: clsx(s.Popover_Interface),
        }}
        /* react-hook-form */
        ref={_registered.ref} // For default values
        name={_registered.name}
        onIonChange={e => {
          _registered.onChange(e);
          if (props.onChange) props.onChange(e.detail.value);
        }}
      >
        {props.children}
      </IonSelect>

      <InputYAnchor
        refCallback={_registered.ref} // For error
      />
    </InputWrapper>
  );
};

export default InputSelect;
