import React from 'react';
import s from './ActionButton.module.scss';
import clsx from 'clsx';
import { useLandingPageContext } from '../../context';

interface Props {
  text?: string;
  arrow?: boolean;
  animation?: boolean;
  skipDefaultAction?: boolean;
  instagram?: boolean;
  onClick?: () => void;
}

export const ActionButton: React.FC<Props> = ({
  text,
  arrow = true,
  animation,
  skipDefaultAction,
  instagram,
  onClick,
}) => {
  const { actionButtonName, onActionClick } = useLandingPageContext();

  return (
    <button
      onClick={() => {
        if (!skipDefaultAction) {
          onActionClick();
        }
        onClick?.();
      }}
      data-animation
      className={clsx(
        s.ActionButton,
        animation ? s.ActionButton__animation : '',
        instagram ? s.ActionButton__instagram_color : ''
      )}
    >
      {instagram && (
        <img
          src="./svg/instagram.svg"
          alt="instagram"
          style={{ width: '24px', height: '24px' }}
        />
      )}
      {text || actionButtonName}{' '}
      {arrow && <img src="./svg/arrowAction_Common.svg" alt="" />}
    </button>
  );
};
