import { IonIcon } from '@ionic/react';
import {
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
} from 'ionicons/icons';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import s from './HorizontalScrollHelpers.module.scss';
import clsx from 'clsx';
import { isPlatform } from '@ionic/core';

export const BUTTON_WIDTH = isPlatform('desktop') ? 50 : 0;

type Props = {
  scrollBy: (direction: 'left' | 'right') => void;
  disabled: boolean;
};

export const LeftArrow: React.FC<Props> = ({ scrollBy, disabled }) => {
  return isPlatform('desktop') ? (
    <div
      style={{
        width: BUTTON_WIDTH,
        height: 2 * BUTTON_WIDTH,
      }}
    >
      <ButtonMicroWrapper
        noRipple
        disabled={disabled}
        onClick={() => scrollBy('left')}
        className={clsx(s.NavigationButton)}
      >
        <IonIcon
          src={chevronBackCircleOutline}
          className={clsx(
            s.IonIcon,
            disabled ? s.IonIcon__disabled : undefined,
            s.IonIcon__left
          )}
        />
      </ButtonMicroWrapper>
    </div>
  ) : null;
};

export const RightArrow: React.FC<Props> = ({ scrollBy, disabled }) => {
  return isPlatform('desktop') ? (
    <div
      style={{
        width: BUTTON_WIDTH,
        height: 2 * BUTTON_WIDTH,
      }}
    >
      <ButtonMicroWrapper
        noRipple
        disabled={disabled}
        onClick={() => scrollBy('right')}
        className={clsx(s.NavigationButton)}
      >
        <IonIcon
          src={chevronForwardCircleOutline}
          className={clsx(
            s.IonIcon,
            disabled ? s.IonIcon__disabled : undefined,
            s.IonIcon__right
          )}
        />
      </ButtonMicroWrapper>
    </div>
  ) : null;
};
