import clsx from 'clsx';
import s from './Avatar.module.scss';
import { IonImg } from '@ionic/react';
import defaultAvatar from '../../assets/default-avatar.png';
import React from 'react';

export enum AvatarType {
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
}

export enum AvatarSize {
  SMALL = 'small',
}

interface AvatarProps {
  image?: string;
  alt: string;
  type: AvatarType;
  size: AvatarSize;
}

const Avatar: React.FC<AvatarProps> = props => {
  const styleClassName = props.type + '_' + props.size;

  return (
    <div className={clsx(s.Image_No_Distortion__container, s[styleClassName])}>
      <IonImg alt={props.alt} src={props.image || defaultAvatar} />
    </div>
  );
};

export default Avatar;
