import * as Yup from 'yup';
import Form from '../../Form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Input from '../../Inputs/Input/Input';
import Button from '../../Buttons/Button/Button';
import { useTypedSelector } from '../../../redux/hooks';
import { validationErrors } from '../../../utils/validators';

export interface PasswordResetConfirmFormValues {
  email: string;
  password1?: string;
  password2?: string;
}

interface PasswordResetConfirmFormProps extends PasswordResetConfirmFormValues {
  submitCallback: (values: PasswordResetConfirmFormValues) => void;
  children?: React.ReactNode;
}

let validationSchema = Yup.object({
  password1: Yup.string()
    .required(validationErrors.REQUIRED)
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .nullable(),
  password2: Yup.string()
    .required(validationErrors.REQUIRED)
    .oneOf([Yup.ref('password1'), null], 'Passwords do not match'),
});

const PasswordResetConfirmForm: React.FC<PasswordResetConfirmFormProps> = ({
  children,
  submitCallback,
  ...initialFormValues
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const loading = useTypedSelector(s => s.auth.loading);

  return (
    <Form loadingStore="auth" onSubmit={handleSubmit(submitCallback)}>
      {/* ------- Email for correct saving ------ */}
      <div hidden={true}>
        <Input
          type="email"
          inputmode="email"
          label="Email"
          _name="email"
          _errorText={undefined}
          _register={register}
        />
      </div>

      {/* ------- Password 1 ------ */}
      <Input
        applyWorkaround
        type="password"
        inputmode="text"
        maxlength={50}
        label="Password"
        autocomplete="new-password"
        _name="password1"
        _errorText={errors.password1?.message}
        _register={register}
      />

      {/* ------- Password 2 ------ */}
      <Input
        applyWorkaround
        type="password"
        inputmode="text"
        maxlength={50}
        label="Repeat password"
        autocomplete="new-password"
        _name="password2"
        _errorText={errors.password2?.message}
        _register={register}
      />

      {/* ------- Submit ------ */}
      <Button
        text="Continue"
        type="submit"
        color="secondary"
        fill="solid"
        loading={loading}
      />
    </Form>
  );
};

export default PasswordResetConfirmForm;
