import React from 'react';
import Page from '../components/Page/Page';
import sg from '../styles/global.module.scss';
import clsx from 'clsx';
import { openUrlInWeb } from '../components/Auth/helper';
import { useAppVersion } from '../hooks/hook.version';

const About: React.FC = () => {
  const { versionNumber, versionCode } = useAppVersion();

  return (
    <Page headerProps={{ title: 'About' }}>
      <div
        className={clsx(sg.Stack_Vertical, sg.Gap_15)}
        style={{ marginTop: '10px' }}
      >
        <p
          style={{ textDecoration: 'underline' }}
          onClick={() => openUrlInWeb('privacy')}
        >
          Privacy Policy
        </p>

        <p
          style={{ textDecoration: 'underline' }}
          onClick={() => openUrlInWeb('terms')}
        >
          Terms of Usage
        </p>

        <div style={{ marginTop: '20px' }}>
          <p>Eventmaker © {new Date().getFullYear()}</p>
        </div>

        <p>
          Version: {versionNumber} ({versionCode})
        </p>

        {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
          <p style={{ color: 'red' }}>
            Environment: {process.env.REACT_APP_ENVIRONMENT}
          </p>
        )}

        <p>Contact us: support@eventmaker.app</p>
      </div>
    </Page>
  );
};

export default About;
