import { IonToolbar } from '@ionic/react';
import { Icons } from '../icons';
import { Link } from 'react-router-dom';

export const AppLogoDesktop = () => {
  return (
    <IonToolbar style={{ '--padding-start': '10px' }}>
      <Link to={{ pathname: '/', state: { allowVisitLandning: true } }}>
        <img
          alt=""
          src={Icons.logo}
          style={{
            width: '170px',
            height: '100%',
            maxWidth: 'unset',
            cursor: 'pointer',
          }}
        />
      </Link>
    </IonToolbar>
  );
};
