import { Manager, Socket } from 'socket.io-client';

class SocketSingletonCreator {
  socket: Socket | undefined;

  private validateThatSocketConnectionWasEstablishedBefore() {
    if (!this.socket) {
      throw new Error(
        'Authorized socket connection was not established before'
      );
    }
  }

  createAuthorizedConnection(token: string) {
    if (this.socket) {
      this.socket.disconnect();
    }

    const manager = new Manager(process.env.REACT_APP_API_ENDPOINT, {
      transports: ['websocket', 'polling'],
      withCredentials: true,
      reconnection: true,
    });

    // any-namespace
    this.socket = manager.socket('/', {
      auth: {
        token,
      },
    });

    this.socket.on('connect', () => {
      console.warn(`Socket connection was established: ${this.socket?.id}`);
    });

    this.socket.on('connect_error', () => {
      console.warn(`Connection error. Trying to reconnect...`);
    });

    this.socket.on('disconnect', () => {
      console.warn('DISCONNECTED');
    });

    this.socket.connect();
  }

  __emit() {
    this.socket!.emit('EMITED_EVENT_NAME', { a: 1, b: 'abcdefg' });
  }

  on(ev: string, listener: (...args: any[]) => void) {
    console.log(`New socket listener at '${ev}'.`);
    this.validateThatSocketConnectionWasEstablishedBefore();
    this.socket!.on(ev, listener);
  }

  off(ev: string, listener?: (...args: any[]) => void) {
    console.log(`Removed socket listener at '${ev}'.`);
    this.validateThatSocketConnectionWasEstablishedBefore();
    this.socket!.off(ev, listener);
  }
}

export const socket = new SocketSingletonCreator();
