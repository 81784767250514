import { ObjectTyped } from 'object-typed';
import {
  FileCategoryEnum,
  OneFileKeyEnum,
  UploadedFile,
  VALIDATION_CONFIG,
} from '../../../../@shared/file';
import { GenericFile } from '../interfaces';

export type UrlsType = {
  thumbnail: {
    high: string;
    medium: string;
    low: string;
  };
  content: {
    high: string;
    medium: string;
    low: string;
  };
};

/*
 * Return "urls" in all qualities and "fileCategory"
 */
export const buildUrlsFromGenericFile = (
  genericFile: GenericFile | undefined
): UrlsType => {
  const urls = {
    thumbnail: {
      high: '',
      medium: '',
      low: '',
    },
    content: {
      high: '',
      medium: '',
      low: '',
    },
  };

  if (!genericFile) {
    return urls;
  }

  const uploadedFile = genericFile.uploadedFile;
  const sourceFile = genericFile.sourceFile;
  const sourceImagePreview = genericFile.sourceImagePreview;
  const fileCategory = getFileCategoryFromGenericFile(genericFile);

  // Local
  if (sourceFile) {
    urls.content.high =
      urls.content.medium =
      urls.content.low =
        URL.createObjectURL(sourceFile);

    switch (fileCategory) {
      case FileCategoryEnum.IMAGE:
      case FileCategoryEnum.VIDEO: {
        urls.thumbnail.high =
          urls.thumbnail.medium =
          urls.thumbnail.low =
            sourceImagePreview ? URL.createObjectURL(sourceImagePreview) : '';
        break;
      }
      case FileCategoryEnum.DOCUMENT: {
        urls.thumbnail.high = urls.thumbnail.medium = urls.thumbnail.low = '';
        break;
      }
    }
  }

  // Server
  if (uploadedFile) {
    switch (fileCategory) {
      case FileCategoryEnum.IMAGE: {
        urls.content.high = urls.thumbnail.high =
          uploadedFile[OneFileKeyEnum.CI_HIGH] || '';

        urls.content.medium = urls.thumbnail.medium =
          uploadedFile[OneFileKeyEnum.CI_MEDIUM] || '';

        urls.content.low = urls.thumbnail.low =
          uploadedFile[OneFileKeyEnum.CI_LOW] || '';

        break;
      }
      case FileCategoryEnum.VIDEO: {
        urls.content.high = uploadedFile[OneFileKeyEnum.CV_HIGH] || '';
        urls.thumbnail.high = uploadedFile[OneFileKeyEnum.CI_HIGH] || '';

        urls.content.medium = uploadedFile[OneFileKeyEnum.CV_MEDIUM] || '';
        urls.thumbnail.medium = uploadedFile[OneFileKeyEnum.CI_MEDIUM] || '';

        urls.content.low = uploadedFile[OneFileKeyEnum.CV_MEDIUM] || '';
        urls.thumbnail.low = uploadedFile[OneFileKeyEnum.CI_LOW] || '';

        break;
      }
      case FileCategoryEnum.DOCUMENT: {
        urls.content.high =
          urls.content.medium =
          urls.content.low =
            uploadedFile[OneFileKeyEnum.DOCUMENT_ORIGINAL] || '';

        urls.thumbnail.high = urls.thumbnail.medium = urls.thumbnail.low = '';
        break;
      }
    }
  }

  return urls;
};

export const buildUrlsFromUploadedFile = (
  uploadedFile: UploadedFile | null
): UrlsType => {
  const genericFile = transformToGenericFiles([uploadedFile])[0] as
    | GenericFile
    | undefined;
  return buildUrlsFromGenericFile(genericFile);
};

/*
 * Get fileCategory based on mimeType
 */
export const getFileCategoryFromGenericFile = (
  genericFile: GenericFile | undefined
): FileCategoryEnum | undefined => {
  if (genericFile?.uploadedFile) {
    return genericFile.uploadedFile.fileCategory;
  } else if (genericFile?.sourceFile) {
    const mimeType = genericFile.sourceFile.type;
    for (const [configFileCategory, configValue] of ObjectTyped.entries(
      VALIDATION_CONFIG
    )) {
      if (Object.keys(configValue.mimeTypes).includes(mimeType))
        return configFileCategory;
    }
  }
};

/*
 * Transform UploadedFiles to GenericFiles
 */
export const transformToGenericFiles = (
  uploadedFiles: (UploadedFile | null | undefined)[]
): GenericFile[] => {
  const genericFiles: GenericFile[] = [];

  for (const uploadedFile of uploadedFiles) {
    if (uploadedFile) {
      genericFiles.push({
        id: uploadedFile.id,
        uploadedFile,
      });
    }
  }

  return genericFiles;
};

export const getImageDimensions = async (
  imageData: Blob
): Promise<{ height: number; width: number }> => {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = function () {
      const height = img.height;
      const width = img.width;
      resolve({
        height,
        width,
      });
    };

    img.src = URL.createObjectURL(imageData);
  });
};
