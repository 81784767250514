import { useCallback } from 'react';
import { useTypedSelector } from '../../../../redux/hooks';
import { ObjectTyped } from 'object-typed';
import {
  onSnapshot,
  doc,
  DocumentReference,
  deleteDoc,
} from 'firebase/firestore';
import { firestore } from '../../../../firebase';
import { UploadingProcedureDispatcherType } from './dispatcher';
import { GenericFilesDispatcherType } from '../dispatcher';
import {
  FileCategoryEnum,
  OneFileKeyEnum,
  GeneratedPoliciesType,
  GeneratePostPoliciesRequestType,
  UploadingDoc,
  StorageDirectoryData,
} from '../../../../@shared/file';

export const useFileUploader = ({
  genericFileId,
  fileCategory,
  storageDirectoryData,
  genericFilesDispatcher,
  uploadingProcedureDispatcher,
}: {
  genericFileId: string;
  fileCategory: FileCategoryEnum;
  storageDirectoryData: StorageDirectoryData;
  uploadingProcedureDispatcher: UploadingProcedureDispatcherType;
  genericFilesDispatcher: GenericFilesDispatcherType;
}) => {
  const token = useTypedSelector(s => s.auth._token);

  return useCallback(
    async (allFiles: {
      [key in OneFileKeyEnum]?: Blob | undefined;
    }): Promise<boolean> => {
      const allFilesInfo: GeneratePostPoliciesRequestType['allFilesInfo'] = {};

      for (const [oneFileKey, file] of ObjectTyped.entries(allFiles)) {
        if (!file) continue;

        allFilesInfo[oneFileKey] = {
          mimeType: file.type,
          size: file.size,
        };
      }

      const body: GeneratePostPoliciesRequestType = {
        uploadedFileId: genericFileId,
        storageDirectoryData,
        fileCategory,
        allFilesInfo,
        authenticationToken: `Token ${token}`,
        backendTunnelEndpoint:
          process.env.REACT_APP_BACKEND_TUNNEL_ENDPOINT || '',
      };

      uploadingProcedureDispatcher({
        action: 'setProgressStepValue',
        setProgressStepValueData: { atPolicies: true },
      });

      const response = await fetch(
        process.env.REACT_APP_FIREBASE_GENERATE_POST_POLICIES!,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );

      if (response.status !== 201) {
        throw new Error(
          `Status of firebase response is not 201 but ${response.status}`
        );
      }

      const policies = (await response.json()) as GeneratedPoliciesType;

      return new Promise((resolve, reject) => {
        let totalUploadingSize = 0;
        const uploadingProgress: { [key in OneFileKeyEnum]?: number } = {};
        for (const [oneFileKey, file] of ObjectTyped.entries(allFiles)) {
          if (!file) continue;
          uploadingProgress[oneFileKey] = 0;
          totalUploadingSize += file.size;
        }

        for (const [oneFileKey, file] of ObjectTyped.entries(allFiles)) {
          if (!file) continue;
          const UPLOADING_IMPACT_COEFFECIENT = file.size / totalUploadingSize;
          const policy = policies[oneFileKey]!;
          const formData = new FormData();
          for (const [key, value] of Object.entries(policy['fields'])) {
            formData.append(key, value);
          }
          formData.append('file', file);
          const xhr = new XMLHttpRequest();
          xhr.open('post', policy['url']);
          xhr.upload.addEventListener('progress', e => {
            uploadingProgress[oneFileKey] =
              Math.floor((e.loaded / e.total) * 100) *
              UPLOADING_IMPACT_COEFFECIENT;
          });
          xhr.send(formData);
          xhr.onerror = err => {
            console.log('+++ B-3 +++ ');
            reject(err);
          };
        }

        // TODO: limit accessing to this doc more strictly !!!
        // Now its restricted by "firebase"/firestore.rules and based on assumption
        // that bad actors could not receive or guess genericFileId
        const uploadingDoc = doc(
          firestore,
          'Uploading',
          genericFileId
        ) as DocumentReference<UploadingDoc>;

        let isAcknowledged = false;
        const unsubscribeProcessingProgress = onSnapshot(
          uploadingDoc,
          async doc => {
            uploadingProcedureDispatcher({
              action: 'setProgressStepValue',
              setProgressStepValueData: doc.data()?.progress || {},
            });

            const data = doc.data();
            if (!data && !isAcknowledged) {
              reject('Server error has occured.');
              return;
            }

            if (data?.uploadedFile) {
              isAcknowledged = true;
              await deleteDoc(uploadingDoc);

              genericFilesDispatcher({
                action: 'attachUploadedFile',
                attachUploadedFile: {
                  genericFileId,
                  uploadedFile: data.uploadedFile,
                },
              });

              resolve(true);
            }
          }
        );

        // <
        const MAXIMUM_ALLOWED_TIME_TO_WAIT = 30 * 1000;
        let lastUpdateWasAt = 0;
        // >

        const intervalUploadingProgress = setInterval(() => {
          console.info(`Uploading... ${genericFileId}`);

          const uploadingProgressPercentage = Math.floor(
            Object.values(uploadingProgress).reduce(
              (previousValue, currentElement) => previousValue + currentElement,
              0
            )
          );

          uploadingProcedureDispatcher({
            action: 'setProgressStepValue',
            setProgressStepValueData: {
              uploading: uploadingProgressPercentage,
            },
          });

          // <
          const currentTimestamp = new Date().getTime();
          if (
            lastUpdateWasAt &&
            currentTimestamp - lastUpdateWasAt > MAXIMUM_ALLOWED_TIME_TO_WAIT
          ) {
            reject('Server is not available');
          } else {
            lastUpdateWasAt = currentTimestamp;
          }
          // >
        }, 1000);

        const listenersRemover = () => {
          unsubscribeProcessingProgress();
          clearInterval(intervalUploadingProgress);
        };

        uploadingProcedureDispatcher({
          action: 'attachListenersRemover',
          attachListenersRemover: { listenersRemover },
        });
      });
    },
    []
  );
};
