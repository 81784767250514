import { Redirect } from 'react-router';
import { useTypedSelector } from './redux/hooks';

/* If user do not provide any path in url */
const AppDefaultRedirect: React.FC = () => {
  // u - user
  // b - business
  // o - organizer
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject);

  if (activeSubject && activeSubject.contentType !== 'user') {
    const prefix = activeSubject.contentType === 'business' ? 'b' : 'o';
    return <Redirect to={`/${prefix}/${activeSubject.objectId!}/products`} />;
  }

  return <Redirect to="/u/catalog" />;
};

export default AppDefaultRedirect;
