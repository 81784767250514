// import { useEffect } from 'react';
// import { IS_SAFARI_MOBILE } from '../../utils/browser';

// export const useSafariSwipeBlocker = (
//   myRef: React.MutableRefObject<HTMLElement | null>
// ) => {
//   useEffect(() => {
//     if (IS_SAFARI_MOBILE) {
//       const htmlElement = myRef.current;
//       if (!htmlElement) {
//         console.warn('[SafariSwipeBlocker] HTML Element not found on page.');
//         return;
//       }

//       applyBlockerEventListener(htmlElement);
//     }
//   }, [myRef]);
// };

export const applyBlockerEventListener = (element: HTMLElement) => {
  element.addEventListener(
    'touchstart',
    (touchEvent: TouchEvent) => {
      const e = touchEvent as { pageX: number } & TouchEvent;

      const OFFSET = 30;

      const touch =
        e.targetTouches.length >= 1
          ? e.targetTouches.item(0)
          : e.touches.item(0);

      let shouldPreventDefaultIfNecessary = true;

      // Disable e.preventDefault() for some components
      // if "disableIosSafariSwipeBlocker" provided in className
      const touchTarget = touch?.target as HTMLElement | undefined;

      if (touchTarget?.className.includes('disableIosSafariSwipeBlocker')) {
        shouldPreventDefaultIfNecessary = false;
      }

      if (e.pageX && e.pageX > OFFSET && e.pageX < window.innerWidth - OFFSET) {
        // Middle of screen was touched
        return;
      } else if (e.pageX && shouldPreventDefaultIfNecessary) {
        // Edge of screen was touched
        e.preventDefault();
      }
    },
    { passive: false }
  );
};
