import React, { useMemo } from 'react';
import sg from '../../styles/global.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from '../Form/Form';
import Input from '../Inputs/Input/Input';
import InputSelect from '../Inputs/InputSelect/InputSelect';
import InputSelectOption from '../Inputs/InputSelect/InputSelectOption';
import InputFile from '../Inputs/InputFile/InputFile';
import { useTypedSelector } from '../../redux/hooks';
import { useSumbitFormCallback } from '../Form/submit.hook';
import { validationErrors, validators } from '../../utils/validators';
import {
  PriceTypeEnum,
  ProductCategory,
  Product,
  CurrenciesEnum,
} from '../../models/product';
import clsx from 'clsx';
import InputArea from '../Inputs/InputArea/InputArea';
import EventCategoryOrder from '../EventCategoryOrder/EventCategoryOrder';
import InputCurrency from '../Inputs/InputCurrency/InputCurrency';
import InputWrapper from '../Inputs/InputWrapper/InputWrapper';
import {
  FileCategoryEnum,
  StorageDirectoryData,
  UploadedFile,
} from '../../@shared/file';
import { NullableNumber } from '../../utils/types';
import { IonCard } from '@ionic/react';
import s from './ProductForm.module.scss';

export type ProductFormValues = Omit<Product, 'id' | 'business'>;

type Props = {
  initialFormValues: Partial<ProductFormValues>;
  submitCallback: (values: ProductFormValues) => Promise<void>;
  storageDirectoryData: StorageDirectoryData;
  isLoading: boolean;
  action: 'add' | 'edit';
};

const validationOrdered = {
  /* BASIC */
  title: Yup.string().required(validationErrors.REQUIRED),
  category: Yup.object()
    .shape({})
    .required(validationErrors.REQUIRED)
    .nullable(),
  subcategories: Yup.array()
    .nullable()
    .when('category.subcategories', {
      is: (v: ProductCategory['subcategories']) => !!v,
      then: schema =>
        schema
          .required(validationErrors.REQUIRED)
          .min(1, 'Please select at least 1 subcategory')
          .max(3, 'Please select no more than 3 subcategories'),
    }),
  details: Yup.string().required(validationErrors.REQUIRED),
  uploadedFiles: validators.uploadedFiles,

  /* PRICE */
  priceCurrency: Yup.mixed<CurrenciesEnum>().oneOf([
    ...Object.values(CurrenciesEnum),
  ]),
  priceFrom: validators.nullableNumber,
  priceTo: validators.nullableNumber,
  priceType: Yup.mixed<PriceTypeEnum>()
    .nullable()
    .oneOf([...Object.values(PriceTypeEnum), null])
    .when('priceFrom', {
      is: (v: NullableNumber) => !!v,
      then: schema => schema.required(validationErrors.REQUIRED),
    })
    .when('priceTo', {
      is: (v: NullableNumber) => !!v,
      then: schema => schema.required(validationErrors.REQUIRED),
    }),
  priceMinRentTime: validators.nullableNumber
    .min(1, validationErrors.HOURS)
    .max(24, validationErrors.HOURS)
    .when('priceType', {
      is: PriceTypeEnum.PER_HOUR,
      then: schema => schema.required(validationErrors.REQUIRED),
    }),
  priceDescription: validators.nullableString,

  /* Search optimization */
  peopleFrom: validators.nullableNumber,
  peopleTo: validators.nullableNumber,
  suitableEventCategories: Yup.array(),
};

const validationSchema: Yup.SchemaOf<Partial<ProductFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

const ProductForm: React.FC<Props> = ({
  submitCallback,
  storageDirectoryData,
  initialFormValues,
  isLoading,
  action,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, dirtyFields, defaultValues },
    control,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const { onSubmit, updateUploadedFilesInForm } =
    useSumbitFormCallback<ProductFormValues>({
      submitCallback,
      handleSubmit,
      reset,
      trigger,
      setValue,
      isSubmitted,
    });

  const productCategories = useTypedSelector(s => s.choices.productCategorySet);

  const category = watch('category');
  const priceType = watch('priceType');
  const priceFrom = watch('priceFrom');
  const priceTo = watch('priceTo');

  const subcategories = useMemo(
    () => productCategories.find(c => c.id === category?.id)?.subcategories,
    [category, productCategories]
  );

  return (
    <Form
      isDirty={!!Object.keys(dirtyFields).length}
      showSubmitButton
      loading={isLoading}
      onSubmit={onSubmit}
    >
      {/* ===== General ===== */}
      <div
        className={clsx(sg.Stack_Horizontal, sg.Justify_Center, s.Container)}
        style={{ marginTop: '20px' }}
      >
        <p className={clsx(s.Text__Header)}>General Information &nbsp;</p>
      </div>

      <IonCard className={clsx(s.IonCard__info)}>
        <p>
          Please provide information about your product or service offering.
        </p>
      </IonCard>

      {/* ------- Title ------ */}
      <Input
        type="text"
        inputmode="text"
        label="Title"
        maxlength={50}
        _name={'title' satisfies FieldNameType}
        _register={register}
        _errorText={errors.title?.message}
      />

      {/* ------- Category (resourcetype) ------ */}
      <InputSelect
        label="Category"
        onChange={() => setValue('subcategories' satisfies FieldNameType, null)}
        // disabled={action === 'edit'} TODO ASAP: This is more correct scenario
        _name={'category' satisfies FieldNameType}
        _register={register}
        _errorText={errors.category?.message}
      >
        {productCategories.map(productCategory => (
          <InputSelectOption
            key={productCategory.id}
            value={productCategory}
            textPreview={productCategory.name}
          />
        ))}
      </InputSelect>

      {/* ------- Subcategory ------ */}
      {subcategories && (
        <InputSelect
          multiple
          label="Subcategory"
          _name={'subcategories' satisfies FieldNameType}
          _register={register}
          _errorText={errors.subcategories?.message}
        >
          {subcategories.map(subcategory => (
            <InputSelectOption
              key={subcategory[0]}
              value={subcategory[0]}
              textPreview={subcategory[1]}
            />
          ))}
        </InputSelect>
      )}

      {/* ------- Details ------ */}
      <InputArea
        label="Description"
        _name={'details' satisfies FieldNameType}
        _register={register}
        _errorText={errors.details?.message}
      />

      {/* ------- Files ------ */}
      <InputFile
        allowedFileCategories={[FileCategoryEnum.IMAGE, FileCategoryEnum.VIDEO]}
        label="Photos & Videos"
        maxCountOfFiles={10}
        storageDirectoryData={storageDirectoryData}
        _name={'uploadedFiles' satisfies FieldNameType}
        _register={register}
        _errorText={errors.uploadedFiles?.message}
        __updateUploadedFilesInForm={updateUploadedFilesInForm}
        __initialValue={defaultValues?.uploadedFiles as UploadedFile[]}
      />

      {/* ===== PRICE ===== */}
      <div
        className={clsx(sg.Stack_Horizontal, sg.Justify_Center, s.Container)}
      >
        <p className={clsx(s.Text__Header)}>Price Details &nbsp;</p>
        <p className={clsx(s.Text__Optional)}>(optional)</p>
      </div>

      <IonCard className={clsx(s.IonCard__info)}>
        <p>
          This information can helps you to reach relevant clients if they have
          specific price desires. We're highly recommend to fill this block.
        </p>
      </IonCard>

      <InputWrapper
        errorText={errors.priceFrom?.message || errors.priceTo?.message}
      >
        <div className={clsx(sg.Stack_Horizontal, sg.Gap_10)}>
          <InputCurrency
            label="Price from"
            marginBottom={2}
            priceCurrency={defaultValues?.priceCurrency}
            _name={'priceFrom' satisfies FieldNameType}
            _register={register}
            _control={control}
            _setValue={setValue}
          />

          <InputCurrency
            label="Price to"
            marginBottom={2}
            priceCurrency={defaultValues?.priceCurrency}
            _name={'priceTo' satisfies FieldNameType}
            _register={register}
            _control={control}
            _setValue={setValue}
          />
        </div>
      </InputWrapper>

      {(priceFrom || priceTo) && (
        <div className={clsx(sg.Stack_Horizontal, sg.Gap_10)}>
          <div
            style={{
              width: priceType === PriceTypeEnum.PER_HOUR ? '50%' : '100%',
            }}
          >
            <InputSelect
              label="Payment option"
              _name={'priceType' satisfies FieldNameType}
              _register={register}
              _errorText={errors.priceType?.message}
            >
              <InputSelectOption
                key="option-1"
                value={PriceTypeEnum.PER_HOUR}
                textPreview="Hourly"
              />
              <InputSelectOption
                key="option-2"
                value={PriceTypeEnum.PER_DAY}
                textPreview="Per event"
              />
            </InputSelect>
          </div>

          {priceType === PriceTypeEnum.PER_HOUR && (
            <Input
              type="number"
              inputmode="decimal"
              label="Min rent time, hours"
              _name={'priceMinRentTime' satisfies FieldNameType}
              _register={register}
              _errorText={errors.priceMinRentTime?.message}
            />
          )}
        </div>
      )}

      <InputArea
        label="Price details & Special offers"
        _name={'priceDescription' satisfies FieldNameType}
        _register={register}
        _errorText={errors.priceDescription?.message}
      />

      {/* ===== PROMOTION ===== */}
      <div
        className={clsx(sg.Stack_Horizontal, sg.Justify_Center, s.Container)}
      >
        <p className={clsx(s.Text__Header)}>Search Optimization &nbsp;</p>
        <p className={clsx(s.Text__Optional)}>(optional)</p>
      </div>

      <IonCard className={clsx(s.IonCard__info)}>
        <p>
          Please specify the typical usage of your product or service. We will
          prioritize your offering in search results if it aligns with the
          client's needs.
          <br />
          <br />• People count - suggest the ideal number of event participants,
          that best aligns with your offering.
          <br />
          <br />• Event categories - suggest and prioritize the most fitting
          event types for this offering. You can reorder the left column by
          dragging and dropping.
        </p>
      </IonCard>

      <div
        className={clsx(sg.Stack_Horizontal, sg.Gap_10)}
        style={{ marginBottom: '10px' }}
      >
        <Input
          label="People count from"
          type="number"
          inputmode="numeric"
          _name={'peopleFrom' satisfies FieldNameType}
          _register={register}
          _errorText={errors.peopleFrom?.message}
        />

        <Input
          label="People count to"
          type="number"
          inputmode="numeric"
          _name={'peopleTo' satisfies FieldNameType}
          _register={register}
          _errorText={errors.peopleTo?.message}
        />
      </div>

      <EventCategoryOrder
        _name={'suitableEventCategories' satisfies FieldNameType}
        _register={register}
        _control={control}
        _setValue={setValue}
        _errorText={errors.suitableEventCategories?.message}
      />

      {/* ===== EXTRA DATA BY RESOURCETYPE ===== */}
      {/* <p style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
        Additional filters
      </p>

      <Input
        label="Square of venue"
        type="number"
        inputmode="decimal"
        _name={'square' satisfies FieldNameType}
        _register={register}
        _errorText={errors.square?.message}
      /> */}
    </Form>
  );
};

export default ProductForm;
