import React from 'react';
import { IonPage } from '@ionic/react';
import Centralizer from '../components/Centralizer/Centralizer';

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <Centralizer enableVerticalCentralization>
        <p>404 Page not found</p>
      </Centralizer>
    </IonPage>
  );
};

export default NotFoundPage;
