import { useEffect } from 'react';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { CommonLanding } from './CommonLanding';
import { LandingPageContextProvider } from './context';

const LandingPageBusiness = () => {
  useEffect(() => {
    trackEvent(AnalyticEventsEnum.VISIT_BUSINESS_LANDING);
  }, []);

  return (
    <LandingPageContextProvider source="business">
      <CommonLanding />
    </LandingPageContextProvider>
  );
};

export default LandingPageBusiness;
