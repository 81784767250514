import clsx from 'clsx';
import { IonIcon } from '@ionic/react';

import sg from '../../styles/global.module.scss';
import s from './TaggedText.module.scss';

export interface TagType {
  icon?: string | null;
  title: string;
  value: string;
}

export const TaggedText: React.FC<TagType> = ({ title, value, icon }) => {
  return (
    <div className={clsx(sg.Stack_Horizontal, sg.Align_Items_Up, sg.Gap_15)}>
      {icon && (
        <div>
          <IonIcon icon={icon} className={clsx(s.IonIcon)} />
        </div>
      )}

      <div>
        <p className={clsx(s.Tag_Title)}>{title}:</p>
        <p className={clsx(s.Tag_Content)}>{value}</p>
      </div>
    </div>
  );
};
