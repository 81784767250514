import clsx from 'clsx';
import s from './Centralizer.module.scss';
import sg from '../../styles/global.module.scss';

interface Props {
  enableVerticalCentralization?: boolean;
  enablePagePadding?: boolean;
  pageLimitSize?: string;
  className?: string;
  children?: React.ReactNode;
  skip?: boolean;
  fullHeight?: boolean;
}

const Centralizer: React.FC<Props> = props => {
  if (props.skip) {
    return <>{props.children}</>;
  }

  return (
    <div
      style={{ height: '100%' }}
      className={clsx(
        sg.Stack_Vertical,
        s.Centralizer,
        props.enablePagePadding && s.Centralizer__enable_page_padding,
        props.enableVerticalCentralization &&
          s.Centralizer__enable_vertical_centralization,
        props.className
      )}
    >
      {props.pageLimitSize ? (
        <div
          style={{
            height: props.fullHeight ? '100%' : undefined,
            width: props.pageLimitSize ? '100%' : undefined,
            maxWidth: props.pageLimitSize,
          }}
        >
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};

export default Centralizer;
