import { IonIcon } from '@ionic/react';
import clsx from 'clsx';
import Centralizer from '../components/Centralizer/Centralizer';
import { Icons } from '../icons';
import sg from '../styles/global.module.scss';
import './AppTabLogo.styles.scss';

export const AppTabLogo: React.FC<{
  isTabActive: boolean;
}> = ({ isTabActive }) => {
  return (
    <>
      <div
        id="fakeTabBackgroundContainer"
        className={clsx(sg.Stack_Horizontal)}
      >
        <div id="firstPieceOfFakeBackground" />
        <div id="secondPieceOfFakeBackground" />
        <div id="thirdPieceOfFakeBackground" />
      </div>

      <div id="externalRound">
        <Centralizer enableVerticalCentralization>
          <div id="internalRound">
            <IonIcon
              id="activeTabEventmaker"
              style={{
                display: isTabActive ? undefined : 'none',
              }}
              icon={Icons.tabActive}
            />

            <IonIcon
              id="inactiveTabEventmaker"
              style={{
                display: !isTabActive ? undefined : 'none',
              }}
              icon={Icons.tabInactive}
            />
          </div>
        </Centralizer>
      </div>
    </>
  );
};
