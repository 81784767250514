import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { convertToOtherCase } from '../@shared/helpers/case';
import { Product } from '../models/product';
import type { ReduxRootState } from './store';
import { Business } from '../models/business';
import { HelpRequest } from '../models/helpRequest';

const bq = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/api`,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as ReduxRootState;
    const token = state.auth._token;
    const selectedLanguage = state.auth.selectedLanguage;

    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }

    if (selectedLanguage) {
      headers.set('Selected-Language', selectedLanguage);
    }

    headers.set('Content-Type', 'application/json');

    return headers;
  },
});

export const api = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    if (args.body) args.body = convertToOtherCase(args.body, 'snake');
    const res = await bq(args, api, extraOptions);
    return convertToOtherCase(res, 'camel');
  },
  tagTypes: ['Product', 'Business'],
  endpoints: builder => ({
    // === Product ===
    getProductsBySearch: builder.query<
      Product[],
      { search: string; offset: number; limit: number }
    >({
      query: ({ search, offset, limit }) =>
        `products/product/?${search}&offset=${offset}&limit=${limit}`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    getProduct: builder.query<Product, { id: string }>({
      query: ({ id }) => `products/product/${id}`,
      providesTags: (result, error, { id }) => [
        { type: 'Product' as const, id },
      ],
    }),
    addProduct: builder.mutation<Product, { body: Product<{ plain: true }> }>({
      query: ({ body }) => ({
        url: 'products/product/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    editProduct: builder.mutation<
      Product,
      { id: string; body: Product<{ plain: true }> }
    >({
      query: ({ id, body }) => ({
        url: `products/product/${id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Product', id }],
    }),
    // === Business ===
    getBusiness: builder.query<Business, { id: string }>({
      query: ({ id }) => `businesses/business/${id}`,
      providesTags: (result, error, { id }) => [
        { type: 'Business' as const, id },
      ],
    }),
    // === HelpRequest ===
    addHelpRequest: builder.mutation<HelpRequest, { body: HelpRequest }>({
      query: ({ body }) => ({
        url: 'users/help_request/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  // === Product ===
  useGetProductsBySearchQuery,
  useLazyGetProductsBySearchQuery,
  useGetProductQuery,
  useAddProductMutation,
  useEditProductMutation,
  // === Business ===
  useGetBusinessQuery,
  // === HelpRequest ===
  useAddHelpRequestMutation,
} = api;
