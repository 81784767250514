import { useState } from 'react';
import { Chip } from './Chip/Chip';
import {
  FindProductsForm,
  FindProductsFormValues,
} from './Subforms/FindProductsForm';
import {
  OrganizeEventForm,
  OrganizeEventFormValues,
} from './Subforms/OrganizeEventForm';
import { calendar, search } from 'ionicons/icons';
import s from './Form.module.scss';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../utils/phoneNumber';

export enum HelpUserFormType {
  FindProducts = 'findProducts',
  OrganizeEvent = 'organizeEvent',
}

type Props = {
  applyCustomStyles: boolean;
};

export const CustomerHelpForm: React.FC<Props> = ({ applyCustomStyles }) => {
  const [activeForm, setActiveForm] = useState<HelpUserFormType | null>(
    applyCustomStyles ? HelpUserFormType.OrganizeEvent : null
  );

  const submitCallbackUserHelpForm = async (
    values: OrganizeEventFormValues | FindProductsFormValues
  ) => {
    const type = 'type' in values ? values.type : null;
    const date = `${values.date}`;
    const text = `${values.text}`;

    let content;

    switch (activeForm) {
      case HelpUserFormType.OrganizeEvent:
        content = `Hey, EventMaker! Can you organize event for me?\n
Event type: ${type}.
Date: ${date}.
Request: ${text}.`;
        break;
      case HelpUserFormType.FindProducts:
        content = `Hey, EventMaker! Can you find event services for me?\n
Date: ${date}.
Request: ${text}.`;
        break;
    }

    const link = `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(
      content || ''
    )}`;

    trackEvent(AnalyticEventsEnum.LEAD);
    window.open(link);
  };

  return (
    <div>
      <div className={s.Chips_container}>
        <Chip
          applyCustomStyles={applyCustomStyles}
          label="Plan Event"
          isActive={activeForm === HelpUserFormType.OrganizeEvent}
          icon={calendar}
          onClick={() => setActiveForm(HelpUserFormType.OrganizeEvent)}
        />

        <Chip
          applyCustomStyles={applyCustomStyles}
          label="Find Services"
          isActive={activeForm === HelpUserFormType.FindProducts}
          icon={search}
          onClick={() => setActiveForm(HelpUserFormType.FindProducts)}
        />
      </div>

      {activeForm === HelpUserFormType.OrganizeEvent ? (
        <OrganizeEventForm
          initialFormValues={{ type: '', date: '', text: '' }}
          submitCallback={submitCallbackUserHelpForm}
          applyCustomStyles={applyCustomStyles}
        />
      ) : activeForm === HelpUserFormType.FindProducts ? (
        <FindProductsForm
          initialFormValues={{ date: '', text: '' }}
          submitCallback={submitCallbackUserHelpForm}
          applyCustomStyles={applyCustomStyles}
        />
      ) : null}
    </div>
  );
};
