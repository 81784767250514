import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page/Page';
import { useTypedSelector } from '../../../redux/hooks';
import SearchContent from '../../../components/SearchContent/SearchContent';
import { SearchTypeEnum } from '../../../components/SearchBlock/interface';

export const BusinessProductsPage: React.FC = () => {
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject);

  // Without this flexbox on <ProductCard /> does not work
  const [isReady, setIsReady] = useState(false);
  useEffect(() => setIsReady(true), []);

  return (
    <Page
      pageLimitDisabled
      disableDefaultIonContent
      headerProps={{
        title: 'My offerings',
        hideBackButton: true,
        routerLink__add: 'products/create',
      }}
    >
      {isReady && (
        <SearchContent
          isBusinessPlatform
          initialSearchArgs={{
            type: SearchTypeEnum.BY_KEYWORDS,
            searchTerm: '',
            businessId: activeSubject!.objectId,
          }}
        />
      )}
    </Page>
  );
};

export default React.memo(BusinessProductsPage);
