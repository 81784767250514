import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import UnvisibleText from '../UnvisibleText/UnvisibleText';
import {
  BUTTON_WIDTH,
  LeftArrow,
  RightArrow,
} from '../HorizontalScrollHelpers/HorizontalScrollHelpers';
import { Product } from '../../models/product';
import Centralizer from '../Centralizer/Centralizer';
import ProductCard from '../ProductCard/ProductCard';
import {
  FetchMoreType,
  INITIAL_PER_CATEGORY_LIMIT,
  StatusType,
} from './useGroup.hook';
import FetchMoreByCategory from './FetchMoreByCategory';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import { useSmoothScroll } from './useSmoothScroll.hook';

import sg from '../../styles/global.module.scss';
import clsx from 'clsx';

// Smooth Scroll For ADVERTISEMENT video
// const scrollingDistance = window.innerHeight * 5;
// const scrollingTime = 13000;

// function easeInOutCubic(x: number, middle?: number): number {
//   return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
// }

// function easeWithPauses(t: any) {
//   const scroll = 0.1; // Time of scrolling
//   const coef = 10; // 10 * 0.1 = 1;

//   if (t < 0 + scroll) return 0 + easeInOutCubic((t - 0) * coef) * 0.25;
//   if (t < 0.25) return 0.25;

//   if (t < 0.25 + scroll) return 0.25 + easeInOutCubic((t - 0.25) * coef) * 0.25;
//   if (t < 0.5) return 0.5;

//   if (t < 0.5 + scroll) return 0.5 + easeInOutCubic((t - 0.5) * coef) * 0.25;
//   if (t < 0.75) return 0.75;

//   if (t < 0.75 + scroll) return 0.75 + easeInOutCubic((t - 0.75) * coef) * 0.25;
//   if (t < 1) return 1;

//   return 1;
// }

// function scrollWithPauses(
//   element: any,
//   value: any,
//   duration: any,
//   easingFunc: any
// ) {
//   var startTime: any;
//   var startPos = element.scrollTop;
//   var clientHeight = element.clientHeight;
//   var maxScroll = element.scrollHeight - clientHeight;
//   var scrollIntendedDestination = startPos + value;
//   // low and high bounds for possible scroll destinations
//   var scrollEndValue = Math.min(
//     Math.max(scrollIntendedDestination, 0),
//     maxScroll
//   );
//   // create recursive function to call every frame
//   var scroll = function (timestamp: any) {
//     startTime = startTime || timestamp;
//     var elapsed = timestamp - startTime;
//     element.scrollTop =
//       startPos + (scrollEndValue - startPos) * easingFunc(elapsed / duration);
//     elapsed <= duration && window.requestAnimationFrame(scroll);
//   };
//   // call recursive function
//   if (startPos !== scrollEndValue) window.requestAnimationFrame(scroll);
// }

type ColumnContext = {
  products: (Product | FetchMoreType)[];
  executeFetchMore: () => void;
  status: StatusType;
};

const Column = ({
  data: { products, executeFetchMore, status },
  index,
  style,
}: {
  data: ColumnContext;
  index: number;
  style: React.CSSProperties;
}) => {
  const productOrFetchMore = products[index];
  const isFetchMore = typeof productOrFetchMore === 'function';

  return (
    <div
      key={!isFetchMore ? productOrFetchMore.id : 'fetchMoreContext'}
      style={style}
    >
      {!isFetchMore ? (
        <ProductCard disableSwipeOnTouch product={productOrFetchMore} />
      ) : (
        <FetchMoreByCategory
          status={status}
          executeFetchMore={executeFetchMore}
        />
      )}
    </div>
  );
};

type Props = {
  products: (Product | FetchMoreType)[];
  productCardSize: { width: number; height: number };
  tableWrapperWidth: number;
  search: string;
};

const ViewByCategory: React.FC<Props> = ({
  products,
  productCardSize: { width, height },
  tableWrapperWidth,
  search,
}) => {
  const category = (products[0] as Product).category;

  const { listOuterRef, scrollBy, isRightEdge, isLeftEdge, trackEdges } =
    useSmoothScroll([]);

  /* Fetch more for current category */
  const [status, setStatus] = useState<StatusType>();

  useEffect(() => {
    setStatus(
      products.length < INITIAL_PER_CATEGORY_LIMIT + 1 ? 'finish' : undefined
    );
  }, [search, products.length]);

  const executeFetchMore = useCallback(() => {
    setStatus('loading');
    const fetchMore = products[products.length - 1] as FetchMoreType;
    fetchMore(category).then(s => {
      setStatus(s);
      setTimeout(() => trackEdges(true), 100);
    });
  }, [category, products, trackEdges]);

  const indexForTriggerFetchMore = useMemo(() => {
    return products.length - INITIAL_PER_CATEGORY_LIMIT;
  }, [products]);

  const listRef = useRef<FixedSizeList>(null);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.forceUpdate();
      listRef.current.scrollTo(0);
    }
  }, [listRef, search]);

  return (
    <>
      <Centralizer>
        <UnvisibleText />
        <p
          // onClick={() => {
          //   const scrollContainer = document.getElementById(
          //     'MyTableVirtuoso'
          //   ) as HTMLElement;

          //   scrollWithPauses(
          //     scrollContainer,
          //     scrollingDistance,
          //     scrollingTime,
          //     easeWithPauses
          //   );
          // }}
          style={{
            width: 'fit-content',
            textAlign: 'center',
            color: 'rgba(255, 255, 255, 0.8)',
            marginTop: '10px',
            fontWeight: 500,
            fontSize: '30px',
            fontFamily: 'Preahvihear',
          }}
        >
          {category.name.replace('attractions', '')}
        </p>
      </Centralizer>

      <div
        className={clsx(sg.Stack_Horizontal)}
        style={{ marginBottom: '15px' }}
      >
        <LeftArrow disabled={isLeftEdge} scrollBy={scrollBy} />

        <List
          ref={listRef}
          layout="horizontal"
          className={clsx(sg.DisableScrollbar, category.name)}
          outerRef={listOuterRef}
          height={height}
          width={tableWrapperWidth - 2 * BUTTON_WIDTH}
          itemCount={products.length}
          itemSize={width}
          itemData={
            {
              products,
              executeFetchMore,
              status,
            } satisfies ColumnContext
          }
          onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
            if (
              visibleStopIndex > indexForTriggerFetchMore &&
              visibleStartIndex !== 0 &&
              !status
            ) {
              executeFetchMore();
            }
          }}
        >
          {Column}
        </List>

        <RightArrow disabled={isRightEdge} scrollBy={scrollBy} />
      </div>
    </>
  );
};

export default React.memo(ViewByCategory);
