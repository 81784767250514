import React from 'react';
import s from './AreYouBusiness.module.scss';
import { ActionButton } from '../../ActionButton/ActionButton';
import { useLandingPageContext } from '../../../context';
import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const AreYouBusiness = () => {
  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section className={s.section}>
      <div data-animation className={s.left}>
        <h3 className={s.section__title}>
          <span>Are you a business </span>that offers event services?
        </h3>
        <p className={s.section__text}>
          Get new clients by showcasing your offerings on our platform.
        </p>
        <span onClick={() => (window.location.href = `/business`)}>
          <ActionButton skipDefaultAction text="Know More" />
        </span>
      </div>
      <img
        data-animation
        alt=""
        className={s.img}
        src={getStorageUrl('landing/busy.webp')}
      />
    </section>
  );
};
