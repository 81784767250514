import { DocumentReference, doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import { useEffect, useState } from 'react';
import { useAppVersion } from './hooks/hook.version';
import { IonModal } from '@ionic/react';
import Centralizer from './components/Centralizer/Centralizer';

type AppConfigMain = {
  minVersion: string;
};

export const AppMinRequiredVersionModal = () => {
  const { versionNumber, isCapacitor } = useAppVersion();
  const [minRequiredVersion, setMinRequiredVersion] = useState<string>();

  useEffect(() => {
    if (!isCapacitor) return;

    const uploadingDoc = doc(
      firestore,
      'AppConfig',
      'main'
    ) as DocumentReference<AppConfigMain>;

    getDoc(uploadingDoc).then(value =>
      setMinRequiredVersion(value.data()?.minVersion)
    );
  }, [isCapacitor]);

  const isDeprecated =
    isCapacitor && !!minRequiredVersion && versionNumber < minRequiredVersion;

  return (
    <IonModal
      mode="ios"
      animated={false}
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={isDeprecated}
      style={{ '--height': '100%', '--width': '100%' }}
    >
      <Centralizer enableVerticalCentralization enablePagePadding>
        <p>App update required.</p>
        <p>
          Please visit appstore and download new version of this application.
        </p>
      </Centralizer>
    </IonModal>
  );
};
