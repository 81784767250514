import { IonInput } from '@ionic/react';
import clsx from 'clsx';
import { RefCallBack } from 'react-hook-form';
import s from './InputYAnchor.module.scss';

type InputYAnchorProps = {
  refCallback: RefCallBack;
};

/* 
Hided input component usefull for navigate to it
when validation error occured
check "./components/Form/submit.hook.ts"
*/
const InputYAnchor: React.FC<InputYAnchorProps> = ({ refCallback }) => {
  return (
    <IonInput
      label=""
      ref={refCallback}
      className={clsx(s.InputYAnchor)}
      value="Required if we want .setFocus() do navigation"
    />
  );
};

export default InputYAnchor;
