import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import Select from '../Inputs/InputSelect/Select';
import { locationOutline } from 'ionicons/icons';
import InputSelectOption from '../Inputs/InputSelect/InputSelectOption';
import { City } from '../../models/business';
import { choicesSlice } from '../../redux/slices/choices/slice';
import { IonIcon } from '@ionic/react';

import s from './LocationPicker.module.scss';
import sg from '../../styles/global.module.scss';

type Props = {};

const LocationPicker: React.FC<Props> = () => {
  const cities = useTypedSelector(s => s.choices.citySet);
  const selectedCity = useTypedSelector(s => s.choices.selectedCity);
  const dispatch = useTypedDispatch();

  return (
    <div className={clsx(sg.Stack_Horizontal)}>
      <IonIcon icon={locationOutline} className={clsx(s.IonIcon_Location)} />

      <Select
        className={clsx(s.Select)}
        defaultValue={selectedCity}
        onChange={(v: City) =>
          dispatch(choicesSlice.actions.setSelectedCity(v))
        }
      >
        {cities.map(city => (
          <InputSelectOption
            key={city.id}
            value={city}
            textPreview={city.name + ', ' + city.country.name}
          />
        ))}
      </Select>
    </div>
  );
};

export default LocationPicker;
