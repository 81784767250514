import { IonContent, IonItem, IonList, IonMenu } from '@ionic/react';
import { AppLogoDesktop } from './AppLogoDesktop';
import { AppConfigType } from './types';

export const AppMenu: React.FC<{
  config: AppConfigType;
  isFormPage: boolean;
}> = ({ config, isFormPage }) => {
  return (
    <IonMenu contentId="main">
      <AppLogoDesktop />

      <IonContent>
        <IonList>
          {config.map(cfg => (
            <IonItem
              key={cfg.link}
              style={{
                margin: '5px',
                borderRadius: '10px',
                '--background': cfg.isActive ? 'grey' : '',
              }}
              // Pages was not unmounted, so we used to apply this workaround
              // https://github.com/ionic-team/ionic-framework/issues/20597
              routerDirection={isFormPage ? 'back' : 'none'}
              routerLink={cfg.link}
            >
              <p>{cfg.name}</p>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
