import { IonContent, IonPage } from '@ionic/react';
import Spinner from '../components/Spinner/Spinner';
import Centralizer from '../components/Centralizer/Centralizer';

const LoadingPage: React.FC = () => {
  return (
    <IonPage className="myIonPage">
      <IonContent>
        <Centralizer enableVerticalCentralization={true}>
          <Spinner />
        </Centralizer>
      </IonContent>
    </IonPage>
  );
};

export default LoadingPage;
