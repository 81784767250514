import { IonContent, IonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import Centralizer from '../Centralizer/Centralizer';
import Spinner from '../Spinner/Spinner';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { globalSlice } from '../../redux/slices/global/slice';
import s from './GlobalHider.module.scss';
import clsx from 'clsx';
import { IS_INSTAGRAM } from '../../utils/browser';

const GlobalHider: React.FC = () => {
  const dispatch = useTypedDispatch();
  const isHiderVisible = useTypedSelector(s => s.global.isHiderVisible);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isHiderVisible) {
      setIsOpen(true);

      setTimeout(() => {
        dispatch(globalSlice.actions.setModalHiderVisibility(false));
      }, 1000);
    } else {
      setIsOpen(false);
    }
  }, [isHiderVisible, dispatch]);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      className={clsx(s.IonModal)}
      isOpen={isOpen}
      mode="md"
    >
      <IonContent style={{ '--overflow': 'hidden' }}>
        <Centralizer enableVerticalCentralization>
          <Spinner size={50} text="Please wait..." />
        </Centralizer>
      </IonContent>
    </IonModal>
  );
};

export default GlobalHider;
