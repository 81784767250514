import clsx from 'clsx';
import s from './MediaSlide.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import {
  buildUrlsFromGenericFile,
  getFileCategoryFromGenericFile,
} from '../Inputs/InputFile/oneFile/helpers';
import { getPlatforms, IonIcon, isPlatform } from '@ionic/react';
import {
  documentOutline,
  playCircleOutline,
  videocamOutline,
} from 'ionicons/icons';
import { GenericFile } from '../Inputs/InputFile/interfaces';
import Centralizer from '../Centralizer/Centralizer';
import { FileCategoryEnum } from '../../@shared/file';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import ImageDynamic from '../ImageDynamic/ImageDynamic';

type MediaSlideProps = {
  genericFile: GenericFile | undefined;
  zoom: number;
  activeIndex: number | undefined;
};

type HTMLVideoElementExtended = HTMLVideoElement & {
  webkitEnterFullScreen?: () => Promise<void>;
};

const MediaSlide: React.FC<MediaSlideProps> = ({
  genericFile,
  zoom,
  activeIndex,
}) => {
  const fileCategory = getFileCategoryFromGenericFile(genericFile);
  const isUploadedToBackend = !!genericFile?.uploadedFile;

  const [videoStatus, setVideoStatus] = useState<
    'stopped' | 'played' | 'paused'
  >();

  const urls = buildUrlsFromGenericFile(genericFile);

  const viewRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef(new Image());
  const videoRef = useRef<HTMLVideoElementExtended>(null);

  const pos = useRef({ top: 0, left: 0, x: 0, y: 0 });

  const mouseDownHandler = function (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    e.preventDefault();

    if (zoom === 1) {
      return;
    }

    pos.current = {
      left: viewRef.current!.scrollLeft,
      top: viewRef.current!.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);

    viewRef.current!.style.cursor = 'grabbing';
    viewRef.current!.style.userSelect = 'none';
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    viewRef.current!.style.cursor = 'grab';
    viewRef.current!.style.removeProperty('user-select');
  };

  const mouseMoveHandler = function (e: MouseEvent) {
    const dx = e.clientX - pos.current.x;
    const dy = e.clientY - pos.current.y;

    viewRef.current!.scroll({
      top: pos.current.top - dy,
      left: pos.current.left - dx,
    });
  };

  const [sizes, setSizes] = useState<{
    container: {
      width: number;
      height: number;
    };
    thumbnail: {
      width: number;
      height: number;
    };
    orientation: 'vertical' | 'horizontal';
    thumbnailRatio: number;
    containerRatio: number;
  }>();

  const stopVideo = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0;
      setVideoStatus('stopped');

      // for better animation while
      // opening video second time
      video.style.display = 'none';
      setTimeout(() => {
        video.style.display = 'auto';
      }, 0);
    }
  }, []);

  useEffect(() => {
    stopVideo();
  }, [activeIndex, stopVideo]);

  useEffect(() => {
    if (!containerRef.current || !viewRef.current || !imageRef.current) {
      return;
    }

    setTimeout(() => {
      if (!sizes) {
        const _sizes = {
          container: {
            height: containerRef.current!.offsetHeight,
            width: containerRef.current!.offsetWidth,
          },
          thumbnail: {
            width: imageRef.current!.naturalWidth,
            height: imageRef.current!.naturalHeight,
          },
        };

        if (
          _sizes.container.height &&
          _sizes.container.width &&
          _sizes.thumbnail.height &&
          _sizes.thumbnail.width
        ) {
          const containerRatio =
            _sizes.container.width / _sizes.container.height;
          const thumbnailRatio =
            _sizes.thumbnail.width / _sizes.thumbnail.height;
          const orientation =
            containerRatio > thumbnailRatio ? 'vertical' : 'horizontal';

          if (orientation === 'vertical') {
            imageRef.current!.style.height = '100%';
          } else {
            imageRef.current!.style.width = '100%';
          }

          setSizes({ ..._sizes, orientation, thumbnailRatio, containerRatio });
        }
      } else {
        if (zoom === 1) {
          viewRef.current!.style.overflow = 'hidden';
        } else {
          viewRef.current!.style.overflow = 'scroll';
        }

        if (sizes.orientation === 'vertical') {
          const height = sizes.container.height * zoom;
          const width = sizes.thumbnailRatio * height;

          containerRef.current!.style.height = `${height}px`;
          containerRef.current!.style.width = `${width}px`;

          if (width < sizes.container.width) {
            containerRef.current!.style.left = '50%';
            containerRef.current!.style.transform = 'translateX(-50%)';
          } else {
            containerRef.current!.style.left = 'unset';
            containerRef.current!.style.transform = 'unset';
          }
        } else {
          const width = sizes.container.width * zoom;
          const height = (1 * width) / sizes.thumbnailRatio;

          containerRef.current!.style.height = `${height}px`;
          containerRef.current!.style.width = `${width}px`;

          if (height < sizes.container.height) {
            containerRef.current!.style.top = '50%';
            containerRef.current!.style.transform = 'translateY(-50%)';
          } else {
            containerRef.current!.style.top = 'unset';
            containerRef.current!.style.transform = 'unset';
          }
        }
      }
      /* workaround */
    }, 100);
  }, [zoom, sizes]);

  return (
    <>
      {videoStatus &&
        ['paused', 'stopped'].includes(videoStatus!) &&
        isUploadedToBackend && (
          <ButtonMicroWrapper
            resizeAnimation
            className={clsx(s.Play__button)}
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();

              const video = videoRef.current!;
              video.style.display = 'block';

              setVideoStatus('played');
              video.controls = isPlatform('desktop');

              // IOS
              if (
                getPlatforms().includes('ios') &&
                video.webkitEnterFullScreen
              ) {
                await video.webkitEnterFullScreen();
              }

              // if (video.webkitRequestFullscreen) { await video.webkitRequestFullscreen() }
              // if (video.webkitSupportsFullscreen) { const isSupported = video.webkitSupportsFullscreen() }
              // video.addEventListener('webkitbeginfullscreen', () => {});

              video.addEventListener('webkitendfullscreen', () => {
                stopVideo();
              });

              video.play();
            }}
          >
            <IonIcon
              icon={playCircleOutline}
              style={{
                backgroundColor: 'var(--color-main-high)',
                color: 'black',
              }}
            />
          </ButtonMicroWrapper>
        )}

      {!isUploadedToBackend && <Spinner size={50} />}

      <div
        className={clsx(s.View__container)}
        ref={viewRef}
        onMouseDown={isPlatform('mobile') ? undefined : mouseDownHandler}
        style={{
          overflow: isPlatform('mobile') ? 'auto' : 'scroll',
        }}
      >
        <div ref={containerRef}>
          <div
            className="swiper-zoom-container"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            {urls.thumbnail.low &&
            urls.thumbnail.medium &&
            urls.thumbnail.high ? (
              <ImageDynamic
                urls={urls}
                objectFit="cover"
                quality={isPlatform('desktop') ? 'high' : 'medium'}
                style={{
                  objectFit: 'contain',
                  opacity: isUploadedToBackend ? 1 : 0.2,
                  display: ['played', 'paused'].find(x => x === videoStatus)
                    ? 'none'
                    : 'block',
                }}
                externalRef={imageRef}
              />
            ) : (
              <Centralizer enableVerticalCentralization>
                <IonIcon
                  icon={
                    fileCategory === FileCategoryEnum.VIDEO
                      ? videocamOutline
                      : documentOutline
                  }
                  className={clsx(s.IonIcon_Placeholder)}
                />
              </Centralizer>
            )}

            {fileCategory === FileCategoryEnum.VIDEO && (
              <video
                key={`video-${genericFile?.id}`}
                preload="auto"
                src={urls.content[isPlatform('desktop') ? 'high' : 'medium']}
                disablePictureInPicture={true}
                style={{ display: 'none' }}
                ref={videoRef}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaSlide;
