import * as Yup from 'yup';
import { UploadedFile } from '../@shared/file';

export const validationErrors = {
  REQUIRED: 'Required field',
  HOURS: 'Select value between 1 and 24',
  EMAIL: 'Incorrect email format',
};

export const validators = {
  uploadedFiles: Yup.array()
    .min(1, 'At least 1 file is required')
    .of(
      Yup.object().shape<Record<keyof UploadedFile, Yup.AnySchema>>({
        id: Yup.string(),
        fileCategory: Yup.string(),
        accessControl: Yup.string(),
        bucketName: Yup.string(),
        user: Yup.string(),
        compressedImageHigh: Yup.string().nullable(),
        compressedImageMedium: Yup.string().nullable(),
        compressedImageLow: Yup.string().nullable(),
        videoOriginal: Yup.string().nullable(),
        compressedVideoHigh: Yup.string().nullable(),
        compressedVideoMedium: Yup.string().nullable(),
        documentOriginal: Yup.string().nullable(),
      })
    )
    .test(
      'is-uploaded',
      'Please wait until all files will be uploaded',
      uploadedFiles => {
        return (
          uploadedFiles?.findIndex((uf: UploadedFile | null) => !uf) === -1
        );
      }
    ),
  uploadedFilesNoMin: Yup.array().test(
    'is-uploaded',
    'Please wait until all files will be uploaded',
    uploadedFiles => {
      return uploadedFiles?.findIndex((uf: UploadedFile | null) => !uf) === -1;
    }
  ),
  phoneNumber: Yup.string()
    .matches(
      /^\+(\d*)$/,
      'Should starts with "+" and contain only numbers. Example: +12553003535'
    )
    .required(validationErrors.REQUIRED)
    .min(10, 'Should contain 10-15 symbols')
    .max(15, 'Should contain 10-15 symbols'),
  nullableNumber: Yup.number()
    .nullable()
    .transform(value => value || null),
  nullableString: Yup.string()
    .nullable()
    .transform(value => value || null),
};
