import React, { useRef, useState } from 'react';
import { UrlsType } from '../Inputs/InputFile/oneFile/helpers';

type Props = {
  urls: UrlsType;
  quality: 'high' | 'medium' | 'low';
  externalRef?: React.RefObject<HTMLImageElement>;
  objectFit: 'contain' | 'cover';
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  className?: string;
};

const ImageDynamic: React.FC<Props> = ({
  quality,
  urls,
  objectFit,
  style,
  externalRef,
  onClick,
  className,
}) => {
  const [isQualityReady, setIsQualityReady] = useState(false);

  const internalRef = useRef<HTMLImageElement>(null);
  const ref = externalRef || internalRef;

  return (
    <img
      ref={ref}
      src={!isQualityReady ? urls.thumbnail.low : urls.thumbnail[quality]}
      alt=""
      onClick={onClick}
      className={className}
      style={{
        width: '100%',
        height: '100%',
        objectFit,
        filter: 'blur(0.5rem)',
        ...style,
      }}
      onLoad={() => {
        if (ref.current?.src && ref.current?.src !== urls.thumbnail[quality]) {
          setTimeout(() => {
            if (ref.current) ref.current.src = urls.thumbnail[quality];
          }, 0);
        }

        if (ref.current?.src === urls.thumbnail[quality]) {
          setIsQualityReady(true);
          if (ref.current) ref.current!.style.filter = 'unset';
        }
      }}
    />
  );
};

export default ImageDynamic;
