import clsx from 'clsx';
import s from './InputWrapper.module.scss';
import sg from '../../../styles/global.module.scss';

interface Props {
  label?: string;
  errorText?: string;
  active?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  labelClassName?: string;
}

const InputWrapper: React.FC<Props> = props => {
  return (
    <div
      className={clsx(
        sg.Stack_Vertical,
        sg.Gap_5,
        sg.Flex_On,
        s.InputWrapper__container
      )}
      style={props.style}
    >
      {props.label && (
        <h4 className={clsx(props.labelClassName)}>{props.label}:</h4>
      )}

      {props.children}

      {props.errorText && (
        <div className={clsx(s.TextLabel__container)}>
          <div className={clsx(s.TextLabel__container_error)}>
            {props.errorText && <p>{props.errorText}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputWrapper;
