import React, { useEffect, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import {
  IonIcon,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import {
  globeOutline,
  heartOutline,
  notificationsOutline,
  settingsOutline,
} from 'ionicons/icons';
import NotificationsPage from '../pages/NotificationsPage';
import UserSettingsRoutes from '../pages/user/settings/UserSettingsRoutes';
import EventsPage from '../pages/EventsPage';
import UserCatalogRoutes from '../pages/user/catalog/UserCatalogRoutes';
import { SubjectGeneric, subjectsSlice } from '../redux/slices/subjects/slice';
import { useTypedDispatch } from '../redux/hooks';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import { isPlatform } from '@ionic/core';
import { AppMenu } from './AppMenu';
import { AppConfigType } from './types';
import clsx from 'clsx';
import { isUserFormPage } from './helpers';
import { AppTabLogo } from './AppTabLogo';
import FavouritesPage from '../pages/user/FavouritesPage';
import UserEventsRoutes from '../pages/user/events/UserEventsRoutes';
import { IS_IOS_NATIVE, IS_IOS_PWA } from '../utils/browser';
import InstagramRedirectRoutes from '../pages/landing/InstagramRedirectRoutes';

const AppUser: React.FC = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const flow = useMemo(() => {
    const search = new URLSearchParams(location.search);
    return search.get('flow');
  }, [location.search]);

  const dispatch = useTypedDispatch();
  useEffect(() => {
    const activeSubject: SubjectGeneric = {
      contentType: 'user',
      objectId: undefined,
    };
    dispatch(subjectsSlice.actions.setActiveSubject(activeSubject));
  }, [dispatch]);

  const config: AppConfigType = useMemo(() => {
    return [
      {
        link: '/u/catalog',
        name: 'Catalog',
        icon: globeOutline,
      },
      {
        link: '/u/saved',
        name: 'Saved',
        icon: heartOutline,
      },
      {
        link: '/u/events',
        name: 'My Events',
        icon: 'custom',
      },
      {
        link: '/u/notifications',
        name: 'Notifications',
        icon: notificationsOutline,
      },
      {
        link: '/u/settings',
        name: 'Settings',
        icon: settingsOutline,
      },
    ].map(cfg => ({ ...cfg, isActive: pathName.startsWith(cfg.link) }));
  }, [pathName]);

  const isFormPage = useMemo(() => isUserFormPage(pathName), [pathName]);
  const isCentralTabActive = pathName.startsWith(config[2].link);

  return isPlatform('desktop') ? (
    <IonSplitPane contentId="main" when="xs">
      {flow !== '1' && <AppMenu config={config} isFormPage={isFormPage} />}

      <IonRouterOutlet id="main" animated={false} mode="md">
        <Route path="/u/catalog">
          <UserCatalogRoutes />
        </Route>

        <Route path="/u/saved">
          <FavouritesPage />
        </Route>

        <PrivateRoute path="/u/events">
          <UserEventsRoutes />
        </PrivateRoute>

        <PrivateRoute path="/u/notifications">
          <NotificationsPage />
        </PrivateRoute>

        <PrivateRoute path="/u/settings">
          <UserSettingsRoutes />
        </PrivateRoute>
      </IonRouterOutlet>
    </IonSplitPane>
  ) : (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/u/catalog">
          <UserCatalogRoutes />
        </Route>

        <Route path="/u/saved">
          <FavouritesPage />
        </Route>

        <PrivateRoute path="/u/events">
          <UserEventsRoutes />
        </PrivateRoute>

        <PrivateRoute path="/u/notifications">
          <NotificationsPage />
        </PrivateRoute>

        <PrivateRoute path="/u/settings">
          <UserSettingsRoutes />
        </PrivateRoute>
      </IonRouterOutlet>

      <IonTabBar
        id="ionTabBar"
        slot="bottom"
        mode="ios"
        className={clsx(
          (IS_IOS_NATIVE || IS_IOS_PWA) && 'fillColorToBottom',
          'disableIosSafariSwipeBlocker'
        )}
        style={isFormPage ? { display: 'none' } : undefined}
      >
        {flow !== '1' &&
          config.map(cfg =>
            cfg.icon !== 'custom' ? (
              <IonTabButton
                key={cfg.link}
                tab={cfg.name}
                href={cfg.link}
                className="disableIosSafariSwipeBlocker"
              >
                <IonIcon
                  icon={cfg.icon}
                  className="disableIosSafariSwipeBlocker"
                />
              </IonTabButton>
            ) : (
              <IonTabButton
                key={cfg.link}
                tab={cfg.name}
                href={cfg.link}
                className="centralTab"
              >
                <AppTabLogo isTabActive={isCentralTabActive} />
              </IonTabButton>
            )
          )}
      </IonTabBar>
    </IonTabs>
  );
};

export default AppUser;
