import { IonSelectOption } from '@ionic/react';

interface InputSelectOptionInterface {
  value: any;
  textPreview: string;
}

const InputSelectOption: React.FC<InputSelectOptionInterface> = props => {
  return (
    <IonSelectOption value={props.value}>{props.textPreview}</IonSelectOption>
  );
};

export default InputSelectOption;
