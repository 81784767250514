import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessCategory, City, Country } from '../../../models/business';
import { EventCategory } from '../../../models/event';
import { ProductCategory } from '../../../models/product';

export enum ChoicesStorageKeys {
  COUNTRY_SET = 'choices_country_set',
  CITY_SET = 'choices_city_set',
  BUSINESS_CATEGORY_SET = 'choices_business_category_set',
  EVENT_CATEGORY_SET = 'choices_event_category_set',
  PRODUCT_CATEGORY_SET = 'choices_product_category_set',
  RECEIVED_AT = 'choices_received_at',
  SELECTED_CITY = 'choices_selected_city',
}

export interface ChoicesStoreState {
  countrySet: Country[];
  citySet: City[];
  businessCategorySet: BusinessCategory[];
  eventCategorySet: EventCategory[];
  productCategorySet: ProductCategory[];
  recievedAt: string | null;
  selectedCity: City | null;
}

const initialChoicesState: ChoicesStoreState = {
  countrySet: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.COUNTRY_SET) || '[]'
  ),
  citySet: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.CITY_SET) || '[]'
  ),
  businessCategorySet: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.BUSINESS_CATEGORY_SET) || '[]'
  ),
  eventCategorySet: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.EVENT_CATEGORY_SET) || '[]'
  ),
  productCategorySet: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.PRODUCT_CATEGORY_SET) || '[]'
  ),
  recievedAt: localStorage.getItem(ChoicesStorageKeys.RECEIVED_AT),
  selectedCity: JSON.parse(
    localStorage.getItem(ChoicesStorageKeys.SELECTED_CITY) || 'null'
  ),
};

export const choicesSlice = createSlice({
  name: 'choices',
  initialState: initialChoicesState,
  reducers: {
    setCountrySet(state: ChoicesStoreState, action: PayloadAction<Country[]>) {
      if (JSON.stringify(state.countrySet) === JSON.stringify(action.payload)) {
        return;
      }

      state.countrySet = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.COUNTRY_SET,
        JSON.stringify(action.payload)
      );
    },
    setCitySet(state: ChoicesStoreState, action: PayloadAction<City[]>) {
      if (JSON.stringify(state.citySet) === JSON.stringify(action.payload)) {
        return;
      }

      state.citySet = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.CITY_SET,
        JSON.stringify(action.payload)
      );
    },
    setBusinessCategorySet(
      state: ChoicesStoreState,
      action: PayloadAction<BusinessCategory[]>
    ) {
      if (
        JSON.stringify(state.businessCategorySet) ===
        JSON.stringify(action.payload)
      ) {
        return;
      }

      state.businessCategorySet = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.BUSINESS_CATEGORY_SET,
        JSON.stringify(action.payload)
      );
    },
    setEventCategorySet(
      state: ChoicesStoreState,
      action: PayloadAction<EventCategory[]>
    ) {
      if (
        JSON.stringify(state.eventCategorySet) ===
        JSON.stringify(action.payload)
      ) {
        return;
      }

      state.eventCategorySet = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.EVENT_CATEGORY_SET,
        JSON.stringify(action.payload)
      );
    },
    setProductCategorySet(
      state: ChoicesStoreState,
      action: PayloadAction<ProductCategory[]>
    ) {
      if (
        JSON.stringify(state.productCategorySet) ===
        JSON.stringify(action.payload)
      ) {
        return;
      }

      state.productCategorySet = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.PRODUCT_CATEGORY_SET,
        JSON.stringify(action.payload)
      );
    },
    setReceivedAt(state: ChoicesStoreState, action: PayloadAction<string>) {
      state.recievedAt = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.RECEIVED_AT,
        JSON.stringify(action.payload)
      );
    },
    setSelectedCity(state: ChoicesStoreState, action: PayloadAction<City>) {
      state.selectedCity = action.payload;

      localStorage.setItem(
        ChoicesStorageKeys.SELECTED_CITY,
        JSON.stringify(action.payload)
      );
    },
    setSelectedCityIfNotExists(
      state: ChoicesStoreState,
      action: PayloadAction<string>
    ) {
      if (!state.selectedCity) {
        const city = state.citySet.find(c => c.id === action.payload);
        if (city) {
          state.selectedCity = city;
          localStorage.setItem(
            ChoicesStorageKeys.SELECTED_CITY,
            JSON.stringify(city)
          );
        }
      }
    },
  },
});
