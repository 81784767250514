import s from "./Alert.module.scss";
import clsx from "clsx";
import { AlertOptions, useIonAlert } from "@ionic/react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { globalActionCreators } from "../../redux/slices/global/actionCreator";

const Alert: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [present] = useIonAlert();

  const errorNotBuildedYet = useTypedSelector((s) => s.global.error);
  const alertBuildedBefore = useTypedSelector((s) => s.global.alert);

  if (errorNotBuildedYet) {
    let alertObject: AlertOptions = {
      header: errorNotBuildedYet.header || "Error",
      message: errorNotBuildedYet.message,
      buttons: [
        {
          text: "Ok",
          role: "cancel",
          handler: () => {
            dispatch(globalActionCreators.setErrorText(null));
          },
        },
      ],
      mode: "ios",
      keyboardClose: true,
      backdropDismiss: false,
      cssClass: clsx(s.Alert),
    };
    present(alertObject);
  } else if (alertBuildedBefore) {
    present(alertBuildedBefore);
  }

  return null;
};

export default Alert;
