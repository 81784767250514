import React from 'react';
import clsx from 'clsx';
import s from './ButtonLabel.module.scss';
import ButtonMicroWrapper from '../ButtonMicroWrapper/ButtonMicroWrapper';
import { IonIcon } from '@ionic/react';

type Props = {
  label: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  iconSrc?: any;
  skipBorder?: boolean;
  toRight?: boolean;
};

const ButtonLabel: React.FC<Props> = ({
  onClick,
  label,
  iconSrc,
  skipBorder,
  toRight,
}) => {
  return (
    <ButtonMicroWrapper
      softDisabled={!onClick}
      noRipple
      className={clsx(
        s.ButtonMicroWrapper,
        !skipBorder ? s.ButtonMicroWrapper__add_border : '',
        !onClick ? s.ButtonMicroWrapper__zIndex_back : '',
        toRight ? s.ButtonMicroWrapper__right : ''
      )}
      onClick={onClick}
    >
      <>
        <p style={{ whiteSpace: 'nowrap' }}>{label}</p>
        {iconSrc && <IonIcon src={iconSrc} className={clsx(s.IonIcon)} />}
      </>
    </ButtonMicroWrapper>
  );
};

export default ButtonLabel;
