import React from 'react';
import s from './Chip.module.scss';
import { IonIcon } from '@ionic/react';
import clsx from 'clsx';

interface Props {
  applyCustomStyles: boolean;
  isActive: boolean;
  onClick: () => void;
  icon: string;
  label: string;
}

export const Chip = ({
  applyCustomStyles,
  isActive,
  onClick,
  icon,
  label,
}: Props) => {
  return (
    <div
      className={
        applyCustomStyles
          ? clsx(
              s.radioButton,
              s.radioButton__custom,
              isActive ? s.radioButton__custom_active : undefined
            )
          : clsx(
              s.radioButton,
              s.radioButton__normal,
              isActive ? s.radioButton__normal_active : undefined
            )
      }
      onClick={onClick}
    >
      <IonIcon icon={icon} />
      {label}
    </div>
  );
};
