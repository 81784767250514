import { AlertOptions, ToastOptions } from '@ionic/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorTextable {
  header?: string;
  message?: string;
}

export type ToastType = Omit<ToastOptions, 'positionAnchor'> & {
  priority?: number;
};

interface GlobalStoreState {
  error: ErrorTextable | null;
  alert: AlertOptions | null;
  toast: ToastType | null;
  screenLock: boolean;
  isHiderVisible: boolean;
}

const initialGlobalState: GlobalStoreState = {
  error: null,
  alert: null,
  toast: null,
  screenLock: false,
  isHiderVisible: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState,
  reducers: {
    setAlert(
      state: GlobalStoreState,
      action: PayloadAction<AlertOptions | null>
    ) {
      state.alert = action.payload;
    },
    setToast(state: GlobalStoreState, action: PayloadAction<ToastType>) {
      state.toast = action.payload;
    },
    /* used only for errors which could not be handled with 'useAlert' hook from component */
    setError(
      state: GlobalStoreState,
      action: PayloadAction<ErrorTextable | null>
    ) {
      if (JSON.stringify(state.error) === JSON.stringify(action.payload)) {
        return;
      }

      if (action.payload?.message === 'Failed to fetch') {
        state.error = {
          header: 'Невозможно присоединиться к серверу',
          message: 'Проверьте интернет соединение.',
        };
      } else {
        state.error = action.payload;
      }
    },
    changeScreenLock(state: GlobalStoreState, action: PayloadAction<void>) {
      state.screenLock = !state.screenLock;
    },
    setModalHiderVisibility(
      state: GlobalStoreState,
      action: PayloadAction<boolean>
    ) {
      state.isHiderVisible = action.payload;
    },
  },
});
