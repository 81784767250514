import React from 'react';
import { IonCard } from '@ionic/react';
import Page from '../../components/Page/Page';

const FavouritesPage: React.FC = () => {
  return (
    <Page headerProps={{ title: 'Saved', hideBackButton: true }}>
      <IonCard style={{ margin: '10px' }}>
        <p>Saved services will appear here.</p>
      </IonCard>
    </Page>
  );
};

export default FavouritesPage;
