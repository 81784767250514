import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { globalSlice } from './slices/global/slice';
import { ToastPriorityEnum } from '../utils/enums';

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  next =>
  action => {
    if (isRejectedWithValue(action)) {
      console.log(action);

      if (action.payload.status === 'FETCH_ERROR') {
        dispatch(
          globalSlice.actions.setToast({
            message: 'Server unavailable.',
            color: 'danger',
            priority: ToastPriorityEnum.HIGH,
          })
        );
      } else {
        console.error('[Redux RTK Query]', action);

        const errorText = action?.payload?.data?.detail || 'Error has occured.';

        dispatch(
          globalSlice.actions.setToast({
            message: errorText,
            color: 'danger',
            priority: ToastPriorityEnum.HIGH,
            duration: 5000,
          })
        );
      }
    }

    return next(action);
  };
