import { useLocation } from 'react-router';
import { useTypedDispatch } from './redux/hooks';
import { authActionCreators } from './redux/slices/auth/actionCreators';
import LoadingPage from './pages/LoadingPage';
import Page from './components/Page/Page';

const AuthenticationRoutes: React.FC = () => {
  const dispatch = useTypedDispatch();
  const location = useLocation();

  const email = new URLSearchParams(location.search).get('email')!;

  if (location.pathname === '/auth/email-confirmation-process/') {
    const emailVerificationKey = new URLSearchParams(location.search).get(
      'key'
    )!;
    dispatch(
      authActionCreators.updateExtraData({ email, emailVerificationKey })
    );
    dispatch(
      authActionCreators.changeAuthPhase('emailVerificationProcessInBackground')
    );
    return <LoadingPage />;
  } else if (location.pathname === '/auth/password-reset-process/') {
    const passwordResetUid = new URLSearchParams(location.search).get('uid')!;
    const passwordResetToken = new URLSearchParams(location.search).get(
      'token'
    )!;
    dispatch(
      authActionCreators.updateExtraData({
        email,
        passwordResetUid,
        passwordResetToken,
      })
    );
    dispatch(authActionCreators.changeAuthPhase('passwordResetConfirm_Page'));
    return <LoadingPage />;
  } else if (location.pathname === '/auth/apple-signin-process/') {
    return (
      <Page>
        <p>ERROR: Unexpected redirect from Apple</p>
      </Page>
    );
  } else if (location.pathname === '/auth/google-signin-process/') {
    return (
      <Page>
        <p>ERROR: Unexpected redirect from Google</p>
      </Page>
    );
  }

  return null;
};

export default AuthenticationRoutes;
