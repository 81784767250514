import ButtonHorizontal from '../../../components/Buttons/ButtonHorizontal/ButtonHorizontal';
import SubjectChange from '../../../components/SubjectChange/SubjectChange';
import React from 'react';
import Page from '../../../components/Page/Page';
import { Link } from 'react-router-dom';
import { isPlatform } from '@ionic/core';

const BusinessSettingsPage: React.FC = () => {
  return (
    <Page headerProps={{ title: 'Settings', hideBackButton: true }}>
      <SubjectChange />

      <ButtonHorizontal
        text="Business Information"
        routerLink="settings/account-data"
      />

      {!isPlatform('capacitor') && (
        <Link to={{ pathname: '/', state: { allowVisitLandning: true } }}>
          <ButtonHorizontal text="About Eventmaker" />
        </Link>
      )}

      <ButtonHorizontal text="Contact Support" routerLink="settings/support" />
    </Page>
  );
};

export default React.memo(BusinessSettingsPage);
