import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import s from './Switcher.module.scss';
import ButtonWithUnderline from '../../Buttons/ButtonWithUnderline/ButtonWithUnderline';
import { useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';

interface SwitcherProps {
  activeButton: 'SignUp' | 'SignIn';
}

const Switcher: React.FC<SwitcherProps> = props => {
  const dispatch = useTypedDispatch();

  return (
    <div
      className={clsx(
        sg.Stack_Horizontal,
        sg.Gap_10,
        sg.Justify_Center,
        s.Switcher__container
      )}
    >
      <ButtonWithUnderline
        text="Registration"
        isActive={props.activeButton === 'SignUp'}
        onClick={() =>
          dispatch(authActionCreators.changeAuthPhase('signUp_Page'))
        }
      />
      <ButtonWithUnderline
        text="Login"
        isActive={props.activeButton === 'SignIn'}
        onClick={() =>
          dispatch(authActionCreators.changeAuthPhase('signIn_Page'))
        }
      />
    </div>
  );
};

export default Switcher;
