import { useCallback, useEffect, useRef, useState } from 'react';
import { IS_SAFARI_DESKTOP } from '../../utils/browser';

export function useSmoothScroll(deps: any[]) {
  const listOuterRef = useRef<HTMLDivElement>(null);
  const scrollBy = useCallback((direction: 'left' | 'right') => {
    const width = (listOuterRef.current?.clientWidth || 0) - 50;
    listOuterRef.current?.scrollBy({
      left: direction === 'left' ? -width : direction === 'right' ? width : 0,
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const [isLeftEdge, setIsLeftEdge] = useState(true);
  const [isRightEdge, setIsRightEdge] = useState(true);

  const trackEdges = useCallback((updateState?: boolean | Event) => {
    const _isLeftEdge = listOuterRef.current!.scrollLeft <= 0;
    const _isRightEdge =
      listOuterRef.current!.scrollWidth -
        (listOuterRef.current!.scrollLeft +
          listOuterRef.current!.clientWidth) <=
      0;

    if (updateState) {
      setIsLeftEdge(_isLeftEdge);
      setIsRightEdge(_isRightEdge);
    }

    return { _isLeftEdge, _isRightEdge };
  }, []);

  useEffect(() => {
    const unlockGlobalWheel = (e: WheelEvent) => {
      if (!IS_SAFARI_DESKTOP) return;

      const isHorizontalScroll = Math.abs(e.deltaX) > Math.abs(e.deltaY);

      const isLeftScroll = isHorizontalScroll && e.deltaX < 0;
      const isRightScroll = isHorizontalScroll && e.deltaX > 0;

      const { _isLeftEdge, _isRightEdge } = trackEdges();

      /* 
        Scroll event will be prevented by global wheel locker
        which was setup on "SafariSwipeBlocker" level
        */
      if (isRightScroll && _isRightEdge) {
        // console.log('- locked right -');
        return;
      } else if (isLeftScroll && _isLeftEdge) {
        // console.log('- locked left -');
        return;
      }

      /*
        Disable global wheel lock by cancel preventDefault()
        method invocation on "SafariSwipeBlocker" level
        */
      e.stopPropagation();
    };

    if (!listOuterRef.current) return;

    trackEdges(true);

    listOuterRef.current?.addEventListener('scroll', trackEdges);
    listOuterRef.current?.addEventListener('wheel', unlockGlobalWheel);

    return () => {
      listOuterRef.current?.removeEventListener('scroll', trackEdges);
      listOuterRef.current?.removeEventListener('wheel', unlockGlobalWheel);
    };
  }, [trackEdges, ...deps]);

  return {
    listOuterRef,
    scrollBy,
    isLeftEdge,
    isRightEdge,
    trackEdges,
  };
}
