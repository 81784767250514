import React from 'react';
import Page from '../components/Page/Page';
import { IonCard } from '@ionic/react';

type Props = {
  isBusiness?: boolean;
};

const NotificationsPage: React.FC<Props> = ({ isBusiness }) => {
  return (
    <Page headerProps={{ title: 'Notifications', hideBackButton: true }}>
      <IonCard style={{ margin: '10px' }}>
        <p>No new notifications.</p>
      </IonCard>
    </Page>
  );
};

export default NotificationsPage;
