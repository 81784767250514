import React from 'react';
import clsx from 'clsx';
import s from './BusinessRegisteredModal.module.scss';
import Centralizer from '../Centralizer/Centralizer';
import { IonModal, useIonRouter } from '@ionic/react';
import Button from '../Buttons/Button/Button';
import { IS_INSTAGRAM } from '../../utils/browser';
import { getStorageUrl } from '../../pages/landing/Utils/getStorageUrl';

type Props = {
  close: () => void;
  businessData:
    | {
        id: string;
        name: string;
      }
    | undefined;
};

export const BusinessRegisteredModal: React.FC<Props> = ({
  close,
  businessData,
}) => {
  const router = useIonRouter();
  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      backdropDismiss={false}
      keepContentsMounted
      handle={false}
      isOpen={!!businessData}
      onWillDismiss={close}
      className={clsx(s.IonModal)}
    >
      <p className={clsx(s.Text__Header)}>Successfully Registered 🥳</p>
      <p className={clsx(s.Text__Content)}>
        You would be redirected to the <u>Business Dashboard</u>. {`\n`} To
        navigate into the <u>User Dashboard</u>, just click the "Switch" button
        on the "Settings" page.
      </p>

      <Centralizer>
        <img
          src={getStorageUrl('app/switch-example.gif')}
          style={{
            width: '400px',
            border: 'solid 1px var(--color-navigation-elements)',
            borderRadius: '10px',
            margin: '20px 0',
          }}
          alt=""
        />
      </Centralizer>

      <p className={clsx(s.Text__Content)}>
        User dashboard could be helpful if you want to see how your products and
        services looks for clients. You also can explore offerings from other
        vendors there.
      </p>

      <div style={{ margin: '15px 0' }}>
        <Button
          text="Ok"
          color="secondary"
          maxWidth="200px"
          fill="solid"
          onClick={() => {
            close();
            router.push(`/b/${businessData!.id}/products`);
          }}
        />
      </div>
    </IonModal>
  );
};
