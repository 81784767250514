import { Business } from '../../models/business';
import clsx from 'clsx';
import sg from '../../styles/global.module.scss';
import { buildUrlsFromUploadedFile } from '../Inputs/InputFile/oneFile/helpers';
import StarRatings from 'react-star-ratings';
import { useMemo } from 'react';
import SubjectMini from '../SubjectMini/SubjectMini';
import s from './BusinessCard.module.scss';
import { useContext } from 'react';
import { NavContext } from '@ionic/react';
import ButtonLabel from '../Buttons/ButtonLabel/ButtonLabel';
import { chevronForwardOutline } from 'ionicons/icons';

export interface BusinessCardProps {
  business: Business;
  description?: string | null;
  rating?: number;
  reviewsCount?: number;
  softDisabled?: boolean;
  hideBusinessInfo?: boolean;
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  business,
  description,
  rating,
  reviewsCount,
  softDisabled,
  hideBusinessInfo,
}) => {
  const { goBack } = useContext(NavContext);

  const avatarUrls = useMemo(() => {
    return buildUrlsFromUploadedFile(business.uploadedFiles[0]);
  }, [business.uploadedFiles]);

  return (
    <SubjectMini
      suffixIcon={
        !softDisabled ? (
          <ButtonLabel
            label="DETAILS"
            skipBorder
            iconSrc={chevronForwardOutline}
          />
        ) : null
      }
      softDisabled={softDisabled}
      title={business.name}
      description={
        rating ? (
          <div className={clsx(sg.Stack_Horizontal, sg.Gap_10)}>
            <StarRatings
              rating={rating}
              starRatedColor="var(--color-main)"
              starEmptyColor="var(--color-main-low)"
              starDimension="20px"
              starSpacing="1px"
            />
            <p className={clsx(s.Text__review)}>
              {rating ? reviewsCount : 0} reviews
            </p>
          </div>
        ) : (
          description
        )
      }
      image={avatarUrls.content.low}
      isActive={true}
      routerLink={`/u/catalog/business/${business.id}`}
      onClick={e => {
        if (hideBusinessInfo) {
          goBack(`business/${business.id}`);
          e.preventDefault();
        }
      }}
    />
  );
};

export default BusinessCard;
