import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import PasswordResetConfirmForm, {
  PasswordResetConfirmFormValues,
} from './PasswordResetConfirmForm';

const PasswordResetConfirm: React.FC = () => {
  const dispatch = useTypedDispatch();
  const extraData = useTypedSelector(s => s.auth.extraData)!;

  const email = extraData.email!;

  const submitCallback = async function (
    values: PasswordResetConfirmFormValues
  ) {
    dispatch(
      authActionCreators.passwordResetConfirmationProcess({
        password1: values.password1!,
        password2: values.password2!,
      })
    );
  };

  return (
    <div className={clsx(sg.Stack_Vertical, sg.Gap_20)}>
      <p>
        Create a new strong password for the user with the email address:{' '}
        <i>{`<${email}>`}</i>.
      </p>
      <PasswordResetConfirmForm email={email} submitCallback={submitCallback} />
    </div>
  );
};

export default PasswordResetConfirm;
