import { useState } from 'react';
import s from '../../../components/Auth/SignUp/SignUp.module.scss';
import Page from '../../../components/Page/Page';
import { UserAccountForm } from '../../../components/UserAccount/UserAccountForm';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import Centralizer from '../../../components/Centralizer/Centralizer';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';

export const UserAccountSettingsPage: React.FC = () => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(s => s.auth.user);

  const isStoreLoading = useTypedSelector(s => s.auth.loading);

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState(false);

  const close = () => setIsConfirmationModalOpened(false);

  const onConfirm = async () => {
    await dispatch(authActionCreators.deleteSelfAccount());
    close();
  };

  return (
    <Page disableDefaultIonContent headerProps={{ title: 'Manage account' }}>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        close={close}
        onConfirm={onConfirm}
        contentText={`If you continue, we will delete your account. This action is permanent and cannot be undone.`}
        isLoading={isStoreLoading}
      />

      <UserAccountForm initialFormValues={{ email: user?.email || '' }} />

      <Centralizer>
        <button
          className={s.Agreement__button}
          onClick={() => setIsConfirmationModalOpened(true)}
        >
          <p
            className={s.Agreement__link}
            style={{ fontSize: '16px', opacity: 0.7 }}
          >
            Delete My Account
          </p>
        </button>
      </Centralizer>
    </Page>
  );
};
