import { createAnimation } from '@ionic/react';

const hideToolbar = () => {
  const formSubmitToolbar = document.getElementById('myFormToolbar');
  if (formSubmitToolbar) {
    formSubmitToolbar.style.display = 'none';
  }
};

export const animationSlide = (baseEl: any, opts: any) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo('opacity', 1, 1);

  const leavingAnimation = createAnimation().addElement(opts.leavingEl);

  if (opts.direction === 'back') hideToolbar();

  if (opts.direction === 'back') {
    enteringAnimation.fromTo('transform', 'translateX(-100%)', 'translateX(0)');
    leavingAnimation.fromTo('transform', 'translateX(0)', 'translateX(100%)');
  } else {
    enteringAnimation.fromTo('transform', 'translateX(100%)', 'translateX(0)');
    leavingAnimation.fromTo('transform', 'translateX(0)', 'translateX(-100%)');
  }

  const animation = createAnimation()
    .addAnimation(leavingAnimation)
    .addAnimation(enteringAnimation)
    .duration(400);

  return animation;
};

/* Could be used ONLY for IonModal entering */
export const animationCenter = (baseEl: any, opts: any) => {
  const root = baseEl.shadowRoot;

  const backdropAnimation = createAnimation()
    .addElement(root.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  console.log('ANIMATION');

  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'scale(0)' },
      { offset: 1, opacity: '1', transform: 'scale(1)' },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(3000)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

// export const animationCenterLeaving = (baseEl: any, opts: any) =>
//   animationCenter(baseEl, opts).direction("reverse");

/* Could be used for fade in effect */
export const animationOpacity = (baseEl: any, opts: any) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .beforeStyles({ opacity: 1 });

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .beforeStyles({ opacity: 1 });

  if (opts.direction === 'back') hideToolbar();

  enteringAnimation.fromTo('opacity', 0, 1).duration(100).delay(200);
  leavingAnimation.fromTo('opacity', 1, 0).duration(200);

  const animation = createAnimation()
    .addAnimation(leavingAnimation)
    .addAnimation(enteringAnimation);

  return animation;
};
