import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import s from './SearchForm.module.scss';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonModal,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import SearchForm from './SearchForm';
import { SearchFormValues, SearchTypeEnum } from '../SearchBlock/interface';
import { closeOutline } from 'ionicons/icons';
import { useTypedSelector } from '../../redux/hooks';
import Centralizer from '../Centralizer/Centralizer';
import { SearchArgsType } from '../../redux/_types';
import { EventCategory } from '../../models/event';
import { ProductCategory } from '../../models/product';
import { pick } from 'lodash';
import { IS_INSTAGRAM } from '../../utils/browser';

interface SearchModalFormProps {
  selectedGenericCategory: EventCategory | ProductCategory | undefined;
  closeModal: () => void;
  searchType: SearchTypeEnum;
  initialSearchArgs: SearchArgsType;
  setSearchArgs: React.Dispatch<React.SetStateAction<SearchArgsType>>;
  fixUiOnSearch: () => Promise<void>;
}

const SearchModalForm: React.FC<SearchModalFormProps> = ({
  selectedGenericCategory,
  closeModal,
  searchType,
  initialSearchArgs,
  setSearchArgs,
  fixUiOnSearch,
}) => {
  const eventCategories = useTypedSelector(s => s.choices.eventCategorySet);
  const productCategories = useTypedSelector(s => s.choices.productCategorySet);

  const { selectedProductCategory, selectedEventCategory } = useMemo(
    () => ({
      selectedProductCategory: productCategories.find(
        c => c.id === selectedGenericCategory?.id
      ),
      selectedEventCategory: eventCategories.find(
        c => c.id === selectedGenericCategory?.id
      ),
    }),
    [productCategories, eventCategories, selectedGenericCategory]
  );

  const [savedFormValues, setSavedFormValues] = useState<SearchFormValues>(
    pick(initialSearchArgs as SearchFormValues, [
      'sortBy',
      'peopleCapacity',
      'eventDuration',
      'productCategories',
    ])
  );

  const submitCallback = useCallback(
    async (values: SearchFormValues) => {
      const productCategories = selectedProductCategory
        ? {
            [selectedProductCategory.id]:
              values.productCategories[selectedProductCategory.id],
          }
        : values.productCategories;

      await fixUiOnSearch();

      switch (searchType) {
        case SearchTypeEnum.BY_EVENT_CATEGORY: {
          if (!selectedEventCategory) {
            throw new Error('Event category should be selected');
          }

          setSearchArgs(oldSearchArgs => ({
            type: SearchTypeEnum.BY_EVENT_CATEGORY,
            eventCategory: selectedEventCategory,
            ...values,
            productCategories,
            city: oldSearchArgs.city,
          }));
          break;
        }
        case SearchTypeEnum.BY_PRODUCT_CATEGORY: {
          if (!selectedProductCategory) {
            throw new Error('Product category should be selected');
          }

          setSearchArgs(oldSearchArgs => ({
            type: SearchTypeEnum.BY_PRODUCT_CATEGORY,
            productCategory: selectedProductCategory,
            ...values,
            productCategories,
            city: oldSearchArgs.city,
          }));
          break;
        }
        default: {
          throw new Error('Unhandled case.');
        }
      }
      setSavedFormValues(values);
      closeModal();
    },
    [
      fixUiOnSearch,
      searchType,
      selectedProductCategory,
      selectedEventCategory,
      closeModal,
      setSearchArgs,
    ]
  );

  /* This workaround helps to scroll inside 80% opened modal */
  const scrollableAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!!selectedGenericCategory) {
      setTimeout(() => {
        scrollableAreaRef &&
          scrollableAreaRef.current &&
          (scrollableAreaRef.current.ontouchmove = e => {
            e.stopPropagation();
          });
      }, 0);
    }
  }, [selectedGenericCategory, scrollableAreaRef]);

  const modalRef = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      ref={modalRef}
      keepContentsMounted
      handle={false}
      isOpen={!!selectedGenericCategory}
      initialBreakpoint={isPlatform('desktop') ? undefined : 0.8}
      breakpoints={[0, 0.8]}
      onWillDismiss={() => closeModal()}
      className={clsx(
        s.IonModal,
        isPlatform('desktop') ? s.IonModal__desktop : s.IonModal__mobile
      )}
    >
      <IonToolbar mode="ios" className={clsx(s.IonToolbar)}>
        <IonButtons slot="end">
          <IonButton
            onClick={() => closeModal()}
            className="disableIosSafariSwipeBlocker"
          >
            <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
          </IonButton>
        </IonButtons>

        <Centralizer>
          <p className={clsx(s.Text__header)}>
            {selectedProductCategory?.name || selectedEventCategory?.name}
          </p>
        </Centralizer>
      </IonToolbar>

      {!!selectedGenericCategory && (
        <SearchForm
          submitCallback={submitCallback}
          savedFormValues={savedFormValues}
          selectedEventCategory={selectedEventCategory}
          selectedProductCategory={selectedProductCategory}
        />
      )}
    </IonModal>
  );
};

export default SearchModalForm;
