import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Business } from "../../../models/business";
import { Organizer } from "../../../models/organizer";

export enum SubjectsStorageKeys {
  BUSINESS_SET = "subjects_business_set",
  ORGANIZER_SET = "subjects_organizer_set",
  ACTIVE_SUBJECT = "subjects_active_subject",
}

export type SubjectContentTypeType = "user" | "business" | "organizer";

export interface SubjectGeneric {
  contentType: SubjectContentTypeType;
  objectId: string | undefined;
}

export interface SubjectsStoreState {
  businessSet: Business[] | null;
  organizerSet: Organizer[] | null;
  activeSubject: SubjectGeneric | null;
  loading: boolean;
}

const initiaSubjectsState: SubjectsStoreState = {
  businessSet: localStorage.getItem(SubjectsStorageKeys.BUSINESS_SET)
    ? JSON.parse(localStorage.getItem(SubjectsStorageKeys.BUSINESS_SET)!)
    : null,
  organizerSet: localStorage.getItem(SubjectsStorageKeys.ORGANIZER_SET)
    ? JSON.parse(localStorage.getItem(SubjectsStorageKeys.ORGANIZER_SET)!)
    : null,
  activeSubject: localStorage.getItem(SubjectsStorageKeys.ACTIVE_SUBJECT)
    ? JSON.parse(localStorage.getItem(SubjectsStorageKeys.ACTIVE_SUBJECT)!)
    : null,
  loading: false,
};

export const subjectsSlice = createSlice({
  name: "subjects",
  initialState: initiaSubjectsState,
  reducers: {
    setBusinessSet(
      state: SubjectsStoreState,
      action: PayloadAction<Business[] | null>
    ) {
      if (
        JSON.stringify(state.businessSet) === JSON.stringify(action.payload)
      ) {
        return;
      }

      state.businessSet = action.payload;
      action.payload
        ? localStorage.setItem(
            SubjectsStorageKeys.BUSINESS_SET,
            JSON.stringify(action.payload)
          )
        : localStorage.removeItem(SubjectsStorageKeys.BUSINESS_SET);
    },
    setOrganizerSet(
      state: SubjectsStoreState,
      action: PayloadAction<Organizer[] | null>
    ) {
      if (
        JSON.stringify(state.organizerSet) === JSON.stringify(action.payload)
      ) {
        return;
      }

      state.organizerSet = action.payload;
      action.payload
        ? localStorage.setItem(
            SubjectsStorageKeys.ORGANIZER_SET,
            JSON.stringify(action.payload)
          )
        : localStorage.removeItem(SubjectsStorageKeys.ORGANIZER_SET);
    },
    setActiveSubject(
      state: SubjectsStoreState,
      action: PayloadAction<SubjectGeneric | null>
    ) {
      if (
        JSON.stringify(state.activeSubject) === JSON.stringify(action.payload)
      ) {
        return;
      }

      state.activeSubject = action.payload;
      action.payload
        ? localStorage.setItem(
            SubjectsStorageKeys.ACTIVE_SUBJECT,
            JSON.stringify(action.payload)
          )
        : localStorage.removeItem(SubjectsStorageKeys.ACTIVE_SUBJECT);
    },
    setLoading(state: SubjectsStoreState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});
