import clsx from 'clsx';
import sg from '../../styles/global.module.scss';
import s from './SubjectMini.module.scss';
import Avatar, { AvatarSize, AvatarType } from '../Avatar/Avatar';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import ButtonHorizontal from '../Buttons/ButtonHorizontal/ButtonHorizontal';

interface SubjectMiniProps {
  image: any;
  title: string;
  description: React.ReactNode | string;
  isActive: boolean;
  prefixIcon?: boolean;
  suffixIcon?: React.ReactNode;
  routerLink?: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  addTwoHr?: boolean;
  softDisabled?: boolean;
}

const SubjectMini: React.FC<SubjectMiniProps> = props => {
  return (
    <ButtonHorizontal
      hideTopHr={!props.addTwoHr}
      addBottomHr={props.addTwoHr}
      routerLink={props.routerLink}
      softDisabled={props.softDisabled}
      onClick={props.onClick}
    >
      <div className={clsx(sg.Stack_Horizontal, s.Container)}>
        {props.prefixIcon && (
          <IonIcon
            src={chevronForwardOutline}
            className={clsx(
              s.IonIcon_Arrow,
              !props.isActive ? s.IonIcon_Arrow__invisible : undefined
            )}
          />
        )}

        <Avatar
          type={AvatarType.CIRCLE}
          size={AvatarSize.SMALL}
          image={props.image}
          alt={
            props.title +
            ' ' +
            (typeof props.description === 'string' ? props.description : '')
          }
        />

        <div className={clsx(sg.Stack_Vertical, sg.Gap_10, s.Text__container)}>
          <p>{props.title}</p>
          {typeof props.description === 'string' ? (
            <p className={clsx(s.Text__description)}>{props.description}</p>
          ) : (
            props.description
          )}
        </div>

        {props.suffixIcon}
      </div>
    </ButtonHorizontal>
  );
};

export default SubjectMini;
