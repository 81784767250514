import { useForm } from 'react-hook-form';
import Form from '../../../../../../components/Form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationErrors } from '../../../../../../utils/validators';
import * as Yup from 'yup';
import { useSumbitFormCallback } from '../../../../../../components/Form/submit.hook';
import Input from '../../../../../../components/Inputs/Input/Input';
import InputArea from '../../../../../../components/Inputs/InputArea/InputArea';
import { OrganizeEventFormValues } from './OrganizeEventForm';
import { TextUsButton } from './TextUsButton';
import clsx from 'clsx';
import s from '../Form.module.scss';

export type FindProductsFormValues = Omit<OrganizeEventFormValues, 'type'>;

const validationOrdered = {
  date: Yup.string().required(validationErrors.REQUIRED),
  text: Yup.string().required(validationErrors.REQUIRED),
};

const validationSchema: Yup.SchemaOf<Partial<FindProductsFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

type Props = {
  initialFormValues: Partial<FindProductsFormValues>;
  submitCallback: (values: FindProductsFormValues) => Promise<void>;
  applyCustomStyles: boolean;
};

export const FindProductsForm: React.FC<Props> = ({
  initialFormValues,
  submitCallback,
  applyCustomStyles,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitted, isSubmitSuccessful, errors },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const { onSubmit } = useSumbitFormCallback<OrganizeEventFormValues>({
    submitCallback,
    handleSubmit,
    reset,
    trigger,
    setValue,
    isSubmitted,
  });

  return (
    <Form onSubmit={onSubmit} hidePrompt>
      <Input
        label="Date"
        type="text"
        inputmode="text"
        labelClassName={applyCustomStyles ? s.IonInputLabel__custom : ''}
        className={applyCustomStyles ? s.IonInput__custom : ''}
        className__active={applyCustomStyles ? s.IonInput__custom__active : ''}
        _name={'date' satisfies FieldNameType}
        _register={register}
        _errorText={errors.date?.message}
      />

      <InputArea
        label="What are you looking for?"
        labelClassName={
          applyCustomStyles
            ? clsx(s.IonInputLabel__custom, s.IonInputLabel__custom__margin)
            : ''
        }
        className={clsx(applyCustomStyles ? s.IonTextarea__custom : undefined)}
        className__active={
          applyCustomStyles ? s.IonTextarea__custom__active : ''
        }
        minRows={5}
        _name={'text' satisfies FieldNameType}
        _register={register}
        _errorText={errors.text?.message}
      />

      <TextUsButton applyCustomStyles={applyCustomStyles} />
    </Form>
  );
};
