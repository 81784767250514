import React, { useEffect } from 'react';
import {
  IS_SAFARI_DESKTOP,
  IS_SAFARI_MOBILE,
  IS_SAFARI_NEW,
} from '../../utils/browser';
import { useTypedDispatch } from '../../redux/hooks';
import { applyBlockerEventListener } from './hook.swipe-blocker';

const SafariSwipeBlocker: React.FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (IS_SAFARI_DESKTOP) {
      /*
      Prevent history navigation on wheel
      Related with "unlockGlobalWheel" for horizontal scrolls
      */
      document.addEventListener(
        'wheel',
        e => {
          const isHorizontalScroll = Math.abs(e.deltaX) > Math.abs(e.deltaY);
          if (e.deltaX !== 0 && isHorizontalScroll) {
            e.preventDefault();
          }
        },
        // New safari (> 16) supports "overscroll-behavior-x: contain;"
        !IS_SAFARI_NEW ? { passive: false } : undefined
      );
    }

    if (IS_SAFARI_MOBILE) {
      /* 
      Prevent history navigation when swipe from the edge of screen 
      Related with "disableIosSafariSwipeBlocker" className for some buttons
      */
      const htmlCollection = document.getElementsByTagName('body');
      const body = htmlCollection[0];
      applyBlockerEventListener(body);

      /* 
      Prevent zoom on double tap 
      */
      // function detectDoubleTapClosure() {
      //   let lastTap = 0;
      //   let timeout: NodeJS.Timeout;
      //   const DIFF = 250; // TODO: Improve. Do not make more than 300, it will make site not responsive

      //   return function detectDoubleTap(event: TouchEvent) {
      //     const curTime = new Date().getTime();
      //     const tapLen = curTime - lastTap;
      //     if (tapLen < DIFF && tapLen > 0) {
      //       event.preventDefault();
      //     } else {
      //       timeout = setTimeout(() => {
      //         clearTimeout(timeout);
      //       }, DIFF);
      //     }
      //     lastTap = curTime;
      //   };
      // }

      // document.body.addEventListener('touchend', detectDoubleTapClosure(), {
      //   passive: false,
      // });

      /* 
      Prevent zoom on double tap 
      */
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
      });

      /* 
      Prevent zoom on gesture zoom 
      */
      document.addEventListener(
        'touchmove',
        (event: any) => {
          event = event.originalEvent || event;
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }
  }, [dispatch]);

  return null;
};

export default SafariSwipeBlocker;
