import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './BuyBlock.module.scss';
import sg from '../../styles/global.module.scss';
import { IonIcon } from '@ionic/react';
import Button from '../Buttons/Button/Button';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { cartOutline, heartOutline, mailOutline } from 'ionicons/icons';
import Centralizer from '../Centralizer/Centralizer';
import { NullableNumber } from '../../utils/types';
import {
  CurrenciesEnum,
  CURRENCIES_CONFIG,
  PriceTypeEnum,
} from '../../models/product';
import {
  ContactInfoType,
  useContactInfoContext,
} from '../ContactInfoModal/context';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

const getPriceString = (
  from: NullableNumber,
  to: NullableNumber,
  currency: CurrenciesEnum,
  isPreview: boolean
) => {
  if (!from && !to) return ['', '-'];

  const symbol = CURRENCIES_CONFIG[currency];

  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    notation: isPreview && currency === 'IDR' ? 'compact' : 'standard',
    maximumFractionDigits: 1,
    trailingZeroDisplay: 'stripIfInteger',
  } as any);

  const priceFrom = from
    ? formatter.format(Number(from)).replace(currency, symbol).trim()
    : null;

  const priceTo = to
    ? formatter.format(Number(to)).replace(currency, symbol).trim()
    : null;

  let firstLine = '';
  let secondLine = '';

  if (priceFrom && priceTo) {
    firstLine = priceFrom + ' -';
    secondLine = priceTo;
  } else if (priceFrom) {
    firstLine = 'from';
    secondLine = priceFrom;
  } else if (priceTo) {
    firstLine = 'up to';
    secondLine = priceTo;
  }

  return [firstLine, secondLine];
};

export type BuyBlockProps = {
  priceFrom: NullableNumber;
  priceTo: NullableNumber;
  priceCurrency: CurrenciesEnum;
  priceType: PriceTypeEnum | null;
  isPreview: boolean;
  contactInfoContextData: ContactInfoType;
};

const BuyBlock: React.FC<BuyBlockProps> = ({
  priceFrom,
  priceTo,
  priceCurrency,
  isPreview,
  priceType,
  contactInfoContextData,
}) => {
  const priceLines = useMemo(
    () => getPriceString(priceFrom, priceTo, priceCurrency, isPreview),
    [priceFrom, priceTo, priceCurrency, isPreview]
  );

  const noPrice = !priceFrom && !priceTo;

  const { setInfo } = useContactInfoContext();
  const openContactModal = () => setInfo(contactInfoContextData);

  return (
    <div
      className={clsx(
        s.BuyBlock__container,
        !isPreview ? s.BuyBlock__container__ProductDetailPage : ''
      )}
    >
      <Centralizer enableVerticalCentralization>
        <div
          className={clsx(sg.Stack_Horizontal, sg.Gap_5)}
          style={{ width: '100%' }}
        >
          <div
            className={clsx(
              sg.Stack_Horizontal,
              s.Price_Wrapper,
              isPreview ? s.Price_Wrapper__preview : ''
            )}
          >
            {noPrice ? (
              <p
                style={{
                  fontFamily: 'GillSans',
                  fontSize: 16,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                No price info
              </p>
            ) : (
              <>
                <div>
                  <p className={clsx(s.Text_Price)}>{priceLines[0]}</p>
                  <p className={clsx(s.Text_Price)}>{priceLines[1]}</p>
                </div>

                <div className={clsx(sg.Stack_Horizontal)}>
                  <div
                    style={{
                      borderLeft: 'solid 1.2px white',
                      height: '30px',
                      marginLeft: '7px',
                      marginRight: '5px',
                      transform: 'rotate(10deg)',
                    }}
                  />

                  <div>
                    <p className={clsx(s.Text__payment)}>per</p>
                    <p className={clsx(s.Text__payment)}>
                      {priceType === PriceTypeEnum.PER_DAY
                        ? 'event'
                        : priceType === PriceTypeEnum.PER_HOUR
                        ? 'hour'
                        : '?'}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Like  */}
          {/* <ButtonMicroWrapper
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              openContactModal();
            }}
          >
            <IonIcon icon={heartOutline} className={clsx(s.IonIcon)} />
          </ButtonMicroWrapper> */}

          <div style={{ height: '45px' }} />

          {!isPreview && (
            <ButtonMicroWrapper
              onClick={e => {
                trackEvent(AnalyticEventsEnum.CLICK_MESSAGE_ICON);
                e.preventDefault();
                e.stopPropagation();
                openContactModal();
              }}
              className={clsx(s.IonIcon__wrapper__message)}
            >
              <IonIcon icon={mailOutline} className={clsx(s.IonIcon)} />
            </ButtonMicroWrapper>
          )}

          {/* {isPreview && (
            <ButtonMicroWrapper
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                openContactModal();
              }}
            >
              <IonIcon icon={cartOutline} className={clsx(s.IonIcon)} />
            </ButtonMicroWrapper>
          )} */}

          {!isPreview && (
            <Button
              width="90%"
              text="Book now"
              color="secondary"
              fill="solid"
              onClick={e => {
                trackEvent(AnalyticEventsEnum.INITIATE_CHEKOUT);
                e.preventDefault();
                e.stopPropagation();
                openContactModal();
              }}
            />
          )}
        </div>
      </Centralizer>
    </div>
  );
};

export default BuyBlock;
