import clsx from 'clsx';
import s from './InputCurrency.module.scss';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import InputYAnchor from '../InputYAnchor/InputYAnchor';
import CurrencyInput from 'react-currency-input-field';
import { CURRENCIES_CONFIG, CurrenciesEnum } from '../../../models/product';

interface InputCurrencyProps {
  label?: string;
  marginBottom?: number;
  priceCurrency?: CurrenciesEnum;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _setValue: UseFormSetValue<any>;
  _control: Control<any, any>;
  _errorText?: string;
}

const InputCurrency: React.FC<InputCurrencyProps> = props => {
  const [active, setActive] = useState(false);

  const _registered = props._register(props._name);

  const _initialValue = useWatch({
    control: props._control,
    name: props._name,
    disabled: true,
  });

  const [value, setValue] = useState<string | undefined>(_initialValue);

  useEffect(() => {
    props._setValue(props._name, value || null, {
      shouldDirty: true,
    });
  }, [value]);

  const symbol = props.priceCurrency
    ? CURRENCIES_CONFIG[props.priceCurrency]
    : '-';

  return (
    <InputWrapper
      label={props.label}
      errorText={props._errorText}
      active={active}
      style={{ marginBottom: `${props.marginBottom}px` }}
    >
      <CurrencyInput
        prefix={`${symbol} `}
        decimalSeparator="."
        inputMode="decimal"
        className={clsx(
          s.InputCurrency,
          active ? s.InputCurrency_active : undefined
        )}
        groupSeparator=" "
        disableAbbreviations
        decimalScale={2}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        /* react-use-form */
        value={value}
        onValueChange={value => setValue(value)}
        onKeyDown={e => {
          // * For IOS safari its necessary for some locales
          // workaround for allow "," as decimal separator
          if (e.key === ',' && !value?.includes('.')) {
            setValue(value + '.');
          }
        }}
      />

      <InputYAnchor refCallback={_registered.ref} />
    </InputWrapper>
  );
};

export default InputCurrency;
