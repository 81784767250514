import { useForm } from 'react-hook-form';
import Form from '../Form/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../Inputs/Input/Input';
import { User } from '../../models/user';

export type UserFormValues = Pick<User, 'email'>;

type Props = {
  initialFormValues: Partial<UserFormValues>;
};

const validation = {
  email: Yup.string(),
};

const validationSchema: Yup.SchemaOf<Partial<UserFormValues>> =
  Yup.object().shape(validation);

type FieldNameType = keyof typeof validation;

export const UserAccountForm: React.FC<Props> = ({ initialFormValues }) => {
  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  return (
    <Form onSubmit={() => {}} hidePrompt>
      <div style={{ opacity: 0.7 }}>
        <Input
          disabled
          type="text"
          inputmode="text"
          label="Email (not editable)"
          maxlength={50}
          _name={'email' satisfies FieldNameType}
          _register={register}
          _errorText={errors.email?.message}
        />
      </div>
    </Form>
  );
};
