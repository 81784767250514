import React, { useContext } from 'react';
import FetchMoreCommon from './FetchMoreCommon';
import { FetchMoreStatusContext } from './context';

const FetchMoreByGrid: React.FC = () => {
  const { status, executeFetchMore } = useContext(FetchMoreStatusContext);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <FetchMoreCommon
        status={status}
        onFetchMoreClick={() => executeFetchMore()}
      />
    </div>
  );
};

export default FetchMoreByGrid;
