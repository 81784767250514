import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

// If not one page was rendered
// Return 404 error
// It is workaround because Ionic has bug with fallbacks
//
// BUG REPORT 1: https://github.com/ionic-team/ionic-framework/issues/24855
// BUG REPORT 2: https://github.com/ionic-team/ionic-framework/issues/23507
// COULD BE USEFUL: https://github.com/ionic-team/ionic-framework/issues/18687
//
const AppDefaultFallback: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      const myPages = document.getElementsByClassName('myIonPage');
      if (!myPages.length) {
        history.push('/404');
      }
    }, 0);
  }, []);

  return null;
};

export default AppDefaultFallback;
