import React from 'react';
import Page from '../components/Page/Page';
import { IonCard } from '@ionic/react';
import { PhoneNumber } from '../components/ContactInfoModal/PhoneNumber';

const BusinessFaqPage: React.FC = () => {
  return (
    <Page headerProps={{ title: 'FAQ', hideBackButton: true }}>
      <IonCard style={{ margin: '10px' }}>
        <h3 style={{ marginBottom: '10px' }}>What is eventmaker?</h3>
        <p>
          It is a platform where event organizers find the perfect products and
          services for their events.
        </p>
      </IonCard>

      <IonCard style={{ margin: '10px' }}>
        <h3 style={{ marginBottom: '10px' }}>How much does it costs?</h3>
        <p>
          You can add your offerings to the platform for free. When a client
          books any of your services through our platform, we charge a 10% fee
          based on the total bill.
        </p>
      </IonCard>

      <IonCard style={{ margin: '10px' }}>
        <h3 style={{ marginBottom: '10px' }}>Hot to get clients?</h3>
        <p>
          Just list your offerings, and we will take care of everything rest.
        </p>
      </IonCard>

      <IonCard style={{ margin: '10px' }}>
        <h3 style={{ marginBottom: '10px' }}>Have question or feedback?</h3>
        <p style={{ marginBottom: '10px' }}>
          Drop a message to our Whats App number. We are open to communication
          and partnership:
        </p>

        <PhoneNumber />
      </IonCard>
    </Page>
  );
};

export default BusinessFaqPage;
