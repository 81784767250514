import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../models/user';

export enum AuthStorageKeys {
  TOKEN = 'auth_token',
  USER = 'auth_user',
  LOGIN_REDIRECT_URL = 'login_redirect_url',
}

export type AuthPhaseType =
  | 'authenticated'
  | 'notAuthenticated'
  | 'signUp_Page'
  | 'signIn_Page'
  | 'loginCompleted' // <- hard redirect
  | 'emailVerificationRequire_Page'
  | 'emailVerificationProcessInBackground'
  | 'passwordReset_Page'
  | 'passwordResetConfirmRequired_Page'
  | 'passwordResetConfirm_Page'
  | 'logoutCompleted'; // <- hard reload

export interface ExtraData {
  email?: string /* inputed by user or received in link */;
  password?: string /* inputed by user */;
  emailIsVerified?: boolean;
  emailVerificationKey?: string;
  passwordResetUid?: string;
  passwordResetToken?: string;
  isAnonymusModeGoogleError?: boolean;
}

export interface AuthStoreState {
  _token: string | null;
  user: User | null;
  loading: boolean;
  authPhase: AuthPhaseType;
  extraData: ExtraData | null;
  loginRedirectUrl: string | null;
  selectedLanguage: 'en' | 'ru';
}

const initialAuthState: AuthStoreState = {
  _token: localStorage.getItem(AuthStorageKeys.TOKEN),
  user: localStorage.getItem(AuthStorageKeys.USER)
    ? JSON.parse(localStorage.getItem(AuthStorageKeys.USER)!)
    : null,
  extraData: null,
  loading: false,
  authPhase:
    localStorage.getItem(AuthStorageKeys.TOKEN) &&
    localStorage.getItem(AuthStorageKeys.USER)
      ? 'authenticated'
      : 'notAuthenticated',
  loginRedirectUrl: localStorage.getItem(AuthStorageKeys.LOGIN_REDIRECT_URL),
  selectedLanguage: 'en',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setToken(state: AuthStoreState, action: PayloadAction<string | null>) {
      state._token = action.payload;
      action.payload
        ? localStorage.setItem(AuthStorageKeys.TOKEN, action.payload)
        : localStorage.removeItem(AuthStorageKeys.TOKEN);
    },
    setUser(state: AuthStoreState, action: PayloadAction<User | null>) {
      if (JSON.stringify(state.user) === JSON.stringify(action.payload)) {
        return;
      }

      state.user = action.payload;
      action.payload
        ? localStorage.setItem(
            AuthStorageKeys.USER,
            JSON.stringify(action.payload)
          )
        : localStorage.removeItem(AuthStorageKeys.USER);
    },
    setExtraData(
      state: AuthStoreState,
      action: PayloadAction<ExtraData | null>
    ) {
      if (JSON.stringify(state.extraData) === JSON.stringify(action.payload)) {
        return;
      }

      state.extraData = action.payload;
    },
    setAuthPhase(state: AuthStoreState, action: PayloadAction<AuthPhaseType>) {
      state.authPhase = action.payload;
    },
    setLoading(state: AuthStoreState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoginRedirectUrl(
      state: AuthStoreState,
      action: PayloadAction<string | null>
    ) {
      state.loginRedirectUrl = action.payload;
      action.payload
        ? localStorage.setItem(
            AuthStorageKeys.LOGIN_REDIRECT_URL,
            action.payload
          )
        : localStorage.removeItem(AuthStorageKeys.LOGIN_REDIRECT_URL);
    },
  },
});
