import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Input from '../../Inputs/Input/Input';
import Button from '../../Buttons/Button/Button';
import Form from '../../Form/Form';
import { useTypedSelector } from '../../../redux/hooks';
import { validationErrors } from '../../../utils/validators';

export interface PasswordResetFormValues {
  email?: string;
  children?: React.ReactNode;
}

interface PasswordResetFormProps extends PasswordResetFormValues {
  submitCallback: (values: PasswordResetFormValues) => void;
}

let validationSchema = Yup.object({
  email: Yup.string()
    .email(validationErrors.EMAIL)
    .required(validationErrors.REQUIRED)
    .nullable(),
});

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  children,
  submitCallback,
  ...initialFormValues
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const loading = useTypedSelector(s => s.auth.loading);

  return (
    <Form loading={loading} onSubmit={handleSubmit(submitCallback)}>
      {/* ------- Email ------ */}
      <Input
        applyWorkaround
        type="email"
        inputmode="email"
        maxlength={50}
        label="Email"
        autocomplete="email"
        _name="email"
        _errorText={errors.email?.message}
        _register={register}
      />

      {/* ------- Submit ------ */}
      <Button
        text="Continue"
        type="submit"
        color="secondary"
        fill="solid"
        loading={loading}
      />
    </Form>
  );
};

export default PasswordResetForm;
