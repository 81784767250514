import clsx from 'clsx';
import s from './PasswordResetConfirmRequired.module.scss';

const PasswordResetConfirmRequired: React.FC = () => {
  return (
    <p className={clsx(s.P__container)}>
      If the email address you provided is correct, you will receive an email
      with a link to reset your password.
    </p>
  );
};

export default PasswordResetConfirmRequired;
