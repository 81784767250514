export enum AnchorEnum {
  ABOUT = 'ABOUT',
  REVIEWS = 'REVIEWS',
  HOW_WORKS = 'HOW_WORKS',
  FORM_REQUEST = 'FORM_REQUEST',
  FAQ = 'FAQ',
}

export const buildAnchorId = (v: AnchorEnum) => `navigation_anchor_${v}`;

export type Item = {
  name: string;
  anchor?: AnchorEnum;
  pageUrl?: string;
};

export const processAnchorClick = (anchor: AnchorEnum, behavior?: 'auto') => {
  const el = document.getElementById(buildAnchorId(anchor));
  el?.scrollIntoView({
    behavior: behavior || 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

export const processItemClick = (item: Item, behavior?: 'auto') => {
  if (item.pageUrl) {
    window.location.href = `${item.pageUrl}?allowVisitLanding=1#${AnchorEnum.ABOUT}`;
  }

  if (item.anchor) {
    processAnchorClick(item.anchor, behavior);
  }
};

const configCustomer: Item[] = [
  {
    name: 'About',
    anchor: AnchorEnum.ABOUT,
  },
  {
    name: 'Reviews',
    anchor: AnchorEnum.REVIEWS,
  },
  {
    name: 'Assistance',
    anchor: AnchorEnum.FORM_REQUEST,
  },
  {
    name: 'FAQ',
    anchor: AnchorEnum.FAQ,
  },
  {
    name: 'For businesses',
    pageUrl: '/business',
  },
];

const configBusiness: Item[] = [
  {
    name: 'About',
    anchor: AnchorEnum.ABOUT,
  },
  {
    name: 'How it works',
    anchor: AnchorEnum.HOW_WORKS,
  },
  {
    name: 'Transfer your catalog',
    anchor: AnchorEnum.FORM_REQUEST,
  },
  {
    name: 'FAQ',
    anchor: AnchorEnum.FAQ,
  },
  {
    name: 'For clients',
    pageUrl: '/',
  },
];

export const getHeaderConfig = ({
  source,
}: {
  source: 'customer' | 'business';
}) => (source === 'customer' ? configCustomer : configBusiness);
