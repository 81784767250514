import React from 'react';
import { Route, useRouteMatch } from 'react-router';
import { IonRouterOutlet } from '@ionic/react';
import BusinessSettingsPage from './BusinessSettingsPage';
import BusinessAccountDataPage from './BusinessAccountDataPage';
import { animationSlide } from '../../../utils/animation';
import SupportPage from '../../SupportPage';
import FaqPage from '../../BusinessFaqPage';

const BusinessSettingsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path={`${match.path}/account-data`}>
        <BusinessAccountDataPage />
      </Route>

      <Route exact path={`${match.path}/support`}>
        <SupportPage />
      </Route>

      <Route exact path={`${match.path}/faq`}>
        <FaqPage />
      </Route>

      <Route exact path={match.path}>
        <BusinessSettingsPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default BusinessSettingsRoutes;
