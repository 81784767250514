import { Redirect, Route, Switch } from 'react-router-dom';
import {
  createAnimation,
  IonApp,
  isPlatform,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* <<< Don't move styles >>> */
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import './styles/index.scss';

import AppBusiness from './apps/AppBusiness';
import AppUser from './apps/AppUser';
import AuthenticationRoutes from './AuthenticationRoutes';
import AuthWrapper from './components/Auth/AuthWrapper';
import AppDefaultRedirect from './AppDefaultRedirect';
import AppDefaultFallback from './AppDefaultFallback';
import Alert from './components/Alert/Alert';
import Toast from './components/Toast/Toast';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { useTypedDispatch, useTypedSelector } from './redux/hooks';
import { useEffect, useRef } from 'react';
import { authActionCreators } from './redux/slices/auth/actionCreators';
import Page404 from './pages/NotFoundPage';
import GlobalHider from './components/GlobalHider/GlobalHider';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import SafariSwipeBlocker from './components/SafariSwipeBlocker/SafariSwipeBlocker';
import PrivacyPage from './pages/Privacy';
import AgreementPage from './pages/Agreement';
import LandingPageCustomer from './pages/landing/LandingPageCustomer';
import LandingPageBusiness from './pages/landing/LandingPageBusiness';
import SupportPage from './pages/SupportPage';
import About from './pages/About';
import { socket } from './socketio';
import InstagramRedirectRoutes from './pages/landing/InstagramRedirectRoutes';
import { AppMinRequiredVersionModal } from './AppMinRequiredVersionModal';

setupIonicReact({
  navAnimation: (_, __) => createAnimation(),
  swipeBackEnabled: false,
});

// Update when do some changes in backend categories
const LAST_CHANGES_IN_CHOICES =
  'Fri May 10 2024 14:54:00 GMT+0800 (Central Indonesia Time)';

const App: React.FC = () => {
  const dispatch = useTypedDispatch();
  const token = useTypedSelector(s => s.auth._token);
  const timestamp = useTypedSelector(s => s.choices.recievedAt);
  const publicDataReceivedAt =
    timestamp && new Date(timestamp) > new Date(LAST_CHANGES_IN_CHOICES)
      ? timestamp
      : null;

  const isPublicDataFetched = useRef<boolean>();

  useEffect(() => {
    if (isPlatform('mobile')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      StatusBar.setStyle({ style: Style.Dark });
    }
  }, []);

  /* PRIVATE DATA */
  useEffect(() => {
    setTimeout(() => {
      dispatch(authActionCreators.checkConsistencyOfLocalStorageData());
    }, 10000);

    if (token) {
      console.log('::: FETCHING PRIVATE DATA :::');
      dispatch(authActionCreators.fetchAllPrivateData({ nextAuthPhase: null }));

      // This will increase allocation time for Cloud Run service which leads to costs increase
      if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
        socket.createAuthorizedConnection(token);
      }
    }
  }, [token, dispatch]);

  /* PUBLIC DATA */
  useEffect(() => {
    const isFetchingRequired = (publicDataReceivedAt: string | null) => {
      if (!publicDataReceivedAt) {
        return true;
      }

      const timeDifference =
        new Date().getTime() - new Date(publicDataReceivedAt).getTime();

      return timeDifference > 1000 * 60 * 60 * 24 * 1;
    };

    if (
      isFetchingRequired(publicDataReceivedAt) &&
      !isPublicDataFetched.current
    ) {
      console.log('::: FETCHING PUBLIC DATA :::');
      dispatch(authActionCreators.fetchAllPublicData());
      isPublicDataFetched.current = true;
    }
  }, [publicDataReceivedAt, dispatch]);

  // https://github.com/petyosi/react-virtuoso/issues/1043
  useEffect(() => {
    window.addEventListener('error', e => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <IonApp>
      <SafariSwipeBlocker />
      <GlobalHider />
      <Alert />
      <Toast />
      <AppMinRequiredVersionModal />
      <IonReactRouter>
        <AuthWrapper>
          <AppDefaultFallback />
          <Switch>
            <Route
              exact
              path="/default"
              render={() => <AppDefaultRedirect />}
            />

            <Route
              exact
              path="/"
              render={data => {
                const allowVisitLandingAfterRedirect =
                  new URLSearchParams(data.location.search).get(
                    'allowVisitLanding'
                  ) || (data.location.state as any)?.allowVisitLandning;

                if (
                  (!token || allowVisitLandingAfterRedirect) &&
                  !isPlatform('capacitor')
                ) {
                  return <LandingPageCustomer />;
                }

                return <AppDefaultRedirect />;
              }}
            />

            <Route path="/business/registration">
              <Redirect to="/u/settings/new-business?flow=1" />
            </Route>

            <Route path="/business">
              <LandingPageBusiness />
            </Route>

            <Route path="/inst">
              <InstagramRedirectRoutes />
            </Route>

            <Route exact path="/404" render={() => <Page404 />} />
            <Route path="/auth" render={() => <AuthenticationRoutes />} />

            <Route path="/about" render={() => <About />} />
            <Route path="/support" render={() => <SupportPage />} />
            <Route path="/privacy" render={() => <PrivacyPage />} />
            <Route path="/terms" render={() => <AgreementPage />} />

            <Route path="/u">
              <AppUser />
            </Route>

            <PrivateRoute enablePermissionLayer path="/b/:businessId">
              <AppBusiness />
            </PrivateRoute>
          </Switch>
        </AuthWrapper>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
