import { convertToOtherCase } from '../../../@shared/helpers/case';
import { Business } from '../../../models/business';
import { ReduxActionCreator } from '../../store';
import { subjectsSlice } from './slice';

const createBusiness: ReduxActionCreator<{ body: any }> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(subjectsSlice.actions.setLoading(true));

      const token = getState().auth._token;
      const businessSet = getState().subjects.businessSet!;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/businesses/business/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(variables.body),
        }
      );

      const res = convertToOtherCase(await response.json(), 'camel');

      if (response.status === 201) {
        const business = res as Business;
        const newBusinessSet = businessSet.slice();
        newBusinessSet.push(business);
        dispatch(subjectsSlice.actions.setBusinessSet(newBusinessSet));
      } else {
        throw new Error(res.detail);
      }
    } catch (err: any) {
      throw err;
    } finally {
      dispatch(subjectsSlice.actions.setLoading(false));
    }
  };
};

const updateBusiness: ReduxActionCreator<{
  businessId: string;
  body: any;
}> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(subjectsSlice.actions.setLoading(true));

      const businessId = variables.businessId;

      const token = getState().auth._token;
      const businessSet = getState().subjects.businessSet!;

      const businessIndexForUpdate = businessSet.findIndex(
        business => business.id === businessId
      );

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/businesses/business/${businessId}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(variables.body),
        }
      );

      const res = convertToOtherCase(await response.json(), 'camel');

      if (response.status === 200) {
        const business = res as Business;
        const newBusinessSet = businessSet.slice();
        newBusinessSet[businessIndexForUpdate] = business;
        dispatch(subjectsSlice.actions.setBusinessSet(newBusinessSet));
      } else {
        throw new Error(res.detail);
      }
    } catch (err) {
      throw err;
    } finally {
      dispatch(subjectsSlice.actions.setLoading(false));
    }
  };
};

export const subjectsActionCreators = {
  createBusiness,
  updateBusiness,
};
