import clsx from 'clsx';
import s from './OneFile.module.scss';
import {
  buildUrlsFromGenericFile,
  getFileCategoryFromGenericFile,
  getImageDimensions,
} from './helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Spinner from '../../../Spinner/Spinner';
import React from 'react';
import Compressor from 'compressorjs';
import { useFileUploader } from './hook.uploader';
import { GenericFilesDispatcherType } from '../dispatcher';
import { useUploadingProcedureState } from './context';
import { useAlert } from '../../../../hooks/hook.alert';
import { GenericFile } from '../interfaces';
import Centralizer from '../../../Centralizer/Centralizer';
import { IonIcon } from '@ionic/react';
import {
  documentOutline,
  playCircleOutline,
  videocamOutline,
} from 'ionicons/icons';
import {
  VALIDATION_CONFIG,
  COMPRESSION_IMAGE_CONFIG,
  COMPRESSED_IMAGES_FILE_KEYS,
  COMPRESSED_IMAGE_MIME_TYPE,
  FileCategoryEnum,
  OneFileKeyEnum,
  StorageDirectoryData,
} from '../../../../@shared/file';
import { IS_IOS_NATIVE, IS_SAFARI } from '../../../../utils/browser';

interface OneFileProps {
  genericFile: GenericFile;
  genericFilesDispatcher: GenericFilesDispatcherType;
  getAlreadyUploadedFilesCount: () => number;
  maxCountOfFiles: number;
  storageDirectoryData: StorageDirectoryData;
  uploadingProcedureBusyBy: string | null;
  uploadingProcedureBusyByRef: React.MutableRefObject<string | null>;
  setUploadingProcedureBusyBy: (v: string | null) => void;
}

const OneFile: React.FC<OneFileProps> = ({
  genericFile,
  genericFilesDispatcher,
  getAlreadyUploadedFilesCount,
  maxCountOfFiles,
  storageDirectoryData,
  uploadingProcedureBusyBy,
  uploadingProcedureBusyByRef,
  setUploadingProcedureBusyBy,
}) => {
  const genericFileId = genericFile.id;
  const sourceFile = genericFile.sourceFile!;
  const isUploadedToBackend = !!genericFile.uploadedFile;
  const fileCategory = getFileCategoryFromGenericFile(genericFile)!;

  const [isPrepared, setIsPrepared] = useState(false);
  const [uploadingProcedureState, uploadingProcedureDispatcher] =
    useUploadingProcedureState();

  const previewSrc = useMemo(
    () => buildUrlsFromGenericFile(genericFile).thumbnail.low,
    [genericFile.uploadedFile, genericFile.sourceImagePreview]
  );

  const compressedImages = useRef<{
    [key in (typeof COMPRESSED_IMAGES_FILE_KEYS)[number]]?: Blob;
  }>({});

  const alertBuilder = useAlert();
  const errorBuilder = useCallback(
    (error: string | Error): void => {
      console.error('ERROR DETAILS:', error);
      uploadingProcedureDispatcher({ action: 'close' });
      alertBuilder({
        message:
          typeof error === 'string'
            ? error
            : 'An error occurred while uploading the file.',
        previewSrc,
        cancelButtonHandler: () => {
          uploadingProcedureBusyByRef.current = null;
          setUploadingProcedureBusyBy(null);

          genericFilesDispatcher({
            action: 'delete',
            delete: { genericFileId },
          });
        },
        /* Timeout is strictly necessary */
        milisecondsBeforeAlert: 300,
      });
    },
    [previewSrc]
  );

  const fileUploader = useFileUploader({
    genericFileId,
    fileCategory,
    storageDirectoryData,
    uploadingProcedureDispatcher,
    genericFilesDispatcher,
  });

  const attachSourceImagePreviewWrapper = useCallback(
    (sourceImagePreview: Blob | null) => {
      if (sourceImagePreview) {
        genericFilesDispatcher({
          action: 'attachSourceImagePreview',
          attachSourceImagePreview: {
            genericFileId,
            sourceImagePreview,
          },
        });
      }
    },
    []
  );

  /* Preparation */
  const thumbnailProcess = useCallback(() => {
    return new Promise<void>(async resolve => {
      if (
        fileCategory === FileCategoryEnum.VIDEO &&
        sourceFile.type !== 'video/quicktime' &&
        !IS_SAFARI
      ) {
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(sourceFile);
        video.muted = true;
        video.playsInline = true;
        video.play();

        video.addEventListener('loadeddata', (): void => {
          const canvas = document.createElement('canvas') as HTMLCanvasElement;

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext('2d');
          context!.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.pause();

          canvas.toBlob(result => {
            attachSourceImagePreviewWrapper(result);
            resolve();
          }, 'image/jpeg');
        });
      } else {
        resolve();
      }
    });
  }, []);

  /* Preparation */
  const convertationProcess = useCallback(async () => {
    if (fileCategory !== FileCategoryEnum.IMAGE) return;

    if (['image/heic', 'image/heif'].includes(sourceFile.type)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const converter = require('heic2any');
      const convertedImage = (await converter({
        blob: new Blob([sourceFile], { type: 'image/heic' }),
        toType: 'image/jpeg',
      })) as Blob;

      attachSourceImagePreviewWrapper(convertedImage);
    } else {
      attachSourceImagePreviewWrapper(sourceFile);
    }
  }, []);

  /* Preparation */
  const validationProcess = useCallback(async () => {
    const maxSize = VALIDATION_CONFIG[fileCategory].maxSize;
    if (sourceFile.size > maxSize) {
      const maxSizeInMb = Math.round(maxSize / (1024 * 1024));
      return errorBuilder(
        `Maximum size ${maxSizeInMb} MB. 
        The uploaded file exceeds this limit.`
      );
    }

    if (getAlreadyUploadedFilesCount() >= maxCountOfFiles) {
      return errorBuilder(
        `It is not possible to attach more than ${maxCountOfFiles} files.
        Delete some files to free up space.`
      );
    }

    const MINIMUM_PIXEL_DIMENSION = 200;
    const MAXIMUM_PIXEL_DIMENSION = 10000;
    const sourceImagePreview = genericFile.sourceImagePreview;
    if (sourceImagePreview) {
      const { height, width } = await getImageDimensions(sourceImagePreview);
      if (height < MINIMUM_PIXEL_DIMENSION || width < MINIMUM_PIXEL_DIMENSION) {
        return errorBuilder(
          `The uploaded media file must be at least
            ${MINIMUM_PIXEL_DIMENSION}x${MINIMUM_PIXEL_DIMENSION} pixels.`
        );
      } else if (
        height > MAXIMUM_PIXEL_DIMENSION ||
        width > MAXIMUM_PIXEL_DIMENSION
      ) {
        return errorBuilder(
          `The uploaded media file must be maximum
            ${MAXIMUM_PIXEL_DIMENSION}x${MAXIMUM_PIXEL_DIMENSION} pixels.`
        );
      }
    }

    return true;
  }, [errorBuilder]);

  /* Preparation */
  const compressingProcess = useCallback(() => {
    const imageForCompression = genericFile.sourceImagePreview;

    return new Promise<string>(async resolve => {
      if (!imageForCompression) {
        resolve('COMPRESSION_NOT_NECESSARY');
        return;
      }

      for (const oneFileKey of COMPRESSED_IMAGES_FILE_KEYS) {
        const opts: {
          maxWidth?: number;
          maxHeight?: number;
          width?: number;
          height?: number;
        } = {};

        if (COMPRESSION_IMAGE_CONFIG[oneFileKey].fit === 'outside') {
          opts.maxWidth = COMPRESSION_IMAGE_CONFIG[oneFileKey].width;
          opts.maxHeight = COMPRESSION_IMAGE_CONFIG[oneFileKey].height;
        } else {
          const { height, width } = await getImageDimensions(
            imageForCompression
          );
          height > width
            ? (opts.width = COMPRESSION_IMAGE_CONFIG[oneFileKey].width)
            : (opts.height = COMPRESSION_IMAGE_CONFIG[oneFileKey].height);
        }

        new Compressor(imageForCompression, {
          quality: COMPRESSION_IMAGE_CONFIG[oneFileKey].quality,
          ...opts,
          mimeType:
            IS_SAFARI || IS_IOS_NATIVE
              ? 'image/jpeg'
              : COMPRESSED_IMAGE_MIME_TYPE,
          success(result) {
            if (result.size > imageForCompression.size) {
              console.warn(
                `${oneFileKey}: Compressed size should not be bigger than original.`
              );
            }

            compressedImages.current[oneFileKey] = result;

            if (
              compressedImages.current[OneFileKeyEnum.CI_HIGH] &&
              compressedImages.current[OneFileKeyEnum.CI_MEDIUM] &&
              compressedImages.current[OneFileKeyEnum.CI_LOW]
            ) {
              resolve('COMPRESSION_FINISHED');
            }
          },
          error() {
            errorBuilder(new Error('Compression error.'));
          },
        });
      }
    });
  }, [errorBuilder, genericFile.sourceImagePreview]);

  /* Uploader */
  const uploadingProcess = useCallback(async () => {
    await fileUploader({
      [OneFileKeyEnum.VIDEO_ORIGINAL]:
        fileCategory === FileCategoryEnum.VIDEO ? sourceFile : undefined,
      [OneFileKeyEnum.DOCUMENT_ORIGINAL]:
        fileCategory === FileCategoryEnum.DOCUMENT ? sourceFile : undefined,
      [OneFileKeyEnum.CI_HIGH]:
        compressedImages.current[OneFileKeyEnum.CI_HIGH],
      [OneFileKeyEnum.CI_MEDIUM]:
        compressedImages.current[OneFileKeyEnum.CI_MEDIUM],
      [OneFileKeyEnum.CI_LOW]: compressedImages.current[OneFileKeyEnum.CI_LOW],
    })
      .catch(error => {
        errorBuilder(error);
      })
      .then(isUploaded => {
        if (isUploaded) {
          uploadingProcedureBusyByRef.current = null;
          setUploadingProcedureBusyBy(null);
        }
      });
  }, [errorBuilder, fileUploader]);

  /* Starter */
  useEffect(() => {
    if (isPrepared || isUploadedToBackend) {
      return;
    }

    genericFilesDispatcher({
      action: 'attachUploadingProcedureDispatcher',
      attachUploadingProcedureDispatcher: {
        genericFileId,
        uploadingProcedureDispatcher,
      },
    });

    uploadingProcedureDispatcher({
      action: 'calculateProgressImpact',
      fileCategory,
    });

    thumbnailProcess().then(() =>
      convertationProcess().then(() => setIsPrepared(true))
    );
  }, [isPrepared, isUploadedToBackend]);

  const isPipelineTriggered = useRef<boolean>();
  useEffect(() => {
    if (isUploadedToBackend || isPipelineTriggered.current) {
      return;
    }

    if (
      isPrepared &&
      !uploadingProcedureBusyBy &&
      !uploadingProcedureBusyByRef.current &&
      !isPipelineTriggered.current
    ) {
      uploadingProcedureBusyByRef.current = genericFileId;
      setUploadingProcedureBusyBy(genericFileId);
      return;
    }

    if (uploadingProcedureBusyBy === genericFileId) {
      isPipelineTriggered.current = true;

      validationProcess().then(isValid => {
        if (isValid) {
          compressingProcess().then(() => {
            uploadingProcess();
          });
        }
      });
    }
  }, [
    isPrepared,
    isUploadedToBackend,
    uploadingProcedureBusyBy,
    uploadingProcedureBusyByRef.current,
    setUploadingProcedureBusyBy,
    isPipelineTriggered.current,
  ]);

  return (
    <>
      {!isUploadedToBackend && (
        <Spinner
          text={`${uploadingProcedureState.progress?.totalPercentage || 0} %`}
        />
      )}

      {isUploadedToBackend && fileCategory === FileCategoryEnum.VIDEO && (
        <Centralizer
          enableVerticalCentralization
          className={clsx(s.Centralizer__position)}
        >
          <IonIcon icon={playCircleOutline} className={clsx(s.IonIcon_Video)} />
        </Centralizer>
      )}

      {previewSrc ? (
        <img
          src={previewSrc}
          alt="media preview"
          className={clsx(
            s.PreviewImg,
            !isUploadedToBackend && s.PreviewImg__additional_opacity
          )}
          onDragStart={e => e.preventDefault()}
        />
      ) : (
        <Centralizer enableVerticalCentralization>
          <IonIcon
            icon={
              fileCategory === FileCategoryEnum.VIDEO
                ? videocamOutline
                : documentOutline
            }
            className={clsx(s.IonIcon_Placeholder)}
          />
        </Centralizer>
      )}
    </>
  );
};

export default OneFile;
