import React from 'react';
import { Route, useRouteMatch } from 'react-router';
import { IonRouterOutlet } from '@ionic/react';
import {
  animationCenter,
  animationOpacity,
  animationSlide,
} from '../../../utils/animation';
import BusinessProductPostPage from './BusinessProductPostPage';
import BusinessProductsPage from './BusinessProductsPage';

const BusinessProductsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <IonRouterOutlet ionPage animation={animationOpacity}>
      <Route exact path={`${match.path}/edit/:productId`}>
        <BusinessProductPostPage action="edit" />
      </Route>

      <Route exact path={`${match.path}/create`}>
        <BusinessProductPostPage action="add" />
      </Route>

      <Route exact path={match.path}>
        <BusinessProductsPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(BusinessProductsRoutes);
