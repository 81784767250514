import React, { useState } from 'react';
import clsx from 'clsx';
import s_Customer from './FAQ_Customer.module.scss';
import s_Business from './FAQ_Business.module.scss';
import { getFAQConfig } from './config';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { ActionButton } from '../../ActionButton/ActionButton';
import Centralizer from '../../../../../components/Centralizer/Centralizer';
import { useLandingPageContext } from '../../../context';

export const FAQ = () => {
  const { source } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const [activeItemId, setActiveItemId] = useState<number>();

  const click = (questionId: number) => {
    if (activeItemId === questionId) {
      setActiveItemId(undefined);
    } else {
      setActiveItemId(questionId);
    }
  };

  const questions = getFAQConfig({ source });

  return (
    <section className={s.section} id={buildAnchorId(AnchorEnum.FAQ)}>
      <div id="FAQ" className={s.container}>
        <h3 data-animation className={s.section__title}>
          FAQ
        </h3>
        <div className={s.FAQ}>
          {questions.map((question, index) => (
            <div
              key={index}
              data-animation
              onClick={() => click(question.id)}
              className={s.FAQ__item}
            >
              <div className={s.FAQ__row}>
                <p className={s.question}>{question.question}</p>
                <img
                  alt=""
                  src={
                    source === 'customer'
                      ? './svg/arrowFAQ_White.svg'
                      : './svg/arrowFAQ_Black.svg'
                  }
                  className={clsx(
                    s.FAQ__arrow,
                    activeItemId === question.id && s.arrow_active
                  )}
                />
              </div>
              {activeItemId === question.id && (
                <p className={s.answer}>{question.answer}</p>
              )}
            </div>
          ))}
        </div>

        <Centralizer>
          <ActionButton animation />
        </Centralizer>
      </div>
    </section>
  );
};
