import clsx from 'clsx';
import s from './InputFile.module.scss';
import sg from '../../../styles/global.module.scss';
import { IonButton, IonIcon } from '@ionic/react';
import React, {
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  addOutline,
  closeCircle,
  closeCircleOutline,
  closeCircleSharp,
} from 'ionicons/icons';
import MediaGallery from '../../MediaGallery/MediaGallery';
import InputWrapper from '../InputWrapper/InputWrapper';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { useMedia } from '../../../hooks/hook.media';
import OneFile from './oneFile/OneFile';
import { UseFormRegister } from 'react-hook-form';
import { genericFilesReducerWrapper } from './dispatcher';
import { UploadingProcedureStateProvider } from './oneFile/context';
import { useAlert } from '../../../hooks/hook.alert';
import { Icons } from '../../../icons';
import { useTypedDispatch } from '../../../redux/hooks';
import { globalActionCreators } from '../../../redux/slices/global/actionCreator';
import InputYAnchor from '../InputYAnchor/InputYAnchor';
import { transformToGenericFiles } from './oneFile/helpers';
import {
  StorageDirectoryData,
  FileCategoryEnum,
  VALIDATION_CONFIG,
  UploadedFile,
} from '../../../@shared/file';
import ButtonMicroWrapper from '../../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';

interface InputFileProps {
  allowedFileCategories: FileCategoryEnum[];
  label: string;
  maxCountOfFiles: number;
  storageDirectoryData: StorageDirectoryData;
  fullWidth?: boolean;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
  /* "uploadedFiles" input field */
  __updateUploadedFilesInForm: (uploadedFiles: (UploadedFile | null)[]) => void;
  __initialValue: UploadedFile[];
}

const InputFile: React.FC<InputFileProps> = props => {
  const [initialSlide, setInitialSlide] = useState<number | undefined>();
  const alertBuilder = useAlert();

  const _registered = props._register(props._name);

  const [genericFiles, genericFilesDispatcher] = useReducer(
    genericFilesReducerWrapper(props.__updateUploadedFilesInForm),
    transformToGenericFiles(props.__initialValue)
  );

  /* 
  Allowed mime types restriction
  */
  const allowedMimeTypes = useMemo(() => {
    return props.allowedFileCategories
      .map(allowedFileCategory =>
        Object.keys(VALIDATION_CONFIG[allowedFileCategory].mimeTypes).join(', ')
      )
      .join(', ');
  }, [props.allowedFileCategories]);

  /* 
  React grid dnd
  */
  const rowLength = useMedia(
    ['(min-width: 700px)', '(min-width: 400px)', '(min-width: 200px)'],
    [5, 4, 3],
    2
  );

  const filesTotal = genericFiles.length;
  const filesInOneRow = !props.fullWidth ? rowLength : 1;

  const countOfColumns =
    Math.floor(filesTotal / filesInOneRow) +
    (filesTotal % filesInOneRow == 0 ? 0 : 1);

  const holdedWidth =
    filesInOneRow === 1
      ? 110
      : window.innerWidth <= 500
      ? window.innerWidth
      : 500;

  const squareSize = Math.round(holdedWidth / filesInOneRow);

  const gridZoneHeight = Math.round(countOfColumns * squareSize);
  const onReorder = useCallback(
    (_sourceId: string, sourceIndex: number, targetIndex: number) => {
      const nextState = swap(genericFiles, sourceIndex, targetIndex);
      genericFilesDispatcher({ action: 'rewrite', nextState });
    },
    [genericFiles]
  );

  const [isDragging, setIsDragging] = useState(false);

  /* Uploading Queue */
  const uploadingProcedureBusyByRef = useRef<string | null>(null);
  const [uploadingProcedureBusyBy, setUploadingProcedureBusyBy] = useState<
    string | null
  >(null);

  /* Count validation */
  const getAlreadyUploadedFilesCount = useCallback(
    () => genericFiles.filter(gf => !!gf.uploadedFile).length,
    [genericFiles]
  );

  /* Blocking page */
  const dispatch = useTypedDispatch();

  return (
    <>
      {/* ---- Media gallery---- */}
      <MediaGallery
        genericFiles={genericFiles}
        initialSlide={initialSlide}
        setInitialSlide={setInitialSlide}
      />

      <InputWrapper label={props.label} errorText={props._errorText}>
        <GridContextProvider onChange={onReorder}>
          <GridDropZone
            id="items"
            boxesPerRow={filesInOneRow}
            rowHeight={squareSize}
            className={clsx(s.GridDropZone)}
            style={{ height: `${gridZoneHeight}px` }}
            disableDrag={!isDragging}
          >
            {genericFiles.map((genericFile, index) => (
              <GridItem
                key={genericFile.id}
                className={clsx(s.GridItem)}
                onClick={() => {
                  if (!isDragging) setInitialSlide(index);
                }}
                style={{ cursor: isDragging ? 'auto' : 'default' }}
              >
                <div
                  className={clsx(
                    s.Thumbnail,
                    isDragging
                      ? s.Thumbnail__MovingAnimation
                      : s.Thumbnail__NoAnimation
                  )}
                >
                  {!isDragging && (
                    <ButtonMicroWrapper
                      resizeAnimation
                      className={clsx(
                        'disableIosSafariSwipeBlocker',
                        s.ButtonWrapper__delete
                      )}
                      onClick={e => {
                        e.stopPropagation();

                        // if (uploadingProcedureBusyBy === genericFile.id) {
                        //   return alertBuilder({
                        //     message:
                        //       'Невозможно удалить файл так как он находится в процессе обработки.',
                        //     cancelButtonText: 'Ок',
                        //   });
                        // }

                        return alertBuilder({
                          header: null,
                          message:
                            'Are you sure you want to delete the selected file?',
                          confirmButtonText: 'Delete',
                          confirmButtonVisible: true,
                          confirmButtonHandler: () => {
                            genericFilesDispatcher({
                              action: 'delete',
                              delete: {
                                genericFileId: genericFile.id,
                              },
                            });
                          },
                          cancelButtonText: 'Cancel',
                        });
                      }}
                    >
                      <IonIcon icon={closeCircle} />
                    </ButtonMicroWrapper>
                  )}

                  <UploadingProcedureStateProvider>
                    <OneFile
                      key={genericFile.id}
                      genericFile={genericFile}
                      getAlreadyUploadedFilesCount={
                        getAlreadyUploadedFilesCount
                      }
                      maxCountOfFiles={props.maxCountOfFiles}
                      storageDirectoryData={props.storageDirectoryData}
                      genericFilesDispatcher={genericFilesDispatcher}
                      uploadingProcedureBusyBy={uploadingProcedureBusyBy}
                      uploadingProcedureBusyByRef={uploadingProcedureBusyByRef}
                      setUploadingProcedureBusyBy={setUploadingProcedureBusyBy}
                    />
                  </UploadingProcedureStateProvider>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>

        {/* ---- Upoload Button (hided) ---- */}
        <input
          id={`genericFilesInput-${props._name}`}
          multiple={props.maxCountOfFiles > 1}
          type="file"
          accept={allowedMimeTypes}
          style={{ display: 'none' }}
          onChange={e => {
            const newFiles = [...((e.target.files || []) as File[])];
            e.target.value = '';
            genericFilesDispatcher({ action: 'add', newFiles });
          }}
        />

        {/* ---- Buttons ---- */}
        <div className={clsx(sg.Stack_Horizontal)}>
          {genericFiles.length < props.maxCountOfFiles && (
            <IonButton
              mode="md"
              className={clsx(
                s.Button,
                s.Button__border,
                'disableIosSafariSwipeBlocker'
              )}
              fill="outline"
              style={{
                width: `${squareSize}px`,
              }}
            >
              <label
                htmlFor={`genericFilesInput-${props._name}`}
                className={clsx(s.Label, 'disableIosSafariSwipeBlocker')}
              >
                <IonIcon
                  icon={addOutline}
                  className={clsx(s.IonIcon__add_button)}
                />
              </label>
            </IonButton>
          )}

          {(genericFiles.length > 1 || isDragging) && (
            <IonButton
              mode="md"
              className={clsx(
                s.Button,
                s.Button__border,
                s.Button__forward_plan
              )}
              fill={isDragging ? 'solid' : 'clear'}
              color="secondary"
              onClick={() => {
                setIsDragging(!isDragging);
                dispatch(globalActionCreators.changeScreenLock());
              }}
              style={{
                width: `${squareSize}px`,
              }}
            >
              <>
                {isDragging ? (
                  <p className={clsx(s.Text_Reorder)}>Save</p>
                ) : (
                  <IonIcon
                    icon={Icons.swap}
                    className={clsx(s.IonIcon__reorder_button)}
                  />
                )}
              </>
            </IonButton>
          )}
        </div>
      </InputWrapper>

      <InputYAnchor refCallback={_registered.ref} />
    </>
  );
};

export default InputFile;
