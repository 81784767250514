import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import { useEffect, useState } from 'react';
import { useTypedSelector, useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import Button from '../../Buttons/Button/Button';

const EmailVerificationRequired: React.FC = () => {
  const dispatch = useTypedDispatch();
  const loading = useTypedSelector(s => s.auth.loading);
  const extraData = useTypedSelector(s => s.auth.extraData)!;

  const [clickedButton, setClickedButton] = useState<'ready' | 'resend'>();

  const TIMER_SEC = 30;
  const lastEmailWasSendAt = new Date().getTime();
  const secondsUntilNextEmailCouldBeSent =
    TIMER_SEC - Math.round((new Date().getTime() - lastEmailWasSendAt) / 1000);
  const [secondsLeft, setSecondsLeft] = useState(
    secondsUntilNextEmailCouldBeSent > 0 ? secondsUntilNextEmailCouldBeSent : 0
  );

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }
      if (secondsLeft === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const resendCallback = async function () {
    setClickedButton('resend');
    await dispatch(authActionCreators.emailVerificationResend());
    setSecondsLeft(TIMER_SEC);
  };

  const readyCallback = async function () {
    setClickedButton('ready');
    await dispatch(authActionCreators.loginBySavedExtraData());
  };

  let buttonText: string;
  let buttonDisabled: boolean;
  if (extraData.emailIsVerified) {
    buttonText = `Email already confirmed`;
    buttonDisabled = true;
  } else if (secondsLeft !== 0) {
    buttonText = `Send again (${secondsLeft})`;
    buttonDisabled = true;
  } else {
    buttonText = `Send again`;
    buttonDisabled = false;
  }

  return (
    <div className={clsx(sg.Stack_Vertical, sg.Gap_10)}>
      <p>
        Please confirm the email address: <i>{extraData.email}</i>
      </p>
      <p>
        An email with instructions has been sent to your inbox. It may have
        landed in the spam folder.
      </p>

      <div className={clsx(sg.Stack_Vertical)}>
        <Button
          text="Ready!"
          fill="solid"
          color="secondary"
          onClick={readyCallback}
          loading={clickedButton === 'ready' && loading}
          maxWidth="200px"
        />
        <Button
          text={buttonText}
          color="primary"
          onClick={resendCallback}
          loading={clickedButton === 'resend' && loading}
          maxWidth="200px"
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default EmailVerificationRequired;
