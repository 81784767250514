import React, { useEffect, useRef, useState } from 'react';
import s from './EventTypes.module.scss';
import clsx from 'clsx';
import { ActionButton } from '../../ActionButton/ActionButton';
import { eventTypes } from './config';
import useOnScreen from '../../../../../hooks/hook.observe';
import { useLandingPageContext } from '../../../context';
import Centralizer from '../../../../../components/Centralizer/Centralizer';

export const EventTypes = () => {
  const [activeItem, setActiveItem] = useState(0);

  const ref = useRef<HTMLUListElement>(null);

  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (isOnScreen) {
      setInterval(() => {
        setActiveItem(item => {
          if (item === eventTypes.length - 1) {
            return 0;
          } else {
            return item + 1;
          }
        });
      }, 3000);
    }
  }, [isOnScreen]);

  const text = '- ' + eventTypes[activeItem].services.join('\n- ');

  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section>
      <div className={s.section}>
        <div className={s.section__left}>
          <h2 data-animation>
            <span>Bali vendors</span> have a lot of options for{' '}
            <span>your event</span>
          </h2>
          <ul data-animation ref={ref}>
            {eventTypes.map((item, index) => (
              <li
                key={index}
                className={clsx(index === activeItem && s.activeItem)}
                onMouseEnter={() => setActiveItem(index)}
              >
                <span />
                {item.text}
              </li>
            ))}
          </ul>
          <span className={s.animation} data-animation>
            <ActionButton />
          </span>
        </div>
        {/* <img
          data-animation
          className={s.img}
          src={`${eventTypes[activeItem].src}`}
          alt=""
        /> */}
        <div
          className={s.slideImageContainer}
          style={{
            backgroundImage: `url(${eventTypes[activeItem].src})`,
            width: '500px',
            height: '500px',
          }}
        >
          <Centralizer enableVerticalCentralization>
            <p className={s.slideOverlappedText} data-text={text}>
              {text}
            </p>
          </Centralizer>
        </div>
      </div>
    </section>
  );
};
