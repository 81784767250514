import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import s from './ButtonHorizontal.module.scss';
import { IonButton } from '@ionic/react';
import { isPlatform } from '@ionic/core';

interface ButtonHorizontalProps {
  text?: string;
  children?: React.ReactNode;
  // loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  routerLink?: string;
  softDisabled?: boolean;
  hideTopHr?: boolean;
  addBottomHr?: boolean;
}

export const ButtonHorizontal: React.FC<ButtonHorizontalProps> = props => {
  return (
    <IonButton
      expand="full"
      fill="outline"
      color="primary"
      disabled={props.softDisabled}
      className={clsx(
        s.Button_Horizontal,
        isPlatform('desktop')
          ? s.Button_Horizontal__desktop
          : s.Button_Horizontal__mobile
      )}
      routerLink={props.routerLink}
      onClick={props.onClick}
    >
      <div className={clsx(sg.Stack_Vertical, s.Button_Content__container)}>
        {!props.hideTopHr && <hr />}
        {props.text ? <p>{props.text}</p> : props.children}
        {props.addBottomHr && <hr />}
      </div>
    </IonButton>
  );
};

export default ButtonHorizontal;
