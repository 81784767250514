import { v4 as uuidv4 } from 'uuid';
import { UploadedFile } from '../../../@shared/file/_types';
import { UploadingProcedureDispatcherType } from './oneFile/dispatcher';
import { GenericFile } from './interfaces';

type ActionType =
  | 'add'
  | 'rewrite'
  | 'delete'
  | 'attachSourceImagePreview'
  | 'attachUploadingProcedureDispatcher'
  | 'attachUploadedFile';

type Payload = {
  action: ActionType;

  newFiles?: File[];
  nextState?: GenericFile[];
  delete?: {
    genericFileId: string;
  };
  attachSourceImagePreview?: {
    genericFileId: string;
    sourceImagePreview: Blob;
  };
  attachUploadingProcedureDispatcher?: {
    genericFileId: string;
    uploadingProcedureDispatcher: UploadingProcedureDispatcherType;
  };
  attachUploadedFile?: {
    genericFileId: string;
    uploadedFile: UploadedFile;
  };
};

export const genericFilesReducerWrapper = (
  updateUploadedFilesInForm: (uploadedFiles: (UploadedFile | null)[]) => void
) => {
  const genericFilesReducer = (
    state: GenericFile[],
    payload: Payload
  ): GenericFile[] => {
    switch (payload.action) {
      case 'add':
        const newGenericFiles: GenericFile[] = [];
        for (const file of payload.newFiles!) {
          const genericFile: GenericFile = {
            id: uuidv4(),
            sourceFile: file,
            uploadedFile: undefined,
          };
          newGenericFiles.push(genericFile);
        }
        return state.concat(newGenericFiles);

      case 'rewrite':
        if (!payload.nextState) {
          throw new Error('Next state is required if you do reorder.');
        }
        updateUploadedFilesInForm(
          payload.nextState.map(gf => gf.uploadedFile || null)
        );
        return payload.nextState;

      case 'delete': {
        const index = state.findIndex(elem => {
          return elem.id === payload['delete']!.genericFileId;
        });
        if (index === -1) return state;

        const { uploadingProcedureDispatcher } = state[index];
        if (uploadingProcedureDispatcher) {
          setTimeout(() => {
            uploadingProcedureDispatcher({
              action: 'close',
            });
          }, 0);
        }

        state.splice(index, 1);

        updateUploadedFilesInForm(state.map(gf => gf.uploadedFile || null));
        return [...state];
      }

      case 'attachSourceImagePreview': {
        const index = state.findIndex(elem => {
          return elem.id === payload['attachSourceImagePreview']!.genericFileId;
        });
        if (index === -1) return state;

        state[index].sourceImagePreview =
          payload['attachSourceImagePreview']!.sourceImagePreview;
        return [...state];
      }

      case 'attachUploadingProcedureDispatcher':
        const index = state.findIndex(elem => {
          return (
            elem.id ===
            payload['attachUploadingProcedureDispatcher']!.genericFileId
          );
        });
        if (index === -1) return state;

        state[index].uploadingProcedureDispatcher =
          payload[
            'attachUploadingProcedureDispatcher'
          ]!.uploadingProcedureDispatcher;

        updateUploadedFilesInForm(state.map(gf => gf.uploadedFile || null));
        return [...state];

      case 'attachUploadedFile': {
        const index = state.findIndex(elem => {
          return elem.id === payload['attachUploadedFile']!.genericFileId;
        });
        if (index === -1) return state;

        // warning fix
        setTimeout(() => {
          state[index].uploadingProcedureDispatcher!({ action: 'close' });
        }, 0);
        // warning fix

        state[index].uploadedFile = payload['attachUploadedFile']!.uploadedFile;

        updateUploadedFilesInForm(state.map(gf => gf.uploadedFile || null));
        return [...state];
      }

      default:
        throw Error('Unexpected error.');
    }
  };

  return genericFilesReducer;
};

export type GenericFilesDispatcherType = React.Dispatch<Payload>;
