import React from 'react';

/* Prevent text selection on swipe */
const UnvisibleText: React.FC = () => {
  return (
    <p style={{ fontSize: '0px' }}>
      <span data-nosnippet>.</span>
    </p>
  );
};

export default UnvisibleText;
