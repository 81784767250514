import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

/*
Return dimensions of ref object.
Hook working dynamically: values are auto-updated on change width or height of ref.
*/
export const useContainerDimensions = (
  myRef: React.MutableRefObject<HTMLDivElement | null>,
  movementColdTime = 500
) => {
  const [dimensions, setDimensions] = useState({ refWidth: 0, refHeight: 0 });
  const movementTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleResize = useCallback(
    () =>
      setTimeout(() => {
        setDimensions({
          refWidth: myRef.current ? myRef.current.offsetWidth : 0,
          refHeight: myRef.current ? myRef.current.offsetHeight : 0,
        });
      }, 100),
    [myRef]
  );

  /* Initial call */
  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  // workaround
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (!dimensions.refWidth && counter < 10) {
      setTimeout(() => {
        setCounter(c => c + 1);
        handleResize();
      }, 1000);
    }
  }, [dimensions, handleResize, counter, setCounter]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      /* Set dimensions after timeout for memory saving */
      if (movementTimeout.current) {
        clearInterval(movementTimeout.current);
      }
      movementTimeout.current = setTimeout(handleResize, movementColdTime);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, movementColdTime, handleResize]);

  return { dimensions, counter, setCounter };
};
