import SignInForm, { SignInFormValues } from './SignInForm';
import SocialProviders from '../SocialProviders/SocialProviders';
import Switcher from '../Switcher/Switcher';
import clsx from 'clsx';
import sg from '../../../styles/global.module.scss';
import s from './SignIn.module.scss';
import { useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import ButtonMicroWrapper from '../../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';

const SignIn: React.FC = () => {
  const dispatch = useTypedDispatch();

  const submitCallback = async function (values: SignInFormValues) {
    await dispatch(
      authActionCreators.signin({
        email: values.email!,
        password: values.password!,
      })
    );
  };

  return (
    <>
      <Switcher activeButton="SignIn" />
      <SignInForm submitCallback={submitCallback}>
        <div className={clsx(sg.Stack_Horizontal, sg.Justify_End)}>
          <ButtonMicroWrapper
            className={clsx(s.ButtonMicroWrapper)}
            onClick={() =>
              dispatch(authActionCreators.changeAuthPhase('passwordReset_Page'))
            }
          >
            <p>Forget password?</p>
          </ButtonMicroWrapper>
        </div>

        <SocialProviders />
      </SignInForm>
    </>
  );
};

export default SignIn;
