import clsx from "clsx";
import s from "./ButtonWithUnderline.module.scss";
import sg from "../../../styles/global.module.scss";
import { IonButton } from "@ionic/react";

interface ButtonWithUnderlineProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const ButtonWithUnderline: React.FC<ButtonWithUnderlineProps> = (
  props
) => {
  return (
    <IonButton
      className={clsx(s.ButtonWithUnderline)}
      fill="clear"
      onClick={props.onClick}
    >
      <div className={clsx(sg.Stack_Vertical)}>
        <p>{props.text}</p>
        <hr className={props.isActive ? undefined : clsx(s.hidden)} />
      </div>
    </IonButton>
  );
};

export default ButtonWithUnderline;
