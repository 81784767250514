import * as Yup from 'yup';
import s from './Form.module.scss';
import { HelpRequest } from '../../../../../models/helpRequest';
import { validationErrors } from '../../../../../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSumbitFormCallback } from '../../../../../components/Form/submit.hook';
import Form from '../../../../../components/Form/Form';
import Input from '../../../../../components/Inputs/Input/Input';
import InputArea from '../../../../../components/Inputs/InputArea/InputArea';
import { useForm } from 'react-hook-form';

export type BusinessHelpFormValues = Omit<HelpRequest, 'id' | 'source'>;

type Props = {
  initialFormValues: Partial<BusinessHelpFormValues>;
  submitCallback: (values: BusinessHelpFormValues) => Promise<void>;
  isLoading: boolean;
};

const validationOrdered = {
  phone: Yup.string().required(validationErrors.REQUIRED),
  text: Yup.string().required(validationErrors.REQUIRED),
};

const validationSchema: Yup.SchemaOf<Partial<BusinessHelpFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

export const BusinessHelpForm: React.FC<Props> = ({
  submitCallback,
  initialFormValues,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitted, isSubmitSuccessful, errors },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const { onSubmit } = useSumbitFormCallback<BusinessHelpFormValues>({
    submitCallback,
    handleSubmit,
    reset,
    trigger,
    setValue,
    isSubmitted,
  });

  return (
    <Form loading={isLoading} onSubmit={onSubmit} hidePrompt>
      <div data-animation className={s.form}>
        <Input
          type="tel"
          disabled={isSubmitSuccessful}
          placeholder="WhatsApp phone number"
          inputmode="tel"
          className={s.IonInput__custom}
          className__active={s.IonInput__custom__active}
          _name={'phone' satisfies FieldNameType}
          _register={register}
          _errorText={errors.phone?.message}
        />

        <InputArea
          disabled={isSubmitSuccessful}
          placeholder="Links to your catalog"
          minRows={5}
          className={s.IonTextarea__custom}
          className__active={s.IonTextarea__custom__active}
          _name={'text' satisfies FieldNameType}
          _register={register}
          _errorText={errors.text?.message}
        />

        <button
          className={s.whatsAppButton}
          type="submit"
          disabled={isSubmitSuccessful}
          style={isSubmitSuccessful ? { opacity: 0.5, cursor: 'unset' } : {}}
        >
          {isSubmitSuccessful
            ? "We'll contact you shortly 👍"
            : 'Transfer your catalog'}
        </button>
      </div>
    </Form>
  );
};
