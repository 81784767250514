import clsx from 'clsx';
import s from './ButtonMicroWrapper.module.scss';
import { IonButton } from '@ionic/react';
import { MouseEventHandler } from 'react';

interface ButtonMicroWrapperProps {
  children: React.ReactNode;
  routerLink?: string;
  onClick?: MouseEventHandler<HTMLIonButtonElement>;
  className?: string;
  disabled?: boolean;
  softDisabled?: boolean;
  resizeAnimation?: boolean;
  noRipple?: boolean;
  mode?: 'md' | 'ios';
}

export const ButtonMicroWrapper: React.FC<ButtonMicroWrapperProps> = props => {
  return (
    <IonButton
      disabled={props.disabled}
      fill="clear"
      mode={props.mode || 'md'}
      routerLink={props.routerLink}
      className={clsx(
        s.IonButton,
        props.className,
        props.noRipple ? s.IonButton__no_ripple : undefined,
        props.resizeAnimation ? s.IonButton__resize_animation : undefined,
        props.softDisabled ? s.IonButton__softDisabled : undefined,
        'disableIosSafariSwipeBlocker' // Disable unclicked edges
      )}
      onClick={e => {
        if (props.onClick) props.onClick(e);
      }}
    >
      {props.children}
    </IonButton>
  );
};

export default ButtonMicroWrapper;
