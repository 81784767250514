import { isSubarray } from '../../@shared/helpers/utils';
import { ProductCategory } from '../../models/product';
import { ProductCategoriesQueryParamsType } from '../../redux/_types';
import { SearchTypeEnum } from '../SearchBlock/interface';
import { CheckedCategoriesType, CheckedSubcategoriesType } from './dispatcher';

export const getInitialCheckedCategories = (
  allCategories: ProductCategory[]
) => {
  const result: CheckedCategoriesType = {};

  for (const category of allCategories) {
    if (!category.subcategories) {
      result[category.id] = true;
    } else {
      result[category.id] = {};
      for (const subcategory of category.subcategories) {
        (result[category.id] as CheckedSubcategoriesType)[subcategory[0]] =
          true;
      }
    }
  }

  return result;
};

export const convertProductCategoriesToQueryObject = (
  checkedCategories: CheckedCategoriesType
) => {
  const productCategoriesQueryParams: ProductCategoriesQueryParamsType = {};

  for (const [resourcetype, val] of Object.entries(checkedCategories)) {
    if (val === true) {
      productCategoriesQueryParams[`c_${resourcetype}`] = 'all';
    } else if (typeof val === 'object') {
      const selectedSubcategories = Object.entries(val)
        .filter(([subcategory, isSelected]) => isSelected)
        .map(([subcategory, isSelected]) => subcategory);

      if (selectedSubcategories.length === 0) {
        continue;
      } else if (selectedSubcategories.length === Object.keys(val).length) {
        productCategoriesQueryParams[`c_${resourcetype}`] = 'all';
      } else {
        productCategoriesQueryParams[`c_${resourcetype}`] =
          selectedSubcategories.join(',');
      }
    }
  }

  if (
    Object.values(productCategoriesQueryParams).every(
      _subcategories => _subcategories === 'all'
    ) &&
    Object.keys(productCategoriesQueryParams).length ===
      Object.keys(checkedCategories).length
  ) {
    return {}; /* all categories and subcategories are checked */
  }

  return productCategoriesQueryParams;
};

export const convertQueryObjectToProductCategories = (
  productCategoriesQueryParams: ProductCategoriesQueryParamsType,
  initialCheckedCategories: CheckedCategoriesType,
  isProductCategoryPreselected: boolean
) => {
  if (!Object.keys(productCategoriesQueryParams).length) {
    return initialCheckedCategories;
  }

  const checkedCategories: CheckedCategoriesType = {};

  for (const [c_resourcetype, _subcategories] of Object.entries(
    productCategoriesQueryParams
  )) {
    const resourcetype = c_resourcetype.slice(2);
    const initialCheckedCategoriesByResourcetype =
      initialCheckedCategories[resourcetype];
    const allSubcategoriesByResourcetype = Object.keys(
      initialCheckedCategoriesByResourcetype
    );

    if (_subcategories === 'all') {
      checkedCategories[resourcetype] = initialCheckedCategoriesByResourcetype;
    } else {
      if (typeof initialCheckedCategoriesByResourcetype === 'boolean') {
        throw new Error(
          'All boolean values should be handled and assigned on previous if case.'
        );
      }

      const subcategories = _subcategories.split(',');

      if (!isSubarray(allSubcategoriesByResourcetype, subcategories)) {
        console.error(
          '[CRITICAL] Provided subcategories should be subarray of all subcategories'
        );
        continue;
      }

      checkedCategories[resourcetype] = {} as CheckedSubcategoriesType;

      for (const subcategory of Object.keys(
        initialCheckedCategoriesByResourcetype
      )) {
        (checkedCategories[resourcetype] as CheckedSubcategoriesType)[
          subcategory
        ] = !!subcategories.find(s => s === subcategory);
      }
    }
  }

  /* Example: ['ProductVenue', {bar: true, loft: true, restaurant: true}] */
  for (const [category, subcategories] of Object.entries(
    initialCheckedCategories
  )) {
    if (!(category in checkedCategories)) {
      if (isProductCategoryPreselected) {
        checkedCategories[category] = subcategories;
      } else {
        /* here we should reverse initial values because by default they are true */
        if (typeof subcategories === 'boolean') {
          checkedCategories[category] = false;
        } else {
          checkedCategories[category] = Object.keys(subcategories).reduce(
            (accum, key) => {
              accum[key] = false;
              return accum;
            },
            {} as CheckedSubcategoriesType
          );
        }
      }
    }
  }

  return checkedCategories;
};
