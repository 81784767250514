import React from 'react';
import Page from '../components/Page/Page';
import { IonCard } from '@ionic/react';

const SupportPage: React.FC = () => {
  return (
    <Page headerProps={{ title: 'Support' }}>
      <IonCard>
        <p style={{ marginBottom: '20px' }}>
          If you need some help you can contact our support. <br /> Just send an
          email to:
        </p>
        <p style={{ color: 'white', opacity: 0.7 }}>
          <i>{`support@eventmaker.app`}</i>
        </p>
      </IonCard>
    </Page>
  );
};

export default SupportPage;
