import React from 'react';
import { Product } from '../../models/product';
import { FetchMoreType } from './useGroup.hook';
import ProductCard from '../ProductCard/ProductCard';
import FetchMoreByGrid from './FetchMoreByGrid';

type Props = {
  productsInRow: (FetchMoreType | Product)[];
  productCardSize: { width: number; height: number };
  productsInOneRow: number;
};

/*
 We use manual table grid because "fixedHeaderContent" from 'react-virtuoso'
 available only in <VirtuosoTable /> and not available in <VirtuosoGrid />
*/
const ViewByGrid: React.FC<Props> = ({
  productsInRow,
  productCardSize: { width, height },
  productsInOneRow,
}) => {
  return (
    <table>
      <tbody>
        <tr>
          {productsInRow.map(productOrFetchMore =>
            typeof productOrFetchMore !== 'function' ? (
              <td key={productOrFetchMore?.id} style={{ height }}>
                <ProductCard product={productOrFetchMore} showCategory />
              </td>
            ) : (
              <td
                key="fetchMore"
                style={{
                  height: productsInRow.length === 1 ? '80px' : height,
                }}
              >
                <FetchMoreByGrid />
              </td>
            )
          )}

          {/* Empty <td /> to fill up the space. Necessary because of grid */}
          {productsInRow.length > 1 &&
            productsInOneRow > productsInRow.length &&
            [...Array(productsInOneRow - productsInRow.length)].map((e, i) => (
              <td key={i} />
            ))}
        </tr>
      </tbody>
    </table>
  );
};

export default React.memo(ViewByGrid);
