import React, { FormEventHandler } from 'react';
import { Prompt } from 'react-router';
import clsx from 'clsx';
import s from './Form.module.scss';
import Button from '../Buttons/Button/Button';
import { useTypedSelector } from '../../redux/hooks';
import { IonToolbar, isPlatform } from '@ionic/react';
import { Scrollbar } from 'react-scrollbars-custom';
import { IS_INSTAGRAM } from '../../utils/browser';

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading?: boolean;
  loadingStore?: 'subjects' | 'auth';
  children?: React.ReactNode;
  isDirty?: boolean;
  stopScrollbarPropagation?: boolean;
  showSubmitButton?: boolean;
  showSubmitButtonAtBottom?: boolean;
  showSubmitButtonText?: string;
  hidePrompt?: boolean;
};

const Form: React.FC<Props> = props => {
  const loadingStore = useTypedSelector(s =>
    props.loadingStore ? s[props.loadingStore]['loading'] : null
  )!;

  const isScreenLocked = useTypedSelector(s => s.global.screenLock)!;

  const form = () => (
    <form
      autoComplete={IS_INSTAGRAM ? 'off' : undefined}
      id="my-form"
      noValidate
      onSubmit={props.onSubmit}
      style={{
        padding: '5px',
        ...(loadingStore || props.loading
          ? {
              opacity: 0.5,
              pointerEvents: 'none',
            }
          : {}),
      }}
    >
      {!props.hidePrompt && (
        <Prompt
          when={props.isDirty && !IS_INSTAGRAM}
          message="You have unsaved changes on the page. If you will exit you will loose them. Continue?"
        />
      )}
      {props.children}
    </form>
  );

  if (!props.showSubmitButton) {
    return form();
  }

  return (
    <div style={{ height: '100%' }}>
      <Scrollbar
        noScroll={isScreenLocked}
        style={{ height: 'calc(100% - 70px)' }}
        translateContentSizeYToHolder={
          !isPlatform('mobile') && !props.showSubmitButtonAtBottom
        }
        onTouchMoveCapture={e => {
          if (props.stopScrollbarPropagation) e.stopPropagation();
        }}
      >
        {form()}
      </Scrollbar>

      <IonToolbar id="myFormToolbar" mode="ios" className={clsx(s.IonToolbar)}>
        <Button
          form="my-form"
          loading={props.loading || loadingStore}
          text={props.showSubmitButtonText || 'Save'}
          color="secondary"
          fill="solid"
          type="submit"
        />
      </IonToolbar>
    </div>
  );
};

export default Form;
