export function getSearchParameters() {
  const searchParams = new URLSearchParams(window.location.search);
  const paramObj: any = {};
  for (const key of searchParams.keys()) {
    paramObj[key] =
      searchParams.getAll(key).length > 1
        ? searchParams.getAll(key)
        : searchParams.get(key);
  }
  return paramObj;
}
