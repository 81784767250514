import React, { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';

import { About } from './Components/Sections/About/About';
import { AreYouBusiness } from './Components/Sections/AreYouBusiness/AreYouBusiness';
import { EventTypes } from './Components/Sections/EventTypes/EventTypes';
import { EventTypesMobile } from './Components/Sections/EventTypes/EventTypesMobile';
import { Reviews } from './Components/Sections/Reviews/Reviews';
import { Benefits } from './Components/Sections/Benefits/Benefits';
import { Achievements } from './Components/Sections/Achievements/Achievements';
import { FAQ } from './Components/Sections/FAQ/FAQ';
import { Footer } from './Components/Sections/Footer/Footer';
import { Form } from './Components/Sections/Form/Form';
import { HowItWorks } from './Components/Sections/HowItWorks/HowItWorks';
import { Header } from './Components/Sections/Header/Header';
import { HeaderMobile } from './Components/Sections/HeaderMobile/HeaderMobile';
import { processAnchorClick } from './Components/Sections/HeaderMobile/config';
import { useMedia } from '../../hooks/hook.media';

import './Styles/_index.scss';
import { IS_INSTAGRAM } from '../../utils/browser';

export const CommonLanding = () => {
  useEffect(() => {
    const navHash = window.location.hash?.replace('#', '') as any;
    if (navHash) {
      setTimeout(() => {
        processAnchorClick(navHash, 'auto');
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname
        );
      }, 50);
    }
  }, []);

  const isBigScreen = useMedia(['(min-width: 1150px)'], [true], false);

  useEffect(() => {
    const Animation: IntersectionObserverCallback = entry => {
      entry.forEach(change => {
        if (change.isIntersecting) {
          change.target.classList.add('show-animation');
        }
      });
    };

    let observer = new IntersectionObserver(Animation, {
      threshold: [0.4],
    });
    let elements = document.querySelectorAll('[data-animation]');

    if (IS_INSTAGRAM) {
      elements.forEach(e => e.classList.add('show-animation'));
    } else {
      elements.forEach(elm => observer.observe(elm));
    }
  }, [isBigScreen]);

  return (
    <IonPage className="myIonPage bodyBackground">
      <IonContent>
        {isBigScreen ? <Header /> : <HeaderMobile />}

        <About />

        {isBigScreen ? <EventTypes /> : <EventTypesMobile />}

        <Reviews />

        <Achievements />

        <Benefits />

        <HowItWorks />

        <Form />

        <FAQ />

        <AreYouBusiness />

        <Footer />
      </IonContent>
    </IonPage>
  );
};
