import { AlertOptions } from '@ionic/react';
import { ReduxActionCreator } from '../../store';
import { ErrorTextable, globalSlice, ToastType } from './slice';

const setErrorRaw: ReduxActionCreator<Error | null> = err => {
  return async dispatch => {
    console.error(err);
    dispatch(
      globalSlice.actions.setError({ header: err?.name, message: err?.message })
    );
  };
};

const setErrorText: ReduxActionCreator<ErrorTextable | null> = errorText => {
  return async dispatch => {
    dispatch(globalSlice.actions.setError(errorText));
  };
};

const setAlertObject: ReduxActionCreator<AlertOptions | null> = alert => {
  return async dispatch => {
    dispatch(globalSlice.actions.setAlert(alert));
  };
};

const setToastObject: ReduxActionCreator<ToastType> = toast => {
  return async dispatch => {
    dispatch(globalSlice.actions.setToast(toast));
  };
};

const changeScreenLock: ReduxActionCreator<void> = () => {
  return async dispatch => {
    dispatch(globalSlice.actions.changeScreenLock(undefined));
  };
};

const setModalHiderVisibility: ReduxActionCreator<boolean> = isHiderVisible => {
  return async dispatch => {
    dispatch(globalSlice.actions.setModalHiderVisibility(isHiderVisible));
  };
};

export const globalActionCreators = {
  setErrorRaw,
  setErrorText,
  setAlertObject,
  setToastObject,
  setModalHiderVisibility,
  changeScreenLock,
};
