import clsx from 'clsx';
import s from './CardAuth.module.scss';

interface CardAuthProps {
  className?: string;
  children?: React.ReactNode;
  noSpace?: boolean;
}

const CardAuth: React.FC<CardAuthProps> = props => {
  return (
    <div
      className={clsx(
        s.CardAuth,
        props.noSpace ? undefined : s.CardAuth__space,
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default CardAuth;
