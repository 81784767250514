import { isPlatform } from '@ionic/react';

export const IS_SAFARI =
  window.navigator.userAgent.includes('Safari') &&
  !window.navigator.userAgent.includes('Chrome');

const getSafariVersion = () => {
  const array = [...window.navigator.userAgent.split('Version/')[1]];
  const [first, second] = array;
  const version = first + second;
  return version; // 15, 16, 17...
};

export const IS_SAFARI_MOBILE = isPlatform('mobile') && IS_SAFARI;
export const IS_SAFARI_DESKTOP = isPlatform('desktop') && IS_SAFARI;
export const IS_SAFARI_NEW = IS_SAFARI ? getSafariVersion() > '16' : null;
console.log('IS_SAFARI_NEW', IS_SAFARI_NEW);

export const IS_IOS_PWA = isPlatform('pwa') && isPlatform('ios');

export const IS_IOS_NATIVE = isPlatform('capacitor') && isPlatform('ios');
export const IS_ANDROID_NATIVE =
  isPlatform('capacitor') && isPlatform('android');

export const IS_INSTAGRAM = /Instagram/.test(navigator.userAgent);
