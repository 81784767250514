import edit from './assets/icons/edit.svg';
import swap from './assets/icons/swap.svg';
import like from './assets/icons/like.svg';
import logo from './assets/icons/logo.svg';
import tabInactive from './assets/tab/inactive.svg';
import tabActive from './assets/tab/active.svg';

export const Icons = {
  swap,
  like,
  logo,
  edit,
  tabInactive,
  tabActive,
};
