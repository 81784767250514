import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetProductsBySearchQuery } from '../../redux/api';
import { SearchArgsType } from '../../redux/_types';
import ViewByCategory from './ViewByCategory';
import ViewByGrid from './ViewByGrid';
import {
  ListRange,
  TableVirtuoso,
  TableComponents,
  TableVirtuosoHandle,
} from 'react-virtuoso';
import SearchBlock from '../SearchBlock/SearchBlock';
import { SearchTypeEnum, SortByEnum } from '../SearchBlock/interface';
import { useDelayUnmount } from '../../hooks/hook.mount';
import {
  INITIAL_PER_CATEGORY_LIMIT,
  ONE_CATEGORY_LIMIT,
  FetchMoreType,
  useGroupedByCategoryProducts,
  useGroupedByGridProducts,
} from './useGroup.hook';
import { useMedia } from '../../hooks/hook.media';
import { useTypedSelector } from '../../redux/hooks';
import { Product, ProductCategory } from '../../models/product';
import { EventCategory } from '../../models/event';
import { useHistory, useLocation } from 'react-router';
import { convertSearchArgsToQueryParams } from '../../redux/_helpers';
import { FetchMoreStatusContext } from './context';
import { sleep } from '../../utils/sleep';
import { useContainerDimensions } from '../../hooks/hook.dimensions';
import { isPlatform } from '@ionic/core';
import Spinner from '../Spinner/Spinner';

import s from './SearchContent.module.scss';
import clsx from 'clsx';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import Centralizer from '../Centralizer/Centralizer';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { IonIcon } from '@ionic/react';
import { refreshCircle, refreshCircleOutline } from 'ionicons/icons';

interface MyContext {
  isSearchTermsApplied: boolean;
  isNotReady: boolean;
  isDataExists: boolean;
}

const Scroller: TableComponents['Scroller'] = React.forwardRef(
  ({ style, ...props }, ref) => {
    const { isSearchTermsApplied, isNotReady, isDataExists } =
      props.context as MyContext;
    return (
      <div
        ref={ref}
        style={{
          ...style,
          overflow:
            !isSearchTermsApplied || isNotReady || !isDataExists
              ? 'hidden'
              : 'scroll',
        }}
        {...props}
      />
    );
  }
);

const TableBody: TableComponents['TableBody'] = React.forwardRef(
  ({ style, ...props }, ref) => {
    const { isSearchTermsApplied, isNotReady, isDataExists } =
      props.context as MyContext;

    return (
      <tbody
        {...props}
        ref={ref}
        style={{
          ...style,
          opacity: !isSearchTermsApplied || isNotReady || !isDataExists ? 0 : 1,
        }}
      />
    );
  }
);

type Props = {
  initialSearchArgs: SearchArgsType;
  hideSearchString?: boolean;
  isBusinessPlatform?: boolean;
};

export const SearchContent: React.FC<Props> = ({
  initialSearchArgs,
  hideSearchString,
  isBusinessPlatform,
}) => {
  const history = useHistory();
  const location = useLocation();

  const productCategories = useTypedSelector(s => s.choices.productCategorySet);

  const isBusinessPage =
    initialSearchArgs.type === SearchTypeEnum.BY_KEYWORDS &&
    !!initialSearchArgs.businessId;

  const [searchArgs, setSearchArgs] =
    useState<SearchArgsType>(initialSearchArgs);

  useEffect(() => {
    setSearchArgs(initialSearchArgs);
  }, [initialSearchArgs]);

  const tableVirtuosoRef = useRef<TableVirtuosoHandle>(null);
  const scrollToTop = useCallback(() => {
    tableVirtuosoRef.current?.scrollTo({
      behavior: 'auto',
      top: 0,
    });
  }, [tableVirtuosoRef]);

  useEffect(() => {
    scrollToTop();
  }, [searchArgs, scrollToTop]);

  const search = useMemo(() => {
    const queryParams = convertSearchArgsToQueryParams(searchArgs);
    const search = !isBusinessPage
      ? queryParams
          .toString()
          .replace(`&sort_by=${SortByEnum.DEFAULT}`, '')
          .replace(`type=${SearchTypeEnum.BY_EVENT_CATEGORY}&`, '')
      : queryParams.toString();

    if (!isBusinessPage && location.pathname === '/u/catalog') {
      setTimeout(() => {
        history.replace({ search });
      });
    }

    return search;
  }, [searchArgs, history, location.pathname, isBusinessPage]);

  useEffect(() => {
    if (
      searchArgs.type === SearchTypeEnum.BY_EVENT_CATEGORY &&
      searchArgs.eventCategory === null
    ) {
      // skip - it is default search
    } else if (isBusinessPage) {
      // skip - it is business portal or "products" tab on business page
    } else {
      trackEvent(AnalyticEventsEnum.SEARCH);
    }
  }, [searchArgs, isBusinessPage]);

  const [selectedSearchType, setSelectedSearchType] = useState<SearchTypeEnum>(
    initialSearchArgs.type
  );

  const highlitedGenericCategory:
    | EventCategory
    | null
    | ProductCategory
    | undefined =
    searchArgs.type === SearchTypeEnum.BY_EVENT_CATEGORY
      ? searchArgs.eventCategory
      : searchArgs.type === SearchTypeEnum.BY_PRODUCT_CATEGORY
      ? searchArgs.productCategory
      : undefined;

  const highlitedSearchType = useMemo(() => {
    if (highlitedGenericCategory === null) {
      return SearchTypeEnum.BY_EVENT_CATEGORY;
    }

    if (highlitedGenericCategory) {
      if ('polymorphicCtype' in highlitedGenericCategory) {
        return SearchTypeEnum.BY_PRODUCT_CATEGORY;
      } else {
        return SearchTypeEnum.BY_EVENT_CATEGORY;
      }
    }

    return SearchTypeEnum.BY_KEYWORDS;
  }, [highlitedGenericCategory]);

  const { data, isLoading, isFetching } = useGetProductsBySearchQuery({
    search,
    offset: 0,
    limit:
      highlitedSearchType === SearchTypeEnum.BY_EVENT_CATEGORY
        ? INITIAL_PER_CATEGORY_LIMIT
        : ONE_CATEGORY_LIMIT,
  });

  const displayType =
    highlitedSearchType !== SearchTypeEnum.BY_EVENT_CATEGORY
      ? 'grid'
      : 'category';

  const isSearchTermsApplied =
    highlitedSearchType === selectedSearchType ||
    (selectedSearchType === SearchTypeEnum.BY_EVENT_CATEGORY &&
      highlitedGenericCategory === null);

  const [searchBlockCollapsed, setSearchBlockCollapsed] = useState(false);
  const notCollapsed = useDelayUnmount(!searchBlockCollapsed, 250);

  const lastStartIndex = useRef(0);
  const lastAtTopChange = useRef(new Date().getTime());
  const lastTouchStart = useRef({ x: 0, y: 0 });
  const isAutoCollapseDisabled = useRef(false);
  const isAtTop = useRef(true);

  const productsInOneRow: number = useMedia(
    [
      '(min-width: 1600px)',
      '(min-width: 1400px)',
      '(min-width: 1150px)',
      '(min-width: 600px)',
      '(min-width: 400px)',
    ],
    [5, 4, 3, 2, 1],
    1
  );

  /* cpm = CategoryProductsManager */
  const cpm = useGroupedByCategoryProducts({
    productCategories,
    initialData: data,
    search,
    skipInitialization: displayType !== 'category',
  });

  /* gpm = GridProductsManager */
  const gpm = useGroupedByGridProducts({
    productCategory: highlitedGenericCategory as ProductCategory,
    initialData: data,
    search,
    productsInOneRow,
    skipInitialization: displayType !== 'grid',
  });

  const groupedProducts = useMemo(() => {
    const groups = Object.values(
      displayType === 'category' ? cpm.groupedProducts : gpm.groupedProducts
    ).filter(val => val.length) as (Product | FetchMoreType)[][];

    if (displayType === 'category') {
      groups.forEach(group => {
        const lastElement = group[group.length - 1];
        if (typeof lastElement !== 'function') {
          group.push(cpm.fetchMore);
        }
      });
    } else if (displayType === 'grid') {
      if (groups.length) {
        const lastGroup = groups[groups.length - 1];
        const lastElement = lastGroup[lastGroup.length - 1];
        if (typeof lastElement !== 'function') {
          if (lastGroup.length < gpm.productsInOneRow) {
            lastGroup.push(gpm.fetchMore);
          } else {
            groups.push([gpm.fetchMore]);
          }
        }
      }
    }

    return groups;
  }, [cpm, gpm, displayType]);

  /* Fetch more for "grid" (by product category, by search string) */
  const executeFetchMore = useCallback(() => {
    if (displayType === 'category') {
      return console.error('Not possible to call this function.');
    }

    const productCategory = highlitedGenericCategory as ProductCategory;
    gpm.fetchMore(productCategory);
  }, [highlitedGenericCategory, gpm, displayType]);

  const scrollHandler = useCallback(
    ({ startIndex, endIndex }: ListRange) => {
      if (isAutoCollapseDisabled.current) return;
      if (lastStartIndex.current === startIndex) return;

      let direction: 'down' | 'up' | undefined = undefined;
      if (lastStartIndex.current < startIndex) {
        direction = 'down';
      } else if (lastStartIndex.current > startIndex + 1) {
        direction = 'up';
      }

      if (direction === 'down') {
        lastStartIndex.current = startIndex;
        setSearchBlockCollapsed(true);
      } else if (direction === 'up') {
        lastStartIndex.current = startIndex;
        setSearchBlockCollapsed(false);
        isAutoCollapseDisabled.current = false;
      }
    },
    [setSearchBlockCollapsed]
  );

  /* Fake loading: could be abstracted to hook */
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const fixUiOnSearch = useCallback(async () => {
    setIsFakeLoading(true);
    setTimeout(() => setIsFakeLoading(false), 500);
    await sleep(0);
  }, []);

  useEffect(() => {
    if (initialSearchArgs.city) fixUiOnSearch();
  }, [initialSearchArgs.city, fixUiOnSearch]);

  /* Fix flickering */
  const isNotReady = useDelayUnmount(
    !(!isFetching && !isLoading && !isFakeLoading),
    200
  );
  const isDataExists = !isNotReady && !!groupedProducts.length;

  /* Calculate sizes */
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const {
    dimensions: { refWidth: tableWrapperWidth },
    setCounter,
  } = useContainerDimensions(tableWrapperRef, 1000);

  const productCardSize = useMemo(() => {
    if (!tableWrapperWidth || !productsInOneRow) return undefined;

    const coef = displayType === 'category' ? 0.5 : 0.2;
    const arrowComepnsation = isPlatform('desktop') ? 50 : 0;

    const width = Math.round(
      (tableWrapperWidth - arrowComepnsation) / (productsInOneRow + coef)
    );
    const height = Math.round(width * (isPlatform('desktop') ? 1.1 : 1.3));
    return { width, height };
  }, [tableWrapperWidth, productsInOneRow, displayType]);

  const context = useMemo(
    () =>
      ({
        isSearchTermsApplied,
        isNotReady,
        isDataExists,
      } satisfies MyContext),
    [isSearchTermsApplied, isNotReady, isDataExists]
  );

  return (
    <FetchMoreStatusContext.Provider
      value={{ status: gpm.status, executeFetchMore }}
    >
      <div
        ref={tableWrapperRef}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        {!tableWrapperWidth ? (
          <Centralizer enableVerticalCentralization>
            <ButtonMicroWrapper onClick={() => setCounter(0)}>
              <IonIcon src={refreshCircle} className={s.IonIcon__refetch} />
            </ButtonMicroWrapper>
          </Centralizer>
        ) : (
          <TableVirtuoso
            id="MyTableVirtuoso"
            ref={tableVirtuosoRef}
            className={clsx(s.TableVirtuoso)}
            data={groupedProducts}
            context={context}
            fixedHeaderContent={() =>
              !tableWrapperWidth ? null : (
                <SearchBlock
                  hideSearchString={hideSearchString}
                  notCollapsed={notCollapsed}
                  setCollapsed={setSearchBlockCollapsed}
                  isAutoCollapseDisabled={isAutoCollapseDisabled}
                  highlitedSearchType={highlitedSearchType}
                  selectedSearchType={selectedSearchType}
                  setSelectedSearchType={setSelectedSearchType}
                  setSearchArgs={setSearchArgs}
                  searchArgs={searchArgs}
                  highlitedGenericCategory={highlitedGenericCategory}
                  isSearchTermsApplied={isSearchTermsApplied}
                  isReady={!isNotReady}
                  isDataExists={isDataExists}
                  initialSearchArgs={initialSearchArgs}
                  lastTouchStart={lastTouchStart}
                  isBusinessPage={isBusinessPage}
                  fixUiOnSearch={fixUiOnSearch}
                  tableWrapperWidth={tableWrapperWidth}
                  isBusinessPlatform={isBusinessPlatform}
                />
              )
            }
            /* Completely disable virtualiztion */
            increaseViewportBy={{
              top: displayType === 'category' ? 99999 : 0,
              bottom: displayType === 'category' ? 99999 : 0,
            }}
            /* Fetch more products from server */
            atBottomThreshold={2000}
            atBottomStateChange={isAtBottom => {
              if (displayType === 'grid' && isAtBottom) {
                executeFetchMore();
              }
            }}
            /* Hide and show filters panel <SearchBlock> */
            rangeChanged={scrollHandler}
            atTopStateChange={atTop => {
              isAtTop.current = atTop;

              const now = new Date().getTime();
              if (now - lastAtTopChange.current < 700 && atTop) return;
              lastAtTopChange.current = now;

              setSearchBlockCollapsed(!atTop);
            }}
            /* Desktop */
            onWheel={e => {
              const isVerticalScroll = Math.abs(e.deltaX) < Math.abs(e.deltaY);
              if (isVerticalScroll && e.deltaY < 0 && isAtTop.current) {
                setSearchBlockCollapsed(false);
                isAutoCollapseDisabled.current = false;
              }
            }}
            /* Mobile */
            onTouchStart={e => {
              lastTouchStart.current = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
              };
            }}
            /* Data */
            components={{
              Scroller,
              TableBody,
            }}
            itemContent={(index, productsInRow) =>
              !tableWrapperWidth ? (
                <td style={{ width: '1px', height: '1px' }} />
              ) : (
                <td>
                  {!productCardSize ? (
                    index === 0 ? (
                      <Spinner />
                    ) : null
                  ) : displayType === 'category' ? (
                    <ViewByCategory
                      search={search}
                      productCardSize={productCardSize}
                      products={productsInRow}
                      tableWrapperWidth={tableWrapperWidth}
                    />
                  ) : (
                    <ViewByGrid
                      productCardSize={productCardSize}
                      productsInRow={productsInRow}
                      productsInOneRow={productsInOneRow}
                    />
                  )}
                </td>
              )
            }
          />
        )}
      </div>
    </FetchMoreStatusContext.Provider>
  );
};

export default React.memo(SearchContent);
