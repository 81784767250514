import clsx from 'clsx';
import s from './EventCategoryOrder.module.scss';
import sg from '../../styles/global.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonList,
  IonReorder,
  IonReorderGroup,
  ItemReorderEventDetail,
} from '@ionic/react';
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
import { useTypedSelector } from '../../redux/hooks';
import { EventCategory } from '../../models/event';
import InputWrapper from '../Inputs/InputWrapper/InputWrapper';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';

interface EventCategoryOrderProps {
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
  _control: Control<any, any>;
  _setValue: UseFormSetValue<any>;
}

const EventCategoryOrder: React.FC<EventCategoryOrderProps> = props => {
  const eventCategories = useTypedSelector(s => s.choices.eventCategorySet);

  const _value = useWatch({
    control: props._control,
    name: props._name,
    disabled: true,
  }) as EventCategory[];

  const orderedInitialEventCategoriesIds = useMemo(
    () => _value.map(i => i.id),
    []
  );

  const [items, setItems] = useState<{
    left: EventCategory[];
    right: EventCategory[];
  }>({
    left: orderedInitialEventCategoriesIds
      .map(id => eventCategories.find(ec => ec.id === id))
      .filter(ec => ec !== undefined) as EventCategory[],
    right: eventCategories.filter(
      ec => !orderedInitialEventCategoriesIds.includes(ec.id)
    ),
  });

  useEffect(() => {
    if (_value !== items.left) {
      props._setValue(props._name, [...items.left], {
        shouldDirty: true,
      });
    }
  }, [items]);

  const moveItemBetweenLists = useCallback(
    (source: 'left' | 'right', item: EventCategory) => {
      const index = items[source].findIndex(i => i.id === item.id);

      items[source].splice(index, 1);
      items[source === 'left' ? 'right' : 'left'].push(item);

      setItems({ ...items });
    },
    [items]
  );

  const onReorder = useCallback(
    (event: CustomEvent<ItemReorderEventDetail>) => {
      const fromIndex = event.detail.from;
      const toIndex = event.detail.to;

      const arr = items['left'];
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);

      setItems({ left: arr, right: items['right'] });

      event.detail.complete();
    },
    [items]
  );

  return (
    <>
      <div className={clsx(sg.Stack_Horizontal, sg.Align_Items_Up)}>
        <InputWrapper
          label="Relevant event categories where the most suitable are on top"
          style={{ width: '50%', paddingRight: '10px' }}
        />

        <InputWrapper
          label="Event categories which are not relevant for this offering"
          style={{ width: '50%', paddingLeft: '10px' }}
        />
      </div>

      <div className={clsx(sg.Stack_Horizontal, sg.Align_Items_Up)}>
        <IonList className={clsx(s.List__container)}>
          <IonReorderGroup disabled={false} onIonItemReorder={onReorder}>
            {items['left'].map(item => (
              <div
                key={item.id}
                className={clsx(
                  sg.Stack_Horizontal,
                  s.Item__container,
                  s.Item__left
                )}
              >
                <IonReorder>
                  <p>{item.name}</p>
                </IonReorder>
                <IonButton
                  onClick={() => moveItemBetweenLists('left', item)}
                  fill="clear"
                >
                  <IonIcon icon={closeOutline} className={clsx(s.IonIcon)} />
                </IonButton>
              </div>
            ))}
          </IonReorderGroup>
        </IonList>

        <div className={clsx(s.Vertical_Line)} />

        <IonList className={clsx(s.List__container)}>
          <IonReorderGroup disabled={true}>
            {items['right'].map(item => (
              <div
                key={item.id}
                className={clsx(
                  sg.Stack_Horizontal,
                  s.Item__container,
                  s.Item__right
                )}
              >
                <ButtonMicroWrapper
                  onClick={() => moveItemBetweenLists('right', item)}
                >
                  <IonIcon
                    icon={arrowBackOutline}
                    className={clsx(s.IonIcon)}
                  />
                  <IonReorder>
                    <p>{item.name}</p>
                  </IonReorder>
                </ButtonMicroWrapper>
              </div>
            ))}
          </IonReorderGroup>
        </IonList>
      </div>
    </>
  );
};

export default EventCategoryOrder;
