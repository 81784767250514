import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import s from './MediaGallery.module.scss';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonTabBar,
  IonTabButton,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import {
  addOutline,
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  removeOutline,
} from 'ionicons/icons';
import { useState } from 'react';
import MediaSlide from './MediaSlide';
import { getFileCategoryFromGenericFile } from '../Inputs/InputFile/oneFile/helpers';
import { GenericFile } from '../Inputs/InputFile/interfaces';
import { Swiper as SwiperType } from 'swiper/types';
import { Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FileCategoryEnum } from '../../@shared/file';
import { IS_INSTAGRAM, IS_IOS_NATIVE } from '../../utils/browser';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

interface MediaGalleryProps {
  genericFiles: GenericFile[];
  initialSlide: number | undefined;
  setInitialSlide: (initialSlide: number | undefined) => void;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({
  genericFiles,
  initialSlide,
  setInitialSlide,
}) => {
  const zoomOptions = [1, 1.25, 1.5, 1.75, 2.0, 2.5, 3, 4, 5] as const;
  const [zoom, setZoom] = useState<number>(1);

  const [swiper, setSwiper] = useState<SwiperType>();

  const moveForvard = () => {
    swiper?.enable();
    swiper!.slideNext();
  };

  const moveBack = () => {
    swiper?.enable();
    swiper!.slidePrev();
  };

  useEffect(() => {
    if (zoom === 1 && swiper) {
      swiper?.enable();
    } else {
      swiper?.disable();
    }
  }, [zoom, swiper]);

  const [activeIndex, setActiveIndex] = useState<number>();

  useEffect(() => {
    if (initialSlide !== undefined) {
      swiper?.slideNext();
      swiper?.slideTo(initialSlide);
    }
  }, [swiper, initialSlide]);

  const lastZoomChange = useRef<number>(0);
  const modalRef = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      handle={false}
      keepContentsMounted
      initialBreakpoint={!isPlatform('desktop') ? 1 : undefined}
      breakpoints={[0, 1]}
      isOpen={initialSlide !== undefined}
      onWillDismiss={() => {
        setInitialSlide(undefined);
        setActiveIndex(undefined);
        setZoom(1);
      }}
      ref={modalRef}
      mode="ios"
      className={clsx(s.IonModal, isPlatform('desktop') && s.IonModal__desktop)}
    >
      {/* ===== Header ===== */}
      <IonHeader>
        <IonToolbar
          mode="ios"
          className={clsx(IS_IOS_NATIVE && 'forceAddPaddingTop', s.NoBorders)}
        >
          {activeIndex !== undefined &&
            !isPlatform('mobile') &&
            getFileCategoryFromGenericFile(genericFiles[activeIndex]) ===
              FileCategoryEnum.IMAGE && (
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    const i = zoomOptions.findIndex(elem => elem === zoom);
                    const prevZoom = zoomOptions[i - 1];
                    if (prevZoom) {
                      setZoom(prevZoom);
                    }
                  }}
                >
                  <IonIcon
                    icon={removeOutline}
                    className={clsx(s.Zoom__button)}
                  />
                </IonButton>

                <p>{Math.round(zoom * 100)}%</p>

                <IonButton
                  onClick={() => {
                    const i = zoomOptions.findIndex(elem => elem === zoom);
                    const nextZoom = zoomOptions[i + 1];
                    if (nextZoom) {
                      setZoom(nextZoom);
                    }
                  }}
                >
                  <IonIcon icon={addOutline} className={clsx(s.Zoom__button)} />
                </IonButton>
              </IonButtons>
            )}

          <IonButtons slot="end">
            <IonButton onClick={() => setInitialSlide(undefined)}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Swiper
        speed={IS_INSTAGRAM ? 1 : 300}
        onInit={setSwiper}
        initialSlide={initialSlide}
        className={clsx(s.Swiper)}
        onSlideChange={() => {
          setZoom(1);
          setActiveIndex(swiper?.activeIndex);
        }}
        zoom={{ minRatio: 1, maxRatio: 3 }}
        modules={isPlatform('mobile') ? [Zoom] : []}
        allowTouchMove={isPlatform('mobile')}
        onZoomChange={(swiper, scale) => {
          swiper.allowTouchMove = isPlatform('mobile') && scale === 1;
          /* Prevent modal closing while resizing */
          if (!isPlatform('desktop')) {
            modalRef.current!.canDismiss = false;
            lastZoomChange.current = new Date().getTime();
            setTimeout(() => {
              if (new Date().getTime() - lastZoomChange.current >= 300) {
                modalRef.current!.canDismiss = true;
              }
            }, 300);
          }
        }}
      >
        {activeIndex !== undefined &&
          genericFiles.map(genericFile => (
            <SwiperSlide key={genericFile.id} style={{ overflow: 'hidden' }}>
              <MediaSlide
                genericFile={genericFile}
                zoom={zoom}
                activeIndex={activeIndex}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      {/* ====== Footer ====== */}
      <IonTabBar slot="bottom" className={clsx(s.NoBorders)} mode="ios">
        <IonTabButton tab="no-move" onClick={moveBack}>
          <IonIcon icon={chevronBackOutline} className={clsx(s.Move__button)} />
        </IonTabButton>

        <IonTabButton tab="no-move">
          <p>
            {(activeIndex || 0) + 1} / {genericFiles.length}
          </p>
        </IonTabButton>

        <IonTabButton tab="no-move" onClick={moveForvard}>
          <IonIcon
            icon={chevronForwardOutline}
            className={clsx(s.Move__button)}
          />
        </IonTabButton>
      </IonTabBar>
    </IonModal>
  );
};

export default MediaGallery;
