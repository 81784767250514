import { ObjectTyped } from "object-typed";
import { FileCategoryEnum, UploadingDoc } from "../../../../@shared/file";

type ProgressStepsType =
  /* Frontend steps */
  | "uploading"
  | "atPolicies"
  /* Firebase steps */
  | Exclude<keyof UploadingDoc["progress"], "createdAt" | "updatedAt">;

type ActionType =
  | "calculateProgressImpact"
  | "setProgressStepValue"
  | "attachListenersRemover"
  | "close";

type PayloadType = {
  action: ActionType;
  fileCategory?: FileCategoryEnum;
  setProgressStepValueData?: {
    [key in ProgressStepsType]?: number | true;
  };
  attachListenersRemover?: { listenersRemover: () => void };
  close?: undefined;
};

export type UploadingProcedureStateType = {
  progress?: {
    stepsImpact: { [key in ProgressStepsType]?: number };
    stepsPercentage: { [key in ProgressStepsType]?: number };
    totalPercentage: number;
  };
  listenersRemover?: () => void;
  isClosed?: boolean;
};

export type UploadingProcedureDispatcherType = React.Dispatch<PayloadType>;

export const uploadingProcedureReducer = (
  state: UploadingProcedureStateType,
  payload: PayloadType
): UploadingProcedureStateType => {
  switch (payload.action) {
    case "calculateProgressImpact":
      const isVideo = payload.fileCategory === FileCategoryEnum.VIDEO;
      state.progress = {
        stepsImpact: {
          atPolicies: isVideo ? 0.04 : 0.25,
          uploading: isVideo ? 0.25 : 0.5,
          atPub: isVideo ? 0.05 : 0,
          atSub: isVideo ? 0.05 : 0,
          processing: isVideo ? 0.6 : 0,
        },
        stepsPercentage: {},
        totalPercentage: 0,
      };
      return state;

    case "setProgressStepValue":
      for (const [progressStep, value] of ObjectTyped.entries(
        payload.setProgressStepValueData!
      )) {
        if (!value) continue;
        state.progress!.stepsPercentage[progressStep] =
          (value === true ? 100 : value) *
          state.progress!.stepsImpact[progressStep]!;
      }
      state.progress!.totalPercentage = Math.ceil(
        Object.values(state.progress!.stepsPercentage).reduce(
          (previousValue, currentElement: number | undefined) =>
            previousValue + (currentElement || 0),
          0
        )
      );
      return { ...state };

    case "attachListenersRemover":
      state.listenersRemover = payload.attachListenersRemover!.listenersRemover;
      return state;

    case "close":
      if (!state.isClosed) {
        state.isClosed = true;
        if (state.listenersRemover) state.listenersRemover();
      }
      return state;

    default:
      throw Error("Unexpected error.");
  }
};
