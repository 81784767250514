import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { ProductCategory } from '../../models/product';
import {
  CheckedCategoriesDispatcherType,
  CheckedCategoriesType,
  CheckedSubcategoriesType,
} from './dispatcher';
import clsx from 'clsx';
import sg from '../../styles/global.module.scss';
import s from './CheckboxGroup.module.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import ButtonLabel from '../Buttons/ButtonLabel/ButtonLabel';

interface OneCategoryProps {
  category: ProductCategory;
  isOpened: boolean;
  setOpenedCategoryId: React.Dispatch<React.SetStateAction<string | undefined>>;
  checkedCategoriesDispatcher: CheckedCategoriesDispatcherType;
  checkedCategories: CheckedCategoriesType;
  isEmptyParentCategory: boolean;
  isParentProductCategory: boolean;
  selectedProductCategory: ProductCategory | undefined;
}

const OneCategory: React.FC<OneCategoryProps> = ({
  category,
  isOpened,
  setOpenedCategoryId,
  checkedCategoriesDispatcher,
  checkedCategories,
  isEmptyParentCategory,
  isParentProductCategory,
  selectedProductCategory,
}) => {
  const countOfChildsTotal =
    typeof checkedCategories[category.id] === 'object'
      ? Object.values(checkedCategories[category.id]).length
      : 0;

  const countOfChildsSelected =
    typeof checkedCategories[category.id] === 'object'
      ? Object.values(checkedCategories[category.id]).filter(Boolean).length
      : 0;

  return (
    <>
      {!isEmptyParentCategory && (
        <div
          className={clsx(
            s.Category__container,
            isOpened ? s.Category__container_opened : undefined
          )}
        >
          <div className={clsx(sg.Stack_Horizontal, sg.Gap_10)}>
            <Checkbox
              disabled={isEmptyParentCategory}
              indeterminate={
                countOfChildsSelected > 0 &&
                countOfChildsSelected !== countOfChildsTotal
              }
              text={!selectedProductCategory ? category.name : 'All'}
              additionalInfo={
                !selectedProductCategory && countOfChildsTotal
                  ? `${countOfChildsSelected}/${countOfChildsTotal} categories`
                  : undefined
              }
              isChecked={
                checkedCategories[category.id] === true ||
                (countOfChildsSelected === countOfChildsTotal &&
                  countOfChildsTotal > 0)
              }
              onChange={e => {
                e.stopPropagation();
                checkedCategoriesDispatcher({
                  action: 'changeChecked',
                  checkboxAddress: {
                    categoryId: category.id,
                  },
                });
              }}
            />

            {countOfChildsTotal !== 0 && !isParentProductCategory && (
              <ButtonLabel
                skipBorder
                label={isOpened ? 'Hide' : 'Show'}
                iconSrc={isOpened ? chevronDownOutline : chevronForwardOutline}
                onClick={() => {
                  if (!isOpened) {
                    setOpenedCategoryId(category.id);
                  } else {
                    setOpenedCategoryId(undefined);
                  }
                }}
              />
            )}
          </div>
        </div>
      )}

      {isOpened && (
        <IonGrid
          className={clsx(
            s.Subcategory__container,
            !isParentProductCategory ? s.Subcategory__container__colored : ''
          )}
        >
          <IonRow>
            {category.subcategories?.map(subcategory => {
              const [subcategoryId, subcategoryName] = subcategory;

              return (
                <IonCol
                  key={subcategoryId}
                  size="6"
                  style={{ paddingTop: '0px', paddingBottom: '0px' }}
                >
                  <Checkbox
                    key={subcategoryId}
                    text={subcategoryName}
                    isChecked={
                      (
                        checkedCategories[
                          category.id
                        ] as CheckedSubcategoriesType
                      )[subcategoryId]
                    }
                    onChange={() => {
                      checkedCategoriesDispatcher({
                        action: 'changeChecked',
                        checkboxAddress: {
                          categoryId: category.id,
                          subcategoryId,
                        },
                      });
                    }}
                  />
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default OneCategory;
