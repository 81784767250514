import clsx from 'clsx';
import s from './InputArea.module.scss';
import { IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { UseFormRegister } from 'react-hook-form';

import './index.scss';

interface InputAreaProps {
  autocomplete?: 'email' | 'current-password' | 'new-password' | 'off';
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  maxlength?: number;
  minRows?: number;
  className?: string;
  className__active?: string;
  labelClassName?: string;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
}

const InputArea: React.FC<InputAreaProps> = props => {
  const [active, setActive] = useState(false);
  const _registered = props._register(props._name);

  return (
    <InputWrapper
      labelClassName={props.labelClassName}
      label={props.label}
      errorText={props._errorText}
      active={active}
    >
      <IonTextarea
        disabled={props.disabled}
        rows={props.minRows}
        label=""
        className={clsx(
          s.IonTextarea,
          active ? s.IonTextarea_active : undefined,
          props.className,
          active ? props.className__active : undefined
        )}
        color="primary"
        autoGrow
        placeholder={props.placeholder}
        maxlength={props.maxlength || 4000}
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        /* react-hook-form */
        name={_registered.name}
        ref={_registered.ref}
        onIonInput={_registered.onChange}
      />
    </InputWrapper>
  );
};

export default InputArea;
