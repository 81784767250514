import clsx from 'clsx';
import s from './Spinner.module.scss';
import { IonSpinner } from '@ionic/react';
import Centralizer from '../Centralizer/Centralizer';

type Props = {
  text?: string;
  size?: number;
};

export const Spinner: React.FC<Props> = ({ text, size = 20 }) => {
  return (
    <Centralizer
      className={clsx(s.Centralizer_unique)}
      enableVerticalCentralization={true}
    >
      <IonSpinner
        className={clsx(s.Spinner)}
        style={{ height: `${size}px` }}
        name="circular"
      />
      {text ? <p>{text}</p> : undefined}
    </Centralizer>
  );
};

export default Spinner;
