import React from 'react';
import clsx from 'clsx';
import Centralizer from '../Centralizer/Centralizer';
import { IonIcon, IonSpinner } from '@ionic/react';
import s from './SearchContent.module.scss';
import { StatusType } from './useGroup.hook';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { refreshOutline } from 'ionicons/icons';

type Props = {
  status: StatusType;
  onFetchMoreClick: () => void;
};

const FetchMoreCommon: React.FC<Props> = ({ status, onFetchMoreClick }) => {
  return (
    <Centralizer enableVerticalCentralization>
      {status === 'loading' && (
        <IonSpinner className={clsx(s.IonSpinner)} name="circular" />
      )}
      {(status === 'error' || !status) && (
        <ButtonMicroWrapper onClick={onFetchMoreClick}>
          <IonIcon src={refreshOutline} className={clsx(s.IonIcon__refetch)} />
        </ButtonMicroWrapper>
      )}
      {/* {status === 'finish' && (
        <p>No more data. Try to change search parameters.</p>
      )} */}
    </Centralizer>
  );
};

export default FetchMoreCommon;
