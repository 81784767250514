import { useIonToast } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTypedSelector } from '../../redux/hooks';
import { ToastType } from '../../redux/slices/global/slice';
import { ToastPriorityEnum } from '../../utils/enums';

const Toast: React.FC = () => {
  const [present] = useIonToast();

  const newToast = useTypedSelector(s => s.global.toast);
  const [isToastBusy, setIsToastBusy] = useState(false);

  const queue = useRef<ToastType[]>([]);
  const [lastToast, setLastToast] = useState<ToastType>();

  const pushToQueue = useCallback(
    (toast: ToastType) => {
      if (lastToast !== toast) {
        queue.current.push(toast);
        setLastToast(toast);
      }
    },
    [lastToast]
  );

  const shiftFromQueue = useCallback(() => {
    queue.current.sort(
      (a, b) =>
        (b.priority || ToastPriorityEnum.LOW) -
        (a.priority || ToastPriorityEnum.LOW)
    );
    return queue.current.shift();
  }, []);

  useEffect(() => {
    if (newToast) {
      pushToQueue(newToast);
    }
  }, [newToast, pushToQueue]);

  useEffect(() => {
    if (!isToastBusy) {
      const nextToastFromQueue = shiftFromQueue();

      if (nextToastFromQueue) {
        setIsToastBusy(true);
        present({
          duration: 3000,
          position: 'top',
          onDidDismiss: () => setIsToastBusy(false),
          ...nextToastFromQueue,
        });
      }
    }
  }, [isToastBusy, lastToast, shiftFromQueue, present]);

  return null;
};

export default Toast;
