import SignUpForm, { SignUpFormValues } from './SignUpForm';
import SocialProviders from '../SocialProviders/SocialProviders';
import Switcher from '../Switcher/Switcher';
import React from 'react';
import { useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import s from './SignUp.module.scss';
import { openUrlInWeb } from '../helper';

const SignUp: React.FC = () => {
  const dispatch = useTypedDispatch();

  const submitCallback = async function (values: SignUpFormValues) {
    await dispatch(
      authActionCreators.signup({
        email: values.email!,
        password1: values.password1!,
        password2: values.password2!,
      })
    );
  };

  return (
    <>
      <Switcher activeButton="SignUp" />

      <SignUpForm submitCallback={submitCallback}>
        <SocialProviders />
      </SignUpForm>

      <div>
        <p className={s.Agreement__text}>
          By signing up, you agree to the {`\n`}
          <button
            className={s.Agreement__button}
            onClick={() => openUrlInWeb('terms')}
          >
            <p className={s.Agreement__link}>Terms of Service</p>
          </button>{' '}
          and{' '}
          <button
            className={s.Agreement__button}
            onClick={() => openUrlInWeb('privacy')}
          >
            <p className={s.Agreement__link}>Privacy Policy</p>
          </button>{' '}
        </p>
      </div>
    </>
  );
};

export default SignUp;
