import React, { useEffect, useState } from 'react';
import { IonIcon, isPlatform } from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline,
  playCircleOutline,
} from 'ionicons/icons';
import { Swiper as SwiperType } from 'swiper/types';
import { Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FileCategoryEnum, UploadedFile } from '../../@shared/file';
import { buildUrlsFromUploadedFile } from '../Inputs/InputFile/oneFile/helpers';
import Centralizer from '../Centralizer/Centralizer';
import ImageDynamic from '../ImageDynamic/ImageDynamic';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';

import clsx from 'clsx';
import s from './MediaSwiper.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { IS_INSTAGRAM } from '../../utils/browser';

interface MediaSwiperProps {
  uploadedFiles: UploadedFile[];
  disableSwipeOnTouch?: boolean;
  isPreview?: boolean;
  activeIndex: number | undefined;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  onSlideClick?: (i: number) => void;
}

const MediaSwiper: React.FC<MediaSwiperProps> = ({
  uploadedFiles,
  disableSwipeOnTouch,
  isPreview,
  activeIndex,
  setActiveIndex,
  onSlideClick,
}) => {
  const [swiper, setSwiper] = useState<SwiperType>();
  const isNavigationVisible = uploadedFiles.length > 1;

  useEffect(() => {
    setActiveIndex(swiper?.activeIndex);
  }, [swiper?.activeIndex, setActiveIndex]);

  const height = isPlatform('desktop') ? '320px' : '250px';

  useEffect(() => {
    if (activeIndex !== undefined) swiper?.slideTo(activeIndex);
  }, [swiper, activeIndex]);

  return (
    <>
      {isNavigationVisible && (
        <ButtonMicroWrapper
          className={clsx(s.SwiperButton__next)}
          onClick={e => {
            swiper!.slideNext();
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Centralizer
            enableVerticalCentralization
            className="disableIosSafariSwipeBlocker"
          >
            <IonIcon
              src={chevronForwardOutline}
              className={clsx(
                s.IonIcon__navigation,
                'disableIosSafariSwipeBlocker'
              )}
            />
          </Centralizer>
        </ButtonMicroWrapper>
      )}

      {isNavigationVisible && (
        <ButtonMicroWrapper
          className={clsx(s.SwiperButton__prev)}
          onClick={e => {
            swiper!.slidePrev();
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Centralizer
            enableVerticalCentralization
            className="disableIosSafariSwipeBlocker"
          >
            <IonIcon
              src={chevronBackOutline}
              className={clsx(
                s.IonIcon__navigation,
                'disableIosSafariSwipeBlocker'
              )}
            />
          </Centralizer>
        </ButtonMicroWrapper>
      )}

      <Swiper
        speed={IS_INSTAGRAM ? 1 : 300}
        virtual
        pagination={isPreview ? { dynamicBullets: true } : false}
        spaceBetween={5}
        onInit={setSwiper}
        initialSlide={0}
        className={clsx(s.Swiper)}
        onSlideChange={() => setActiveIndex(swiper?.activeIndex)}
        modules={[Pagination, Virtual]}
        allowTouchMove={!disableSwipeOnTouch && isPlatform('mobile')}
        style={isPreview ? { position: 'absolute' } : { height }}
      >
        {activeIndex !== undefined &&
          uploadedFiles.map((uploadedFile, index) => (
            <SwiperSlide virtualIndex={index} key={uploadedFile.id}>
              <ImageDynamic
                urls={buildUrlsFromUploadedFile(uploadedFile)}
                quality="medium"
                objectFit={isPreview ? 'cover' : 'contain'}
                style={{
                  borderRadius: '10px',
                  width: isPlatform('desktop') ? 'calc(100% - 110px)' : '100%',
                  cursor: !isPreview ? 'zoom-in' : undefined,
                }}
                onClick={() => (onSlideClick ? onSlideClick(index) : undefined)}
              />

              {uploadedFile?.fileCategory === FileCategoryEnum.VIDEO && (
                <IonIcon
                  icon={playCircleOutline}
                  className={clsx(s.Play__button)}
                  onClick={() =>
                    onSlideClick ? onSlideClick(index) : undefined
                  }
                  style={{
                    cursor: !isPreview ? 'zoom-in' : undefined,
                  }}
                />
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default MediaSwiper;
