import React from 'react';
import clsx from 'clsx';
import s from './Checkbox.module.scss';
import sg from '../../styles/global.module.scss';
import { IonCheckbox } from '@ionic/react';
import { CheckboxAddress } from '../ProductCategories/dispatcher';
import { IonCheckboxCustomEvent } from '@ionic/core';

interface CheckboxProps {
  text: string;
  additionalInfo?: string;
  indeterminate?: boolean;
  address?: CheckboxAddress;
  isChecked?: boolean;
  onChange?: (e: IonCheckboxCustomEvent<any>) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = props => {
  return (
    <IonCheckbox
      className="disableIosSafariSwipeBlocker"
      indeterminate={props.indeterminate}
      labelPlacement="end"
      mode="ios"
      disabled={props.disabled}
      checked={props.isChecked}
      onIonChange={props.onChange}
    >
      <div className={clsx(sg.Stack_Vertical, sg.Gap_5)}>
        <p className={clsx(s.Checkbox__text)}>{props.text}</p>
        {props.additionalInfo && (
          <p className={clsx(s.Checkbox__additional_info)}>
            {props.additionalInfo}
          </p>
        )}
      </div>
    </IonCheckbox>
  );
};

export default Checkbox;
