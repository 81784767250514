import clsx from 'clsx';
import s from './SocialProviders.module.scss';
import sg from '../../../styles/global.module.scss';
import { useAlert } from '../../../hooks/hook.alert';
import ButtonMicroWrapper from '../../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { IS_INSTAGRAM, IS_IOS_NATIVE, IS_SAFARI } from '../../../utils/browser';
import { logoApple, logoGoogle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

export interface SocialProvidersSignInParams {
  provider: 'google' | 'apple';
  isIosNative?: boolean;
  isAndroidNative?: boolean;
  googleAccessToken?: string;
  appleCode?: string;
  appleIdToken?: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

const SocialProviders: React.FC = () => {
  const alertBuilder = useAlert();
  const dispatch = useTypedDispatch();
  const isAnonymusModeGoogleError = useTypedSelector(
    s => s.auth.extraData?.isAnonymusModeGoogleError
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local')
      GoogleAuth.initialize({
        clientId: IS_IOS_NATIVE
          ? process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID_IOS!
          : process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID_WEB!,
        scopes: ['profile', 'email'],
      });
  }, []);

  // Workaround
  const APPLY_WORKAROUND = IS_SAFARI || IS_INSTAGRAM;
  const isInitialized = useRef(false);
  //

  const authorizeWithApple = useCallback(() => {
    // dispatch(authSlice.actions.setLoading(true));

    if (process.env.REACT_APP_ENVIRONMENT !== 'local')
      SignInWithApple.authorize({
        clientId: IS_IOS_NATIVE
          ? process.env.REACT_APP_AUTH_APPLE_CLIENT_IOS!
          : process.env.REACT_APP_AUTH_APPLE_CLIENT_WEB!,
        redirectURI: process.env.REACT_APP_AUTH_APPLE_REDIRECT_URL!,
        scopes: 'email name',
      })
        .then(({ response: appleUser }) => {
          dispatch(
            authActionCreators.signInBySocialProvider({
              provider: 'apple',
              isIosNative: IS_IOS_NATIVE,
              appleCode: appleUser.authorizationCode,
              appleIdToken: appleUser.identityToken,
              firstName: appleUser.givenName,
              lastName: appleUser.familyName,
              email: appleUser.email,
            })
          );
          // dispatch(authSlice.actions.setLoading(false));
        })
        .catch(err => {
          // dispatch(authSlice.actions.setLoading(false));
          if (err.error === 'popup_closed_by_user') return;

          // Workaround
          if (
            APPLY_WORKAROUND &&
            err.error === 'popup_blocked_by_browser' &&
            !isInitialized.current
          ) {
            isInitialized.current = true;
            return;
          }
          //

          if (err.error === 'popup_blocked_by_browser') return;

          alertBuilder({
            header: 'Error while login with Apple',
            message: JSON.stringify(err),
          });
        });
  }, [alertBuilder, dispatch, APPLY_WORKAROUND, isInitialized]);

  // Workaround
  useEffect(() => {
    if (APPLY_WORKAROUND) authorizeWithApple();
  }, []);
  // //

  return (
    <div className={clsx(sg.Stack_Horizontal, s.Social_Providers__container)}>
      <ButtonMicroWrapper
        className={clsx(s.ButtonMicroWrapper)}
        onClick={async () => {
          // dispatch(authSlice.actions.setLoading(true));
          await GoogleAuth.signOut();
          // .catch(() =>
          //   dispatch(authSlice.actions.setLoading(false))
          // );

          GoogleAuth.signIn()
            .then(googleUser => {
              dispatch(
                authActionCreators.signInBySocialProvider({
                  provider: 'google',
                  googleAccessToken: googleUser.authentication.accessToken,
                  firstName: googleUser.givenName,
                  lastName: googleUser.familyName,
                  email: googleUser.email,
                })
              );
            })
            .catch(err => {
              // dispatch(authSlice.actions.setLoading(false));
              if (err.error === 'popup_closed_by_user') return;
              if (err.error === 'popup_blocked_by_browser') return;
              if (err.code === '-5') return; // ios canceled flow

              alertBuilder({
                header: 'Error while login with Google',
                message: JSON.stringify(err),
              });
            });
        }}
      >
        <IonIcon icon={logoGoogle} className={clsx(s.Google__img)} />
      </ButtonMicroWrapper>

      <ButtonMicroWrapper
        className={clsx(s.ButtonMicroWrapper)}
        onClick={authorizeWithApple}
      >
        <IonIcon icon={logoApple} className={clsx(s.Apple__img)} />
      </ButtonMicroWrapper>
    </div>
  );
};

export default SocialProviders;
