import React from 'react';
import { StatusType } from './useGroup.hook';
import FetchMoreCommon from './FetchMoreCommon';

type Props = {
  executeFetchMore: () => void;
  status: StatusType;
};

const FetchMoreByCategory: React.FC<Props> = ({ executeFetchMore, status }) => {
  return (
    <div
      style={{
        height: '100%',
        minHeight: '50px',
        width: status !== 'finish' ? '50px' : 0,
      }}
    >
      <FetchMoreCommon
        status={status}
        onFetchMoreClick={() => executeFetchMore()}
      />
    </div>
  );
};

export default FetchMoreByCategory;
